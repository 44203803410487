// @ts-nocheck
import React, {
  useContext,
  createContext,
  ReactNode,
  MouseEvent,
  FC,
} from 'react';

export enum PositionLotOption {
  Position = 'POSITION',
  Lot = 'LOT',
}

interface PositionLotState {
  positionLotValue: PositionLotOption;
  onPositionLotToggle: (event: MouseEvent, value: PositionLotOption) => void;
}

const PositionLotContext = createContext<PositionLotState | undefined>(
  undefined,
);

/**
 * TODO: This is initially only for position/lot, but can be expanded upon
 * later, and even renamed, to handle more grid updates that are api based or not
 * directly done via the grid. Persistence comes to mind. Eventually need to load state to the grid.
 */
const PositionLotProvider: FC<{
  children: ReactNode;
  setPositionLotValue: func;
  positionLotValue: string;
}> = ({ children, setPositionLotValue, positionLotValue }): JSX.Element => {
  const onPositionLotToggle = (
    _event: MouseEvent,
    value: PositionLotOption | null,
  ): void => {
    if (!value) {
      return;
    }

    setPositionLotValue(value);
  };

  return (
    <PositionLotContext.Provider
      value={{ positionLotValue, onPositionLotToggle }}
    >
      {children}
    </PositionLotContext.Provider>
  );
};

export const usePositionLot = (): PositionLotState => {
  const posLotState = useContext(PositionLotContext);

  if (posLotState === undefined) {
    throw new Error('usePositionLot must be used within a PositionLotProvider');
  }

  return posLotState;
};

export default PositionLotProvider;
