import React, { FC } from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import CompanyTags from './Navigation/CompanyTags';

const SelectedAccountsContainer: FC<{
  isShown: boolean;
  disableDelete?: boolean;
}> = ({ isShown, disableDelete }): JSX.Element => {
  return (
    <Collapse in={isShown}>
      <StyledContainer>
        <CompanyTags disableDelete={disableDelete} />
      </StyledContainer>
    </Collapse>
  );
};

const StyledContainer = styled.div`
  align-items: center;
  min-height: 3.8rem;
  background-color: ${({ theme }): string => theme.colors.NTWarmGrey[100]};
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 4px);
  width: 100%;
  padding: 0 2rem;
`;

export default SelectedAccountsContainer;
