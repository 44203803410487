import React, { FC, useState } from 'react';
import { AssetSuperCategory } from '../../Helpers/data-uri-helper';
import { ReportActions } from '../../Context/View/ViewActions';
import {
  useReportDispatch,
  useReportState,
} from '../../Context/View/ViewProvider';
import HoldingsDonutView from './PortfolioHoldings/HoldingsDonutView';
import HoldingsSmallGridView from './PortfolioHoldings/HoldingsSmallGridView';
import { VisualizationMode } from '../../Context/View/types';

const PortfolioHoldingsCard: FC = (): JSX.Element => {
  const [selectedAssetSuperCategory, setSelectedAssetSuperCategory] =
    useState<AssetSuperCategory>(AssetSuperCategory.ALL);

  const reportDispatch = useReportDispatch();
  const { displayType } = useReportState();

  const onAssetSuperCategoryClicked = (superCategory: string): void => {
    reportDispatch(ReportActions.updateDisplayType(VisualizationMode.Grid));
    setSelectedAssetSuperCategory(superCategory as AssetSuperCategory);
  };

  return displayType === VisualizationMode.Grid ? (
    <HoldingsSmallGridView assetSuperCategory={selectedAssetSuperCategory} />
  ) : (
    <HoldingsDonutView
      onAssetSuperCategoryClicked={onAssetSuperCategoryClicked}
    />
  );
};

export default PortfolioHoldingsCard;
