import React, { FC, useCallback, ReactNode } from 'react';
import { useCompanyContext } from '../../Context/CompanyDataContext/CompanyProvider';
import { CompanyDataActions } from '../../Context/CompanyDataContext/CompanyActions';
import {
  Subsidiary,
  SubsidiaryAccount,
} from '../../Context/CompanyDataContext/types';
import Tag from './Tag';

const CompanyTags: FC<{
  disableDelete?: boolean;
}> = ({ disableDelete }): JSX.Element => {
  const [companyState, dispatch] = useCompanyContext();

  const generateTagsFromSubsidarySelections =
    useCallback((): Array<ReactNode> => {
      const selectedTags: Array<ReactNode> = [];
      companyState.savedSelections.forEach((subsidiary: Subsidiary) => {
        if (subsidiary.isChecked) {
          selectedTags.push(
            <Tag
              key={subsidiary.ID}
              label={subsidiary.SUBSIDIARY_NAME}
              onDelete={
                disableDelete
                  ? null
                  : (): void => {
                      dispatch(
                        CompanyDataActions.onSubsidiaryTagDelete(subsidiary.ID),
                      );
                    }
              }
              tagColor={subsidiary.tagColor}
            />,
          );
        }
        subsidiary.accounts.forEach((subsidiaryAccount: SubsidiaryAccount) => {
          if (subsidiaryAccount.isChecked) {
            selectedTags.push(
              <Tag
                key={subsidiaryAccount.ID}
                label={subsidiaryAccount.ACCOUNT_NAME}
                onDelete={
                  disableDelete
                    ? null
                    : (): void => {
                        dispatch(
                          CompanyDataActions.onSubsidiaryAccountTagDelete({
                            id: subsidiaryAccount.ID,
                            subsidiaryId: subsidiary.ID,
                          }),
                        );
                      }
                }
                tagColor={subsidiaryAccount.tagColor}
              />,
            );
          }
        });
      });

      return selectedTags;
    }, [companyState.savedSelections, disableDelete, dispatch]);

  return <>{generateTagsFromSubsidarySelections()}</>;
};

export default CompanyTags;
