import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  Quarter,
  Month,
  NumericMonth,
  NumericQuarter,
  PickerView,
  Period,
} from '../Context/PeriodSelector/types';

/**
 *
 * @param selectedYear the selected Year from the period picker, as a dayjs object
 * @param selectedMonth the selected Month from the period picker, as a string
 * @param selectedQuarter the selected Quarter from the period picker, as a string
 *
 *
 * The Period picker only allows a single selection. Meaning if you pick a month + year, quarter is null.
 * Quarter + year, the month is null.
 * Year only (under the yearly selector), month AND quarter are null.
 * YTD, again both month and Quarter will be null.
 *
 * Both YTD and Year use the same URL format on the front end and are differentiated on the back end.
 * EG: YTD if the current year was 2020 would be sent as /yearly/2020. All of 2019 would be set as /yearly/2019
 *
 *
 * Monthly is formatted as /monthly/${selectedYear}${numericValueOfSelectedMonth}/
 * EG: May of 2020 would be /monthly/202005/
 *
 * Quarterly is formatted as /quarterly/$selectedYear}${numericValueOfSelectedQuarter}/
 * EG: Q1 of 2020 would be /quarterly/202001/
 */

/**
 * Transforms the numeric month into a 2 width padded string (ex. 1 => '01' for January)
 *
 * @param monthAsNumber - A number representing a month
 * @returns a 2-width padded month string (ex. 1 => '01' for January)
 */
const getPaddedMonth = (monthAsNumber: number): string => {
  return monthAsNumber < 10 ? `0${monthAsNumber}` : `${monthAsNumber}`;
};

export const getFormatttedPeriodURL = (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYTD: boolean,
): string => {
  const formattedYear = dayjs(selectedYear).format('YYYY');

  // Month can be selected for either monthly view, or historical YTD view.
  if (selectedMonth) {
    const monthAsNumber = NumericMonth[selectedMonth] + 1;
    const formattedMonth = getPaddedMonth(monthAsNumber);
    const timing = isYTD ? 'ytd' : 'monthly';
    return `${timing}/${formattedYear}${formattedMonth}`;
  }
  if (selectedQuarter) {
    return `quarterly/${formattedYear}${selectedQuarter}`;
  }
  // If we only have a year that, means we're in the annual view.
  return `ytd/${formattedYear}12`;
};

export const getFormatttedPreviousPeriodURL = (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYTD: boolean,
): string => {
  const formattedPreviousYear = dayjs(selectedYear)
    .subtract(1, 'year')
    .format('YYYY');

  const url = `${formattedPreviousYear}12`;

  if (!selectedMonth && !selectedQuarter) {
    return url;
  }

  if (selectedMonth) {
    if (isYTD) {
      // 0 based enum so grabbing the current value +1 is the current month
      const monthAsNumber = NumericMonth[selectedMonth] + 1;
      const formattedMonth = getPaddedMonth(monthAsNumber);

      return `${formattedPreviousYear}${formattedMonth}`;
    }
    // 0 based enum so grabbing the current value is actually 1 less than actual current month
    let previousMonthAsNumber = NumericMonth[selectedMonth];
    let formattedYear = dayjs(selectedYear).format('YYYY');

    if (previousMonthAsNumber === 0) {
      previousMonthAsNumber = 12;
      formattedYear = formattedPreviousYear;
    }

    const formattedMonth = getPaddedMonth(previousMonthAsNumber);

    return `${formattedYear}${formattedMonth}`;
  }

  if (selectedQuarter) {
    let previousQuarterAsNumber = NumericQuarter[selectedQuarter];
    let formattedYear = dayjs(selectedYear).format('YYYY');
    if (previousQuarterAsNumber === 0) {
      previousQuarterAsNumber = 4;
      formattedYear = formattedPreviousYear;
    }

    return `${formattedYear}q${previousQuarterAsNumber}`;
  }
  return url;
};

export const getFrequencyTypeFromPicker = (
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYTD: boolean,
): PickerView => {
  if (isYTD) {
    return PickerView.YTD;
  }

  if (!selectedMonth && !selectedQuarter) {
    return PickerView.Annual;
  }

  if (selectedMonth) {
    return PickerView.Monthly;
  }

  return PickerView.Quarterly;
};

interface PeriodData {
  frequency: PickerView;
  period: Period;
}

export const getPeriodDataFromPicker = (
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYTD: boolean,
): PeriodData => {
  const frequency = getFrequencyTypeFromPicker(
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const isYearly = !selectedMonth && !selectedQuarter;
  if (isYTD || isYearly) {
    return {
      frequency,
      period: Period.TWELVE,
    };
  }

  if (selectedMonth) {
    const monthIndex = Object.keys(Month).indexOf(selectedMonth);
    const period = monthIndex as Period;
    return {
      frequency,
      period,
    };
  }

  if (selectedQuarter) {
    const quarterIndex = Object.keys(Quarter).indexOf(selectedQuarter);
    const period = quarterIndex as Period;
    return {
      frequency,
      period,
    };
  }

  return {
    frequency,
    period: Period.TWELVE,
  };
};

export const parseCustomDate = (dateString: string): Dayjs => {
  dayjs.extend(customParseFormat);
  return dayjs(dateString, 'YYYYMMDD');
};

export default getFormatttedPeriodURL;
