import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useOktaAuth } from '@okta/okta-react';
import {
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  Fade,
} from '@material-ui/core';
import useSetAnchorEl from '../../CustomHooks/useSetAnchorEl';
import logomain from '../../Assets/NT-iaa-logo.svg';
import ContactUs from '../Selectors/ContactUs';
import '@ntet/fos-fed-styles/dist/css/styles.css';

const NavBar: FC<{}> = (): JSX.Element => {
  const { authService } = useOktaAuth();
  const [anchorEl, handleClick, handleClose] = useSetAnchorEl();

  const handleLogout = (): void => {
    const { localStorage } = window;
    localStorage.removeItem('sessionKey');
    authService.logout();
    handleClose();
  };

  return (
    <StyledNavContainer>
      <StyledBrandLink to='/'>
        <img
          className='brand main'
          data-testid='nt-logo'
          src={logomain}
          alt='Northern Trust | Investment Accounting & Analytic Solutions'
        />
      </StyledBrandLink>
      <StyledNavLinksContainer>
        <ContactUs />
        <StyledNavButton
          data-testid='account-portrait-button'
          onClick={handleClick}
        >
          <i className='icon--person' />
        </StyledNavButton>

        <StyledMUIMenu
          data-testid='account-menu'
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem data-testid='logout-button' onClick={handleLogout}>
            <ListItemText>
              <Typography variant='button'>Logout</Typography>
            </ListItemText>
          </MenuItem>
        </StyledMUIMenu>
      </StyledNavLinksContainer>
    </StyledNavContainer>
  );
};

const StyledNavContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.aqua[700]};
  display: flex;
  min-height: 8rem;
  justify-content: space-between;
  width: 100%;
`;

export const StyledBrandLink = styled(Link)`
  .brand {
    padding-left: 3.2rem;
  }
`;

const StyledNavLinksContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 24rem;
`;

const StyledNavButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }): string => theme.colors.white};
  i[class^='icon--'],
  i[class*=' icon--'] {
    display: inline-block;
    font-size: 2.4rem;
    width: 1em;
    overflow: hidden;
  }
`;

const StyledMUIMenu = styled(Menu)`
  .MuiListItem-root:not(.MuiButtonBase-root) .MuiTypography-body1 {
    font-family: ${({ theme }): string => theme.fonts.robotoMedium};
    font-size: 2.1rem;
    font-weight: 500;
  }

  .MuiTypography-body1 {
  }

  .MuiListItem-button:hover {
    background-color: ${({ theme }): string => theme.colors.darkGreen[100]};
  }
`;

export default NavBar;
