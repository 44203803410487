import React, { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  GridOptions,
  ColDef,
  ColGroupDef,
  CellClickedEvent,
} from 'ag-grid-community';
import '../../Grid/Grid.css';
import { ReportActions } from '../../../Context/View/ViewActions';
import { formatMonetaryNumberLocaleString } from '../../../Helpers/grid-formatters';
import '@ntet/fos-fed-styles/dist/css/styles.css';
import { fetchEarnedIncomeSmallGridData } from '../../../DataFetch/small-grid-api';
import { useModal } from '../../../Context/ModalContext/ModalContext';
import { useReportDispatch } from '../../../Context/View/ViewProvider';

import {
  ReportViewSelectorValue,
  ColumnModifier,
} from '../../../Context/View/types';
import useSmallGridData from '../../../CustomHooks/useSmallGridData';
import { tagMappings } from '../../../Helpers/label-helper';

export const categoryTagMappings = new Map<string, string>([
  ['Total Earned Income', 'earnedincomebaseccy'],
  ['Accretion Of Discount', 'accretionofdiscountbaseccy'],
  ['Amortization Of Premium', 'amortizationofpremiumbaseccy'],
  ['Income Sold', 'accruedincomesoldbaseccy'],
  ['Income Purchased', 'accruedincomepurchasedbaseccy'],
  ['Income Received', 'incomereceivedbaseccy'],
  ['Net Change In Due And Accrued', 'netincomeaccrualbaseccy'],
  ['Other Expenses', 'otherexpensesbaseccy'],
]);

const EarnedIncomeSmallGridView: FC<{
  selectedChartCategory: string;
}> = ({ selectedChartCategory }): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();
  const dispatch = useReportDispatch();

  const data = useSmallGridData({
    queryTarget: selectedChartCategory,
    queryKey: `earned-income-small-grid-${selectedChartCategory}`,
    queryFunction: fetchEarnedIncomeSmallGridData,
  });

  const gridOptions: GridOptions = {
    animateRows: true,
    defaultColDef: {
      filter: false,
      resizable: true,
      suppressMenu: true,
    },
    suppressContextMenu: true,
    onCellClicked: (event: CellClickedEvent): void => {
      const { colDef, value } = event;
      if (colDef.field && colDef.field === 'category') {
        const columnModifiers = new Map<string, ColumnModifier>();
        const columnName =
          selectedChartCategory === 'ALL'
            ? categoryTagMappings.get(value)
            : 'assetsupercategory';

        if (columnName) {
          const columnModifier: ColumnModifier = {
            field: columnName,
            filter: true,
            pin: 'left',
            value: selectedChartCategory === 'ALL' ? '' : value,
          };

          columnModifiers.set(columnName, columnModifier);

          setModalVisibility(!isModalShown);
          dispatch(
            ReportActions.setExpandedReportType({
              expandedReportType: ReportViewSelectorValue.EarnedIncome,
              columnModifiers,
            }),
          );
        }
      }
    },
  };

  const colDefs: Array<ColDef | ColGroupDef> = [
    {
      field: 'category',
      headerName: tagMappings.get('category'),
      width: 300,
      cellStyle: { 'font-weight': 500 },
    },
    {
      field: 'current',
      headerName: tagMappings.get('current'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'prior',
      headerName: tagMappings.get('prior'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'netChange',
      headerName: tagMappings.get('earnedIncomeNetChange'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
  ];

  return (
    <StyledAgGridContainer className='ag-theme-views'>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={gridOptions.defaultColDef}
        gridOptions={gridOptions}
        rowData={data}
        statusBar={undefined}
      />
    </StyledAgGridContainer>
  );
};

export default EarnedIncomeSmallGridView;

const StyledAgGridContainer = styled.div`
  border-top: 2px solid
    ${(props): string => props.theme.colors.NTDarkGrey[700]}};
  height: calc(100% - 5.2rem);
  width: 100%;
`;
