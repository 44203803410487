import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import RoutePaths from './Helpers/route-constants';
import Dashboard from './Components/Dashboard';

const ContentRoutes: FC<{}> = (): JSX.Element => (
  <Switch>
    <Route exact path={RoutePaths.Dashboard} component={Dashboard} />
  </Switch>
);

export default ContentRoutes;
