/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Dayjs } from 'dayjs';
import { createAction } from '../CreateActionHelper';
import { ActionsUnion } from '../SharedContextTypes';
import { Month, Quarter } from './types';

export enum PeriodAction {
  ON_YTD_VIEW_ENTERED = 'ON_YTD_VIEW_ENTERED',
  ON_YTD_VIEW_EXITED = 'ON_YTD_VIEW_EXITED',
  ON_YEAR_SLIDER_CHANGED = 'ON_YEAR_SLIDER_CHANGED',
  ON_MONTH_SELECTED = 'ON_MONTH_SELECTED',
  ON_QUARTER_SELECTED = 'ON_QUARTER_SELECTED',
  ON_YTD_SELECTED = 'ON_YTD_SELECTED',
  PERSIST_SELECTIONS = 'PERSIST_SELECTIONS',
  CANCEL_SELECTIONS = 'CANCEL_SELECTIONS',
}

export const PeriodSelectorActions = {
  onYtdViewDeselected: () => createAction(PeriodAction.ON_YTD_VIEW_EXITED),
  onYtdViewSelected: () => createAction(PeriodAction.ON_YTD_VIEW_ENTERED),
  onYearSliderToggled: (selectedYear: Dayjs) =>
    createAction(PeriodAction.ON_YEAR_SLIDER_CHANGED, selectedYear),
  onMonthSelected: (selectedMonth: Month, isYTD: boolean) =>
    createAction(PeriodAction.ON_MONTH_SELECTED, {
      month: selectedMonth,
      isYTD,
    }),
  onQuarterSelected: (selectedQuarter: Quarter) =>
    createAction(PeriodAction.ON_QUARTER_SELECTED, selectedQuarter),
  persistSelections: (isYearly: boolean) =>
    createAction(PeriodAction.PERSIST_SELECTIONS, isYearly),
  cancelSelections: () => createAction(PeriodAction.CANCEL_SELECTIONS),
};

export type PeriodSelectorActions = ActionsUnion<typeof PeriodSelectorActions>;
