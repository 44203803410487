import React, { FC } from 'react';
import styled from 'styled-components';
import { Select } from '@ntet/fos-ui';
import { Typography } from '@material-ui/core';
import { usePeriodStatus, PublishStatus } from 'Context/PeriodStatusProvider';

export interface PeriodStatusSelectOptions {
  value: string;
  label: string;
}

const periodStatusOptions: PeriodStatusSelectOptions[] = [
  {
    label: PublishStatus.CERTIFIED,
    value: PublishStatus.FINAL,
  },
  {
    label: PublishStatus.BEST,
    value: PublishStatus.REVIEW,
  },
];

const PeriodStatus: FC = (): JSX.Element => {
  const { updateSelectedStatus, selectedStatus } = usePeriodStatus();

  const selectedStatusOption = periodStatusOptions.find(
    option => option.value === selectedStatus,
  );

  return (
    <StyledTextContainer>
      <StyledBoldText variant='body1'>{`Current Period Status: `}</StyledBoldText>
      <StyledViewSelect
        data-testid='period-status-view-select'
        onChange={updateSelectedStatus}
        value={selectedStatusOption}
        options={periodStatusOptions}
      />
    </StyledTextContainer>
  );
};

export default PeriodStatus;

const StyledTextContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 2.2rem;
  padding: 0.6rem;
`;

const StyledBoldText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const StyledViewSelect = styled(Select)`
  min-width: 14rem;
  .fos__control {
    border-width: 0;
  }
`;
