import React, { FC } from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Security } from '@okta/okta-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { enableMapSet } from 'immer';
import authService from './Helpers/okta-helper';
import AppRoutes from './Routes';

enableMapSet();

const App: FC<{}> = (): JSX.Element => (
  <Router>
    <Security authService={authService}>
      <AppRoutes />
    </Security>
    <ReactQueryDevtools initialIsOpen={false} />
  </Router>
);

export default App;
