import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import StackedBarChart from '../../Visualizations/StackedBarChart';
import ChartData from '../../Visualizations/ChartData';
import { EarnedIncomeColors } from '../../../Helpers/tag-color-helper';
import { usePeriodState } from '../../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../../Context/View/ViewProvider';
import { fetchChartData } from '../../../DataFetch/earned-income';
import { useCompanyState } from '../../../Context/CompanyDataContext/CompanyProvider';
import CardLoader from '../../CardLoader';
import LoadingError from '../../LoadingError';
import { getPeriodDataFromPicker } from '../../../Helpers/period-helper';
import { useUser } from '../../../Context/UserProvider';
import { usePeriodStatus } from '../../../Context/PeriodStatusProvider';
import hasEarnedIncomeData from '../../../Helpers/earned-income-helper';

interface Props {
  onDataCategoryClicked?: (category: string) => void;
}

const EarnedIncomeBarChartView: FC<Props> = ({
  onDataCategoryClicked,
}): JSX.Element => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { savedSelections } = useCompanyState();
  const { clientID, clientIDOverride } = useUser();
  const { accountingBasis, reportType } = useReportState();
  const { selectedStatus } = usePeriodStatus();

  const { frequency, period } = getPeriodDataFromPicker(
    selectedMonth,
    selectedQuarter,
    isYearToDate,
  );

  const { status, data } = useQuery(
    [
      'earned-income-data',
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
    ],
    fetchChartData,
    { refetchOnWindowFocus: false },
  );
  const hasData = hasEarnedIncomeData(data);

  if (status === 'loading') {
    return <CardLoader />;
  }

  if (status === 'error' || !data || !hasData) {
    return <LoadingError />;
  }

  return (
    <StyledCardContainer>
      <Grid container item xs={12}>
        <StyledChartContainer>
          <StackedBarChart
            height={350}
            width={750}
            onBarChartRectClicked={onDataCategoryClicked}
            frequency={frequency}
            period={period}
            data={data}
          />
        </StyledChartContainer>
        <StyledChartDataContainer>
          <ChartData
            onValueClicked={onDataCategoryClicked}
            colorLabels={EarnedIncomeColors}
            dataTotals={data.dataCategories}
            useLabelForSmallGrid
          />
        </StyledChartDataContainer>
      </Grid>
    </StyledCardContainer>
  );
};

export default EarnedIncomeBarChartView;

const StyledCardContainer = styled.div`
  display: flex;
  height: calc(100% - 5.2rem);
`;

const StyledChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .axis .domain {
    display: none;
  }
  line {
    display: none;
  }
  .change-analysis-rect {
    stroke: white;
  }
`;

const StyledChartDataContainer = styled.div`
  margin: 0 6rem 0;
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
