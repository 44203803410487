import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core/';
import PickerOption from '../../../Buttons/PickerOption';
import { usePeriodContext } from '../../../../Context/PeriodSelector/PeriodProvider';
import { PeriodState, Month } from '../../../../Context/PeriodSelector/types';
import { PeriodSelectorActions } from '../../../../Context/PeriodSelector/PeriodActions';

const MonthlyView: FC<{
  isYTD?: boolean;
  availableDates: string[];
}> = ({ isYTD, availableDates = [] }): JSX.Element => {
  const [periodState, dispatch] = usePeriodContext();

  const {
    currentSelections: { selectedMonth, selectedYear },
  }: PeriodState = periodState;

  const selectYearString = selectedYear.year().toString();
  const yearStringLength = 4;
  const monthStringIndex = 4;
  const monthStringLength = 2;

  //  Filter to current year and trim to only month substring
  const availableMonths = availableDates
    .filter(dateString => {
      return dateString.substr(0, yearStringLength) === selectYearString;
    })
    .map(dateString => dateString.substr(monthStringIndex));

  return (
    <StyledMonthlyOptionContainer>
      <Grid container spacing={1}>
        {Object.keys(Month).map((label: string, index: number) => {
          //  Convert the Month into a String, with padded zeros if necessary
          //  If the month is NOT present in availableMonths,
          //  there is no data and the picker should be disabled.
          const currentMonthString = (index + 1)
            .toString()
            .padStart(monthStringLength, '0');
          const isDisabled = availableMonths.indexOf(currentMonthString) === -1;
          return (
            <Grid key={label} item xs={4}>
              <PickerOption
                label={label}
                isSelected={label === selectedMonth && !isDisabled}
                onClick={(): void => {
                  dispatch(
                    PeriodSelectorActions.onMonthSelected(
                      label as Month,
                      isYTD as boolean,
                    ),
                  );
                }}
                height={5}
                width={10}
                disabled={isDisabled}
              />
            </Grid>
          );
        })}
      </Grid>
    </StyledMonthlyOptionContainer>
  );
};

export default MonthlyView;

const StyledMonthlyOptionContainer = styled.div`
  padding: 1rem;
`;
