/* eslint-disable react/jsx-wrap-multilines */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Switch, FormControlLabel } from '@material-ui/core';
import { StyledBodyText } from '../StyledWrappers/WrappedTypography';

interface Props {
  toggleShowAccountSelections: () => void;
  selectedAccountsPaneShown: boolean;
}

const SelectedAccountsToggle: FC<Props> = ({
  toggleShowAccountSelections,
  selectedAccountsPaneShown,
}): JSX.Element => {
  return (
    <StyledFormLabel
      control={
        <StyledSwitch
          checked={selectedAccountsPaneShown}
          onChange={toggleShowAccountSelections}
        />
      }
      label={<StyledBodyText>Show Selected Accounts</StyledBodyText>}
    />
  );
};

export default SelectedAccountsToggle;

const StyledFormLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 1rem;
  }
`;

const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${(props): string =>
      props.theme.colors.darkBlue[400]} !important;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${(props): string => props.theme.colors.darkBlue[400]};
  }
`;
