import { getAccountingBasisEnumerationFetchProps } from '../Helpers/data-uri-helper';

export interface AccountingBasisEnumerationResponse {
  availableAccountingBases: string[];
}

export const fetchAccountingBasisEnumerationData = async (
  _queryKey: string,
  clientId: string,
): Promise<string[] | null> => {
  const { URI, accessToken } = await getAccountingBasisEnumerationFetchProps(
    clientId,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((response: Response) => response.json())
      .then(
        (data: AccountingBasisEnumerationResponse) =>
          data?.availableAccountingBases,
      );
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};
