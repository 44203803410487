import React, { FC } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useUser } from '../../Context/UserProvider';
import { usePreferences } from '../../Context/PreferencesProvider';
import InputModal from './InputModal';
import { deleteLayout, Layout } from '../../DataFetch/layouts';
import { selectedLayoutByView } from '../../Helpers/preferences-helper';

interface Props {
  expandedReportType: string;
  inputModalOpen: boolean;
  setInputModalOpen: Function;
  setModalType: Function;
  setIsDeleting: Function;
}

const DeleteModal: FC<Props> = ({
  expandedReportType,
  inputModalOpen,
  setInputModalOpen,
  setModalType,
  setIsDeleting,
}): JSX.Element => {
  const queryCache = useQueryCache();
  const { layouts, prefState, prefDispatch, updatePreferences } =
    usePreferences();
  const { sub, clientID, clientIDOverride } = useUser();

  const selectedLayout = selectedLayoutByView(
    layouts,
    expandedReportType,
    prefState,
  );
  const existingLayoutId = selectedLayout?.layoutId;
  let adjustedLayouts = layouts;

  const [mutateDeletion] = useMutation(deleteLayout, {
    onSettled: () => {
      setIsDeleting(false);
    },
    onSuccess: () => {
      queryCache.invalidateQueries('layouts');
    },
  });

  const removeDeletedLayout = (): Array<Layout> => {
    const tempLayoutArr = layouts;
    const index = tempLayoutArr.indexOf(selectedLayout);
    tempLayoutArr.splice(index, 1);

    return tempLayoutArr;
  };

  // After deleting a layout, we want to remove it from the
  // preferences layout array, so it does not get pushed to the db.
  const adjustForDelete = (): void => {
    adjustedLayouts = removeDeletedLayout();
  };

  const handleDelete = (view: string): void => {
    // Something has gone very wrong if we're lacking an id for the layout we want to delete.
    if (existingLayoutId) {
      setIsDeleting(true);

      mutateDeletion({
        userId: sub,
        clientID: clientIDOverride ?? clientID,
        existingLayoutId,
      });

      updatePreferences({
        clientId: clientIDOverride,
        layoutArr: adjustedLayouts,
      });
      prefDispatch({ type: view, payload: '' });
    }
  };

  const notificationTitle = (): string => {
    return `Deleted ${prefState[expandedReportType]}`;
  };

  return (
    <InputModal
      handleSubmit={handleDelete}
      expandedReportType={expandedReportType}
      inputModalOpen={inputModalOpen}
      setInputModalOpen={setInputModalOpen}
      setModalType={setModalType}
      notificationTitle={notificationTitle}
      modalTitle={`Are you sure you want to delete ${prefState[expandedReportType]}?`}
      adjustedLayouts={adjustedLayouts}
      postSubmitAction={adjustForDelete}
      hasInput={false}
      subHeader='You will not be able to undo this action.'
      confirmBtnText='Delete'
      selectedLayout={selectedLayout}
    />
  );
};

export default DeleteModal;
