/* eslint-disable no-param-reassign */
import React, {
  FC,
  ReactNode,
  useContext,
  useReducer,
  createContext,
  Dispatch,
  useMemo,
} from 'react';

import dayjs from 'dayjs';
import produce, { Draft } from 'immer';
import { PeriodSelectorActions, PeriodAction } from './PeriodActions';
import { PeriodState, Month } from './types';

const months = Object.values(Month);

const initialState: PeriodState = {
  currentSelections: {
    selectedYear: dayjs(),
    selectedMonth: months[dayjs().get('month')],
    selectedQuarter: null,
    isYearToDate: true,
  },
  savedSelections: {
    selectedYear: dayjs(),
    selectedMonth: months[dayjs().get('month')],
    selectedQuarter: null,
    isYearToDate: true,
  },
};

const PeriodStateContext = createContext<PeriodState | undefined>(undefined);
const PeriodDispatchContext = createContext<
  Dispatch<PeriodSelectorActions> | undefined
>(undefined);

const periodReducer = (
  state: PeriodState,
  action: PeriodSelectorActions,
): PeriodState => {
  return produce(state, (draft: Draft<PeriodState>) => {
    switch (action.type) {
      case PeriodAction.ON_YTD_VIEW_ENTERED: {
        draft.currentSelections.isYearToDate = true;
        break;
      }
      case PeriodAction.ON_YTD_VIEW_EXITED: {
        draft.currentSelections.isYearToDate = false;
        break;
      }
      case PeriodAction.ON_YEAR_SLIDER_CHANGED: {
        draft.currentSelections.selectedYear = action.payload;
        break;
      }
      case PeriodAction.ON_MONTH_SELECTED: {
        draft.currentSelections.selectedMonth = action.payload.month;
        draft.currentSelections.selectedQuarter = null;
        break;
      }
      case PeriodAction.ON_QUARTER_SELECTED: {
        draft.currentSelections.selectedQuarter = action.payload;
        draft.currentSelections.selectedMonth = null;
        break;
      }
      case PeriodAction.PERSIST_SELECTIONS: {
        const isYearlyView = action.payload;
        if (isYearlyView) {
          draft.currentSelections.selectedMonth = null;
          draft.currentSelections.selectedQuarter = null;
        }
        draft.savedSelections = draft.currentSelections;
        break;
      }
      case PeriodAction.CANCEL_SELECTIONS: {
        draft.currentSelections = draft.savedSelections;
        break;
      }
      default: {
        break;
      }
    }
  });
};

const PeriodProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer<
    (state: PeriodState, action: PeriodSelectorActions) => PeriodState
  >(periodReducer, initialState);

  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, [dispatch]);

  return (
    <PeriodStateContext.Provider value={memoizedState}>
      <PeriodDispatchContext.Provider value={memoizedDispatch}>
        {children}
      </PeriodDispatchContext.Provider>
    </PeriodStateContext.Provider>
  );
};

const usePeriodState = (): PeriodState => {
  const data = useContext(PeriodStateContext);
  if (data === undefined) {
    throw new Error('usePeriodState must be used within a PeriodProvider');
  }

  return data;
};

const usePeriodDispatch = (): Dispatch<PeriodSelectorActions> => {
  const dispatch = useContext(PeriodDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'usePeriodDispatch must be used within a PeriodDispatchProvider',
    );
  }
  return dispatch;
};

const usePeriodContext = (): [PeriodState, Dispatch<PeriodSelectorActions>] => {
  return [usePeriodState(), usePeriodDispatch()];
};

export { PeriodProvider, usePeriodState, usePeriodDispatch, usePeriodContext };
