import { Dayjs } from 'dayjs';

export interface PeriodSelections {
  selectedYear: Dayjs;
  selectedMonth: Month | null;
  selectedQuarter: Quarter | null;
  isYearToDate: boolean;
}
export interface PeriodState {
  currentSelections: PeriodSelections;
  savedSelections: PeriodSelections;
}

export enum PickerView {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annual = 'Annual',
  YTD = 'YTD',
}

export enum Month {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEP = 'SEP',
  OCT = 'OCT',
  NOV = 'NOV',
  DEC = 'DEC',
}
/*
 * This is used as a quick look-up table when we need the numeric representation of the above String month.
 */
export enum NumericMonth {
  JAN,
  FEB,
  MAR,
  APR,
  MAY,
  JUN,
  JUL,
  AUG,
  SEP,
  OCT,
  NOV,
  DEC,
}

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export enum NumericQuarter {
  Q1,
  Q2,
  Q3,
  Q4,
}

export enum Period {
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  ELEVEN,
  TWELVE,
}
