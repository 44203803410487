import React, { FC } from 'react';
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import styled from 'styled-components';
import WrappedMUIListItem, {
  WrappedListItemProps,
} from '../../StyledWrappers/WrappedMUIListItem';

import { CompanyDataActions } from '../../../Context/CompanyDataContext/CompanyActions';
import { useCompanyDispatch } from '../../../Context/CompanyDataContext/CompanyProvider';

import { SubsidiaryAccount } from '../../../Context/CompanyDataContext/types';

const SubsidiaryAccountListItem: FC<{
  subsidiaryAccount: SubsidiaryAccount;
  subsidiaryId: string;
}> = ({ subsidiaryAccount, subsidiaryId }): JSX.Element => {
  const dispatch = useCompanyDispatch();

  const { ACCOUNT_NAME, ID, isChecked } = subsidiaryAccount;
  const labelId = `subsidiary-account-checkbox-list-label-${ID}`;

  return (
    <>
      <StyledSubsidiaryAccountListItem
        data-testid={labelId}
        key={ID}
        role={undefined}
        isSelected={isChecked}
        dense
        button
        onClick={(): void => {
          dispatch(
            CompanyDataActions.toggleSubsidiaryAccountSelected({
              id: ID,
              subsidiaryId,
            }),
          );
        }}
      >
        <ListItemIcon>
          <Checkbox
            checked={isChecked}
            disableRipple
            edge='start'
            inputProps={{ 'aria-labelledby': labelId }}
            tabIndex={-1}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={<Typography variant='h6'>{ACCOUNT_NAME}</Typography>}
        />
      </StyledSubsidiaryAccountListItem>
    </>
  );
};

export default SubsidiaryAccountListItem;

const StyledSubsidiaryAccountListItem = styled(
  WrappedMUIListItem,
)<WrappedListItemProps>`
  height: 3rem;

  &.MuiListItem-root.MuiListItem-button {
    padding-left: 7rem;

    background-color: ${(
      props: WrappedListItemProps & { theme: any },
    ): string => {
      return props.isSelected
        ? props.theme.colors.teal[100]
        : props.theme.colors.white;
    }};
  }
`;
