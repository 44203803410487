import React, { FC, Suspense, lazy, useRef, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from '@ntet/fos-ui';
import { AgGridReact } from 'ag-grid-react';
import { saveLayout, Layout } from '../../DataFetch/layouts';
import { useModal } from '../../Context/ModalContext/ModalContext';
import { useUser } from '../../Context/UserProvider';
import Modal from '../../Context/ModalContext/Modal';
import SelectedAccountsContainer from '../SelectedAccountsContainer';
import useSelectedAccountsPane from '../../CustomHooks/useSelectedAccountsPane';
import GridHeader from './GridHeader';
import PositionLotProvider, {
  PositionLotOption,
} from '../../Context/PositionLotContext';
import { usePreferences } from '../../Context/PreferencesProvider';

const GridDetail = lazy(() => import('./GridDetail'));

const GridOverlay: FC = (): JSX.Element => {
  const { sub, userDisplayName, clientID, clientIDOverride } = useUser();
  const queryCache = useQueryCache();
  const gridRef = useRef<AgGridReact | null>(null);
  const [model, setGridModel] = useState<any | null>(null);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [positionLotValue, setPositionLotValue] = useState(
    PositionLotOption.Lot,
  );

  const { prefDispatch, updatePreferences } = usePreferences();

  const [mutateLayout] = useMutation(saveLayout, {
    onSettled: () => {
      setIsSaving(false);
    },
    onSuccess: () => {
      queryCache.invalidateQueries('layouts');
    },
  });

  const handleSubmit = (
    view: string,
    layoutName: string,
    layoutArr: Array<Layout>,
    isFavorited: boolean,
  ): void => {
    const uuid = uuidv4().replace(/-/g, '');

    // ASSUMPTIONS: we can only a new layout, cannot resave/overwrite an existing layout.
    layoutArr?.push({
      layoutId: `${encodeURIComponent(view)}-${sub}-${userDisplayName}-${uuid}`,
      layoutLabel: `${layoutName}`,
      isFavorited,
      isNew: false, // Only shared layouts should appear with the "New" alert.  We know we made our own layout..
    });

    setIsSaving(true);

    updatePreferences({
      clientId: clientIDOverride ?? clientID,
      layoutArr,
    });

    mutateLayout({
      userId: sub,
      clientID: clientIDOverride ?? clientID,
      view,
      layoutName,
      data: model,
      userDisplayName,
      uuid,
    });
  };

  const handleReset = (view: string): void => {
    setIsResetting(true);

    prefDispatch({ type: view, payload: '' });

    setTimeout(() => {
      setIsResetting(false);
    }, 1000);
  };
  const { isModalShown } = useModal();
  const [showAccountSelections, toggleShowAccountSelections] =
    useSelectedAccountsPane();
  const test = {
    setGridModel,
  };

  return (
    <>
      {isModalShown && (
        <Modal>
          <Suspense fallback={<Loader />}>
            <PositionLotProvider
              setPositionLotValue={setPositionLotValue}
              positionLotValue={positionLotValue}
            >
              <GridHeader
                toggleShowAccountSelections={toggleShowAccountSelections}
                selectedAccountsPaneShown={showAccountSelections}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                isResetting={isResetting}
                setIsSaving={setIsSaving}
              />
              <SelectedAccountsContainer
                isShown={showAccountSelections}
                disableDelete
              />
              {/* Need to re-render GridDetail in order to avoid duplicating state b/w toggles (holdings view) */}
              {!isResetting && !isSaving && positionLotValue === 'POSITION' && (
                <GridDetail ref={gridRef} {...test} />
              )}
              {!isResetting && !isSaving && positionLotValue !== 'POSITION' && (
                <GridDetail ref={gridRef} {...test} />
              )}
            </PositionLotProvider>
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default GridOverlay;
