import { useQuery } from 'react-query';
import { PublishStatus } from '../Context/PeriodStatusProvider';
import {
  CalendarEnablerResponse,
  fetchCalendarEnablerResponseData,
} from '../DataFetch/calendar-enabler';
import {
  AccountingBasisValue,
  ReportViewSelectorValue,
} from '../Context/View/types';

interface Props {
  reportType: ReportViewSelectorValue;
  clientId: string;
  accountingBasis: AccountingBasisValue;
  selectedStatus: PublishStatus;
}

const useCalendarEnabler = (
  props: Props,
): CalendarEnablerResponse | undefined => {
  const { data } = useQuery(
    [
      'calendar-enabler',
      props.reportType,
      props.clientId,
      props.accountingBasis,
      props.selectedStatus,
    ],
    fetchCalendarEnablerResponseData,
  );

  return data;
};

export default useCalendarEnabler;
