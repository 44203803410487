import React, { FC } from 'react';
import { scaleOrdinal, ScaleLinear, ScaleOrdinal } from 'd3';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import get from 'lodash/get';
import { ChangeAnalysisColors } from '../../Helpers/tag-color-helper';
import { ChangeAnalysisChartSlice } from '../../DataFetch/change-analysis';

interface ChangeAnalysisChartProps {
  data: ChangeAnalysisChartSlice[];
  xScale: ScaleLinear<number, number>;
}

const ChangeAnalysisChart: FC<ChangeAnalysisChartProps> = ({
  data,
  xScale,
}): JSX.Element => {
  const colors: ScaleOrdinal<string, any> =
    scaleOrdinal().range(ChangeAnalysisColors);
  const CHART_HEIGHT = 50; // this should be the same as tr.chart-row height in ChangeAnalysisCard
  const BASE_RECTANGLE_WIDTH = 80;
  const { width, ref: resizeRef } = useResizeDetector();

  // attach the range to the scale here now that we have access
  // to the width
  xScale.rangeRound([0, width || 1]);
  return (
    <StyledResizeDetector ref={resizeRef}>
      <svg width='100%' height={CHART_HEIGHT}>
        <g>
          {data.map(d => {
            if (width) {
              const label = get(d, 'key', '');
              const domain = get(d, ['data', 'data', 'domain'], '');
              const key = `${domain}-${label}-rect`;
              return (
                <rect
                  stroke='white'
                  fill={colors(label) || 'white'}
                  y={0}
                  x={xScale(d.data[0])}
                  width={
                    xScale(d.data[1]) - xScale(d.data[0]) + BASE_RECTANGLE_WIDTH
                  }
                  height={CHART_HEIGHT}
                  key={key}
                />
              );
            }
            return '';
          })}
        </g>
      </svg>
    </StyledResizeDetector>
  );
};

export default ChangeAnalysisChart;

const StyledResizeDetector = styled.div`
  height: 100%;
  width: 100%;
`;
