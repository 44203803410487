/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { StyledSubtitleText } from '../StyledWrappers/WrappedTypography';
import { formatLocaleStringFromNumber } from '../../Helpers/grid-formatters';

export interface ChartDataSummation {
  label: string;
  value?: number;
}

interface ChartDataProps {
  dataTotals: Array<ChartDataSummation>;
  colorLabels?: Array<string>;
  onValueClicked?: (category: string) => void;
  useLabelForSmallGrid?: boolean;
}

const ChartData: FC<ChartDataProps> = ({
  dataTotals,
  colorLabels,
  onValueClicked,
  useLabelForSmallGrid,
}): JSX.Element => {
  return (
    <StyledValueSumsContainer>
      {dataTotals.map((dataTotal: ChartDataSummation, index) => {
        const { label, value } = dataTotal;
        return (
          <StyledValueRow key={`${label}-${value}`}>
            {colorLabels && (
              <StyledColorLabel
                backgroundColor={colorLabels[index % colorLabels.length]}
              />
            )}
            <StyledLinkContainer>
              <Link
                component='button'
                onClick={(): void => {
                  if (onValueClicked) {
                    onValueClicked(
                      useLabelForSmallGrid ? label.toUpperCase() : 'ALL',
                    );
                  }
                }}
              >
                <StyledSubtitleText variant='subtitle1'>
                  {label}
                </StyledSubtitleText>
              </Link>

              {value && (
                <StyledSubtitleText variant='h6'>
                  {formatLocaleStringFromNumber(Number(value))}
                </StyledSubtitleText>
              )}
            </StyledLinkContainer>
          </StyledValueRow>
        );
      })}
    </StyledValueSumsContainer>
  );
};

export default ChartData;

const wrappedDiv: FC<{ backgroundColor: string }> = ({
  // eslint-disable-next-line no-unused-vars
  backgroundColor,
  ...props
}) => <div {...props} />;

const StyledValueRow = styled.div`
  display: flex;
  flex-basis: 33.3%;
  justify-content: start;
  width: 28rem;
`;

const StyledValueSumsContainer = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: ${({ theme }): string => theme.colors.green[500]};
  }
`;

const StyledColorLabel = styled(wrappedDiv)<any>`
  align-self: center;
  border-radius: 50%;
  border: 0;
  background-color: ${({ backgroundColor }): string => backgroundColor};
  height: 1.3rem;
  width: 1.5rem;
  margin-right: 1.5rem;
`;

const StyledLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;
