import React, { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  GridOptions,
  ColDef,
  ColGroupDef,
  CellClickedEvent,
} from 'ag-grid-community';
import '../../Grid/Grid.css';
import { TransactionType, Aggregate } from '../../../Helpers/data-uri-helper';
import { formatMonetaryNumberLocaleString } from '../../../Helpers/grid-formatters';
import '@ntet/fos-fed-styles/dist/css/styles.css';
import { fetchChangeAnalysisSmallGridData } from '../../../DataFetch/small-grid-api';
import { useModal } from '../../../Context/ModalContext/ModalContext';
import { useReportDispatch } from '../../../Context/View/ViewProvider';
import {
  ReportViewSelectorValue,
  ColumnModifier,
  ExpandedReportView,
} from '../../../Context/View/types';
import useSmallGridData from '../../../CustomHooks/useSmallGridData';
import { ReportActions } from '../../../Context/View/ViewActions';
import { tagMappings } from '../../../Helpers/label-helper';

const ChangeAnalysisSmallGridView: FC<{
  selectedChartValue: string | TransactionType | null;
  analysisType: Aggregate;
}> = ({ selectedChartValue, analysisType }): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();
  const dispatch = useReportDispatch();

  // TODO this has a ton of room for improvement. Magic string comparisons no good.
  // Needs contract with back end.
  const getReportTypeToExpand = (
    selectedValue: string | TransactionType | null,
  ): ExpandedReportView => {
    if (!selectedValue) {
      return ReportViewSelectorValue.EarnedIncome;
    }

    if (
      selectedValue === 'AMORTIZED COST' ||
      selectedValue === 'MARKET VALUE'
    ) {
      return ReportViewSelectorValue.Holdings;
    }
    if (
      Object.values(TransactionType).indexOf(
        selectedValue as TransactionType,
      ) !== -1 ||
      selectedValue === 'NET MONEY OF TRANSACTIONS'
    ) {
      return ReportViewSelectorValue.Transactions;
    }
    if (selectedValue === 'REALIZED GAIN LOSS') {
      return ReportViewSelectorValue.AmortizedCostProgression;
    }
    return ReportViewSelectorValue.EarnedIncome;
  };

  const data = useSmallGridData({
    queryTarget:
      selectedChartValue != null ? selectedChartValue.toUpperCase() : '',
    analysisType,
    queryKey: `change-analysis-small-grid-${selectedChartValue}`,
    queryFunction: fetchChangeAnalysisSmallGridData,
  });

  const gridOptions: GridOptions = {
    animateRows: true,
    defaultColDef: {
      filter: false,
      resizable: true,
      suppressMenu: true,
    },
    suppressContextMenu: true,
    onCellClicked: (event: CellClickedEvent): void => {
      const { colDef, value } = event;
      if (
        colDef.field &&
        (colDef.field === 'category' ||
          colDef.field === 'reportingtransactiontype')
      ) {
        const columnModifiers = new Map<string, ColumnModifier>();

        let columnModifier: ColumnModifier;
        if (analysisType === Aggregate.AggregateHoldings) {
          columnModifier = {
            field: 'assetsubcategory',
            filter: true,
            pin: 'left',
            value,
          };
          columnModifiers.set('assetsubcategory', columnModifier);
        } else {
          columnModifier = {
            field: 'reportingtransactiontype',
            filter: true,
            pin: 'left',
            value,
          };
          columnModifiers.set(colDef.field, columnModifier);
        }

        setModalVisibility(!isModalShown);
        dispatch(
          ReportActions.setExpandedReportType({
            expandedReportType: getReportTypeToExpand(selectedChartValue),
            columnModifiers,
          }),
        );
      }
    },
  };

  const colDefs: Array<ColDef | ColGroupDef> =
    analysisType === Aggregate.AggregateHoldings
      ? [
          {
            field: 'category',
            headerName: tagMappings.get('category'),
            width: 300,
            cellStyle: { 'font-weight': 500 },
          },
          {
            field: 'current',
            headerName: tagMappings.get('current'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
          },
          {
            field: 'prior',
            headerName: tagMappings.get('prior'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
          },
          {
            field: 'netChange',
            headerName: tagMappings.get('netChange'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
            width: 300,
          },
        ]
      : [
          {
            field: 'assetsupercategory',
            headerName: tagMappings.get('assetsupercategory'),
            width: 300,
            cellStyle: { 'font-weight': 500 },
          },
          {
            field: 'reportingtransactiontype',
            headerName: tagMappings.get('reportingtransactiontype'),
            width: 300,
            cellStyle: { 'font-weight': 500 },
          },
          {
            field: 'transactioncount',
            headerName: tagMappings.get('transactioncount'),
            type: 'numericColumn',
          },
          {
            field: 'amortizedcostdisposedbaseccy',
            headerName: tagMappings.get('amortizedcostdisposedbaseccy'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
          },
          {
            field: 'proceedsbaseccy',
            headerName: tagMappings.get('proceedsbaseccy'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
          },
          {
            field: 'tradegainslossesbaseccy',
            headerName: tagMappings.get('tradegainslossesbaseccy'),
            valueFormatter: formatMonetaryNumberLocaleString,
            type: 'numericColumn',
          },
        ];

  return (
    <StyledAgGridContainer className='ag-theme-views'>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={gridOptions.defaultColDef}
        gridOptions={gridOptions}
        rowData={data}
        statusBar={undefined}
      />
    </StyledAgGridContainer>
  );
};

export default ChangeAnalysisSmallGridView;

const StyledAgGridContainer = styled.div`
  border-top: 2px solid
    ${(props): string => props.theme.colors.NTDarkGrey[700]}};
  height: calc(100% - 5.2rem);
  width: 100%;
`;
