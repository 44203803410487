import React, { FC } from 'react';
import { usePreferences } from '../../Context/PreferencesProvider';
import InputModal from './InputModal';

interface Props {
  handleSubmit: Function;
  expandedReportType: string;
  inputModalOpen: boolean;
  setInputModalOpen: Function;
  setModalType: Function;
}

const SaveModal: FC<Props> = ({
  handleSubmit,
  expandedReportType,
  inputModalOpen,
  setInputModalOpen,
  setModalType,
}): JSX.Element => {
  const { layouts } = usePreferences();

  const notificationTitle = (userInput: string): string => {
    return `${userInput} was saved.`;
  };

  return (
    <InputModal
      handleSubmit={handleSubmit}
      expandedReportType={expandedReportType}
      inputModalOpen={inputModalOpen}
      setInputModalOpen={setInputModalOpen}
      setModalType={setModalType}
      notificationTitle={notificationTitle}
      modalTitle='Save analysis set'
      adjustedLayouts={layouts}
      hasInput
      confirmBtnText='Save'
    />
  );
};

export default SaveModal;
