import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@ntet/fos-ui';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { usePreferences } from '../../Context/PreferencesProvider';
import { useUser } from '../../Context/UserProvider';
import usePrevious from '../../CustomHooks/usePrevious';
import { Layout } from '../../DataFetch/layouts';

import {
  getLayoutViewType,
  findFavoritedLayout,
  findNewLayout,
} from '../../Helpers/preferences-helper';

interface Props {
  expandedReportType: string;
  isChooseDropDownOpen: boolean;
  chooseAnchorEl: Element | null;
  setIsChooseDropDownOpen: Function;
  setIsDropdownOpen: Function;
  isResetting: boolean;
  isDeleting: boolean;
}

const ChooseDropDown: FC<Props> = ({
  expandedReportType,
  isChooseDropDownOpen,
  setIsChooseDropDownOpen,
  chooseAnchorEl,
  setIsDropdownOpen,
  isResetting,
  isDeleting,
}): JSX.Element => {
  const { clientID, clientIDOverride } = useUser();
  const { prefDispatch, prefState, layouts, updatePreferences } =
    usePreferences();
  const previousPrefState = usePrevious(prefState);

  const sortedLayoutNameOnly = layouts
    ?.filter(
      (layout: Layout) => getLayoutViewType(layout) === expandedReportType,
    )
    ?.map((layout: Layout) => layout.layoutLabel)
    ?.sort((a: any, b: any) => a.localeCompare(b)); // sort case insensitive

  const favoritedLayout = findFavoritedLayout(layouts, expandedReportType);
  const favoritedLayoutName = favoritedLayout
    ? favoritedLayout.layoutLabel
    : null;

  const [selectedView, setSelectedView] = useState(
    favoritedLayout
      ? favoritedLayout.layoutLabel
      : prefState[expandedReportType],
  );

  useEffect(
    () => {
      if (isResetting || (isDeleting && !favoritedLayoutName)) {
        setSelectedView('');
      } else if (
        prefState[expandedReportType] &&
        previousPrefState !== prefState
      ) {
        // Deleting Selected Layout -> favorited is new selected
        // Saving new layout -> new layout is selected
        // Renaming new layout -> renamed layout is still selected
        // Selecting first layout of session with no favorite
        setSelectedView(prefState[expandedReportType]);
      } else if (favoritedLayoutName && !prefState[expandedReportType]) {
        // setting selected view as favorited if it exists.
        setSelectedView(favoritedLayoutName);
        prefDispatch({
          type: expandedReportType,
          payload: favoritedLayoutName,
        });
      } else {
        // This case is used when there is no selected view or favorited view and user opens drop downs
        // and closes it without selecting anything which leaves a blank ag-grid
        setSelectedView(prefState[expandedReportType]);
      }
    },
    // There are things we dont want in dependency array.
    // 1/2: isDeleting/Resetting as we only care about the initial value of it and
    // b/c it eventually changes from onSuccess callback which we dont re-rendering this.
    // 3: favoriteLayoutName b/c we do not want to re-render view on favoriting
    // eslint-disable-next-line
    [prefState, expandedReportType, prefDispatch, previousPrefState],
  );

  const onClose = (name: string): void => {
    setSelectedView(name);
    if (name !== prefState[expandedReportType]) {
      prefDispatch({ type: expandedReportType, payload: name });

      const selectedLayout = layouts
        ?.filter(
          (layout: Layout) => getLayoutViewType(layout) === expandedReportType,
        )
        ?.find((layout: Layout) => layout.layoutLabel === name);

      const selectedLayoutIndex = layouts.findIndex(
        (layout: Layout) => layout.layoutId === selectedLayout.layoutId,
      );

      // If we've selected a new layout, mark it as no longer new.
      if (selectedLayoutIndex >= 0 && layouts[selectedLayoutIndex].isNew) {
        layouts[selectedLayoutIndex].isNew = false;

        updatePreferences({
          clientId: clientIDOverride ?? clientID,
          layoutArr: layouts,
        });
      }
    }

    setIsChooseDropDownOpen(false);
    setIsDropdownOpen(false);
  };

  const handleOnFavorite = (name: string): void => {
    const clickedLayout = layouts
      ?.filter(
        (layout: Layout) => getLayoutViewType(layout) === expandedReportType,
      )
      ?.find((layout: Layout) => layout.layoutLabel === name);

    const clickedLayoutIndex = layouts.findIndex(
      (layout: Layout) => layout.layoutId === clickedLayout.layoutId,
    );

    if (clickedLayoutIndex >= 0) {
      // Invert the layout's current 'isFavorited' value, and set it as no longer new.
      layouts[clickedLayoutIndex].isFavorited =
        !layouts[clickedLayoutIndex].isFavorited;
      layouts[clickedLayoutIndex].isNew = false;

      // Highlander rules, there can only be one.
      if (favoritedLayout) {
        favoritedLayout.isFavorited = false;
      }
    }

    updatePreferences({
      clientId: clientIDOverride ?? clientID,
      layoutArr: layouts,
    });
  };

  return (
    <StyledDropDown
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={chooseAnchorEl}
      open={isChooseDropDownOpen}
      onClose={(): void => {
        onClose(selectedView);
      }}
    >
      {sortedLayoutNameOnly?.map((name: string) => (
        <StyledListItem
          key={name}
          dense
          button
          ContainerComponent='div'
          onClick={(): void => {
            onClose(name);
          }}
          selected={name === selectedView}
        >
          <StyledListItemIcon>
            {findNewLayout(layouts, expandedReportType, name) && (
              <i className='icon--media_record' />
            )}
            {name === selectedView && <i className='icon--check' />}
          </StyledListItemIcon>
          <StyledListItemText>{name}</StyledListItemText>
          <ListItemSecondaryAction>
            <StyledIconButton
              edge='end'
              aria-label='favorite'
              onClick={(): void => handleOnFavorite(name)}
              disableRipple
            >
              {favoritedLayout && favoritedLayout.layoutLabel === name ? (
                <i className='icon--favorite_on' />
              ) : (
                <i className='icon--favorite_off' />
              )}
            </StyledIconButton>
          </ListItemSecondaryAction>
        </StyledListItem>
      ))}
    </StyledDropDown>
  );
};

export default ChooseDropDown;

const StyledDropDown = styled(Dropdown)`
  height: 350px;
`;

const StyledListItem = styled(ListItem)`
  height: 3.2rem;
  &&.MuiListItem-root {
    margin: 0.2rem 0;
  }
  .MuiListItem-container {
    list-style-type: none;
  }

  &&.Mui-selected {
    background-color: ${({ theme }): string => theme.colors.aqua[100]};
  }

  i {
    color: ${({ theme }): string => theme.colors.aqua[700]};
  }
`;

const StyledIconButton = styled(IconButton)`
  i {
    color: ${({ theme }): string => theme.colors.aqua[700]};
  }

  &&.Mui-disabled {
    color: red;
    opacity: 0.4;
  }
  &&:hover {
    background: none;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 2.4rem;
  }
`;

const StyledListItemText = styled.p`
  font-size: 1.3rem;
`;
