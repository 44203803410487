import React, { FC, useState } from 'react';
import { TransactionType } from '../../Helpers/data-uri-helper';
import { ReportActions } from '../../Context/View/ViewActions';
import {
  useReportDispatch,
  useReportState,
} from '../../Context/View/ViewProvider';
import { VisualizationMode } from '../../Context/View/types';
import TransactionsSmallGridView from './Transactions/TransactionsSmallGridView';
import TransactionsDonutView from './Transactions/TransactionsDonutView';

const TransactionsCard: FC = (): JSX.Element => {
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType>(TransactionType.ALL);

  const reportDispatch = useReportDispatch();
  const { displayType } = useReportState();

  const onTransactionTypeClicked = (transactionType: string): void => {
    setSelectedTransactionType(transactionType as TransactionType);
    reportDispatch(ReportActions.updateDisplayType(VisualizationMode.Grid));
  };

  return displayType === VisualizationMode.Grid ? (
    <TransactionsSmallGridView transactionType={selectedTransactionType} />
  ) : (
    <TransactionsDonutView
      onTransactionTypeClicked={onTransactionTypeClicked}
    />
  );
};

export default TransactionsCard;
