import React, { FC } from 'react';
import SubNavBar from './SubNavBar';
import SelectedAccountsContainer from '../SelectedAccountsContainer';
import useSelectedAccountsPane from '../../CustomHooks/useSelectedAccountsPane';

const SubNavContainer: FC = (): JSX.Element => {
  const [showAccountSelections, toggleShowAccountSelections] =
    useSelectedAccountsPane();

  return (
    <>
      <SubNavBar
        toggleShowAccountSelections={toggleShowAccountSelections}
        selectedAccountsPaneShown={showAccountSelections}
      />
      <SelectedAccountsContainer isShown={showAccountSelections} />
    </>
  );
};

export default SubNavContainer;
