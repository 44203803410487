import { Dayjs } from 'dayjs';
import {
  Subsidiary,
  SubsidiaryAccount,
} from 'Context/CompanyDataContext/types';
import {
  getFormatttedPreviousPeriodURL,
  getFormatttedPeriodURL,
} from './period-helper';
import authService from './okta-helper';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import {
  ReportViewSelectorValue,
  ExpandedReportView,
  AccountingBasisValue,
  ReportURLSegment,
} from '../Context/View/types';
import { PublishStatus } from '../Context/PeriodStatusProvider';

interface FetchProps {
  URI: string;
  accessToken: string;
}

export enum AssetSuperCategory {
  CashAndCashEquivalents = 'CASH AND CASH EQUIVALENTS',
  Equities = 'EQUITIES',
  FixedIncome = 'FIXED INCOME',
  HedgeFund = 'HEDGE FUND',
  ALL = 'ALL',
}

export enum TransactionType {
  Buys = 'BUYS',
  Sells = 'SELLS',
  Calls = 'CALLS',
  Paydowns = 'PAYDOWNS',
  Other = 'OTHER',
  ALL = 'ALL',
}

export enum Aggregate {
  AggregateHoldings = 'aggregateholdings',
  AggregateTransactions = 'aggregatetransactions',
  AggregateTrialBalance = 'trialbalance',
}

export const getAggregateType = (
  reportView: ExpandedReportView,
): Aggregate | null => {
  switch (reportView) {
    case ReportViewSelectorValue.Holdings:
    case ReportViewSelectorValue.EarnedIncome:
    case ReportViewSelectorValue.AmortizedCostProgression:
    case 'Change Analysis':
      return Aggregate.AggregateHoldings;
    case ReportViewSelectorValue.Transactions:
      return Aggregate.AggregateTransactions;
    case ReportViewSelectorValue.TrialBalance:
      return Aggregate.AggregateTrialBalance;
    default:
      return null;
  }
};

const getURLStringForReportView = (
  reportView: ExpandedReportView,
): ReportURLSegment | null => {
  switch (reportView) {
    case ReportViewSelectorValue.Holdings:
      return ReportURLSegment.Holdings;
    case ReportViewSelectorValue.EarnedIncome:
      return ReportURLSegment.EarnedIncome;
    case ReportViewSelectorValue.Transactions:
      return ReportURLSegment.Transactions;
    case ReportViewSelectorValue.TrialBalance:
      return ReportURLSegment.TrialBalance;
    case ReportViewSelectorValue.AmortizedCostProgression:
      return ReportURLSegment.AmortizedCostProgression;
    case 'Change Analysis':
      return ReportURLSegment.ChangeAnalysis;
    default:
      return null;
  }
};

export const getCompanyDropdownFetchProps = async (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  return {
    URI: `${
      process.env.REACT_APP_BASE_URL
    }/hierarchy/${clientID}/${periodURLsegment}/${encodeURI(
      publishStatus,
    )}?session=${sessionKey}`,
    accessToken,
  };
};

const getSelectedAccountsURLParam = (
  savedAccountSelections: Subsidiary[],
): string => {
  const selectedAccounts: string[] = [];
  savedAccountSelections.forEach((subsidiary: Subsidiary) => {
    subsidiary.accounts.forEach((subsidiaryAccount: SubsidiaryAccount) => {
      if (subsidiaryAccount.isChecked) {
        selectedAccounts.push(subsidiaryAccount.ID);
      }
    });
  });
  return selectedAccounts.join(',');
};

const getSelectedLegalEntitiesUrlParam = (
  savedAccountSelections: Subsidiary[],
): string => {
  const selectedEntities: string[] = [];
  savedAccountSelections.forEach((subsidiary: Subsidiary) => {
    if (subsidiary.isChecked) {
      selectedEntities.push(subsidiary.ID);
    }
  });
  return selectedEntities.join(',');
};

export const getVisualizationDataFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const reportURLString = getURLStringForReportView(reportType);

  const selectedAccountsURLParam = getSelectedAccountsURLParam(
    savedAccountSelections,
  );

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  // TODO: Hardcoded for demo, along with console logs above.
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/${reportURLString}/${clientID}/${accountingBasis.toLowerCase()}/${periodURLsegment}/${encodeURI(
    publishStatus,
  )}`.concat(`?sa=${selectedAccountsURLParam}&session=${sessionKey}`);

  return {
    URI,
    accessToken,
  };
};

export const getTrialBalanceCardFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const reportURLString = getURLStringForReportView(reportType);

  const selectedLegalEntitiesURLParam = getSelectedLegalEntitiesUrlParam(
    savedAccountSelections,
  );

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  // TODO: Hardcoded for demo, along with console logs above.
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/${reportURLString}/${clientID}/${accountingBasis.toLowerCase()}/${periodURLsegment}/${encodeURI(
    publishStatus,
  )}`.concat(`?le=${selectedLegalEntitiesURLParam}&session=${sessionKey}`);

  return {
    URI,
    accessToken,
  };
};

export const getChangeAnalysisCardFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  savedAccountSelections: Subsidiary[],
  publishStatus: PublishStatus,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const previousPeriodURLsegment = getFormatttedPreviousPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const reportURLString = getURLStringForReportView(reportType);

  const dataAggregateType = getAggregateType(reportType);

  const selectedAccountsURLParam = getSelectedAccountsURLParam(
    savedAccountSelections,
  );

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/${reportURLString}/${clientID}/${accountingBasis.toLowerCase()}/${dataAggregateType}/${periodURLsegment}/${previousPeriodURLsegment}/${encodeURI(
    publishStatus,
  )}`.concat(`?sa=${selectedAccountsURLParam}&session=${sessionKey}`);

  return {
    URI,
    accessToken,
  };
};

export const getGridDataFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  savedAccountSelections: Subsidiary[],
  publishStatus: PublishStatus,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const dataAggregateType = getAggregateType(reportType);

  let selectedAccountsURLParam;

  if (dataAggregateType === Aggregate.AggregateTrialBalance) {
    const legalEntitiesURLParam = getSelectedLegalEntitiesUrlParam(
      savedAccountSelections,
    );
    selectedAccountsURLParam = `?le=${legalEntitiesURLParam}`;
  } else {
    const accountsURLParam = getSelectedAccountsURLParam(
      savedAccountSelections,
    );
    selectedAccountsURLParam = `?sa=${accountsURLParam}`;
  }

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';
  // TODO: Hardcoded for demo, along with console logs above.
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/data/${clientID}/${accountingBasis.toLowerCase()}/${dataAggregateType}/${periodURLsegment}/${encodeURI(
    publishStatus,
  )}`
    .concat(selectedAccountsURLParam)
    .concat(`&session=${sessionKey}`);

  return {
    URI,
    accessToken,
  };
};

export const getSmallGridDataFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  let encodedQueryTarget = '';
  if (queryTarget) {
    encodedQueryTarget = encodeURI(queryTarget);
  }

  const dataAggregateType = getAggregateType(reportType);

  const reportURLString = getURLStringForReportView(reportType);

  let selectedAccountsURLParam;

  if (dataAggregateType === Aggregate.AggregateTrialBalance) {
    const legalEntitiesURLParam = getSelectedLegalEntitiesUrlParam(
      savedAccountSelections,
    );
    selectedAccountsURLParam = `?le=${legalEntitiesURLParam}`;
  } else {
    const accountsURLParam = getSelectedAccountsURLParam(
      savedAccountSelections,
    );
    selectedAccountsURLParam = `?sa=${accountsURLParam}`;
  }

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/${reportURLString}-small-grid/${clientID}/${accountingBasis.toLowerCase()}/${periodURLsegment}/${encodeURI(
    publishStatus,
  )}/${encodedQueryTarget}`
    .concat(selectedAccountsURLParam)
    .concat(`&session=${sessionKey}`);

  return {
    URI,
    accessToken,
  };
};

export const getEarnedIncomeSmallGridDataFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const previousPeriodURLsegment = getFormatttedPreviousPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  let encodedQueryTarget = '';
  if (queryTarget) {
    encodedQueryTarget = encodeURI(queryTarget);
  }

  const reportURLString = getURLStringForReportView(reportType);

  const accountsURLParam = getSelectedAccountsURLParam(savedAccountSelections);

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/${reportURLString}-small-grid/${clientID}/${accountingBasis.toLowerCase()}/${periodURLsegment}/${previousPeriodURLsegment}/${encodeURI(
    publishStatus,
  )}/${encodedQueryTarget}?sa=${accountsURLParam}&session=${sessionKey}`;

  return {
    URI,
    accessToken,
  };
};

export const getChangeAnalysisSmallGridDataFetchProps = async (
  accountingBasis: AccountingBasisValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
  analysisType?: Aggregate,
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const previousPeriodURLsegment = getFormatttedPreviousPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  let encodedQueryTarget = '';
  if (queryTarget) {
    encodedQueryTarget = encodeURIComponent(queryTarget);
  }

  const accountsURLParam = getSelectedAccountsURLParam(savedAccountSelections);

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/change-analysis-small-grid/${clientID}/${accountingBasis.toLowerCase()}/${analysisType}/${periodURLsegment}/${previousPeriodURLsegment}/${encodeURI(
    publishStatus,
  )}/${encodedQueryTarget}?sa=${accountsURLParam}&session=${sessionKey}`;

  return {
    URI,
    accessToken,
  };
};

export const getXLSDownloadFetchProps = async (
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const periodURLsegment = getFormatttedPeriodURL(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    isYTD,
  );

  const dataAggregateType = getAggregateType(reportType);

  const { localStorage } = window;
  const sessionKey = localStorage.getItem('sessionKey') || '';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/xls-download/${clientID}/${accountingBasis.toLowerCase()}/${dataAggregateType}/${periodURLsegment}/${
    publishStatus === PublishStatus.REVIEW ? 'preview' : 'final'
  }?session=${sessionKey}`;

  return {
    URI,
    accessToken,
  };
};

export const getAccountingBasisEnumerationFetchProps = async (
  clientId: string,
): Promise<FetchProps> => {
  const URI = `${process.env.REACT_APP_BASE_URL}/accounting-bases/${clientId}`;
  const accessToken = await authService.getAccessToken();

  return {
    URI,
    accessToken,
  };
};

export const getCalendarEnablerResponseFetchProps = async (
  reportType: ReportViewSelectorValue,
  clientId: string,
  accountingBasis: AccountingBasisValue,
  publishStatus: PublishStatus,
): Promise<FetchProps> => {
  const accessToken = await authService.getAccessToken();

  const reportURLString = getURLStringForReportView(reportType);
  const publishStatusURLString =
    publishStatus === PublishStatus.REVIEW ? 'preview' : 'final';

  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/calendar-enabler/${reportURLString}/${clientId}/${accountingBasis.toLowerCase()}/${publishStatusURLString}`;

  return {
    URI,
    accessToken,
  };
};
