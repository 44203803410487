import { Dispatch, useEffect } from 'react';
import { PeriodSelectorActions } from '../Context/PeriodSelector/PeriodActions';
import { usePeriodContext } from '../Context/PeriodSelector/PeriodProvider';
import { usePeriodStatus } from '../Context/PeriodStatusProvider';
import { useUser } from '../Context/UserProvider';
import { PeriodState } from '../Context/PeriodSelector/types';

import {
  fetchCompanyData,
  useCompanyDispatch,
} from '../Context/CompanyDataContext/CompanyProvider';

const useConnectedState = (): {
  periodSelectorState: PeriodState;
  selectedStatus: string;
  periodSelectorDispatch: Dispatch<PeriodSelectorActions>;
} => {
  const { clientID, clientIDOverride } = useUser();
  const { selectedStatus } = usePeriodStatus();
  const [periodSelectorState, periodSelectorDispatch] = usePeriodContext();

  const companyDispatch = useCompanyDispatch();

  useEffect(() => {
    const refetchCompanyData = async (): Promise<void> => {
      fetchCompanyData(
        periodSelectorState.savedSelections.selectedYear,
        periodSelectorState.savedSelections.selectedMonth,
        periodSelectorState.savedSelections.selectedQuarter,
        clientIDOverride ?? clientID,
        periodSelectorState.savedSelections.isYearToDate,
        selectedStatus,
        companyDispatch,
      );
    };
    refetchCompanyData();
  }, [
    companyDispatch,
    periodSelectorState.savedSelections.isYearToDate,
    periodSelectorState.savedSelections.selectedMonth,
    periodSelectorState.savedSelections.selectedQuarter,
    periodSelectorState.savedSelections.selectedYear,
    clientID,
    clientIDOverride,
    selectedStatus,
  ]);

  return {
    periodSelectorState,
    selectedStatus,
    periodSelectorDispatch,
  };
};

export default useConnectedState;
