import React, {
  FC,
  Dispatch,
  createContext,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import {
  PreferenceResponse,
  fetchPreferences,
  savePreference,
} from '../DataFetch/preferences';
import { Layout } from '../DataFetch/layouts';
import { useUser } from './UserProvider';
import { ReportViewSelectorValue } from './View/types';

interface SuccessResponse {
  id: string;
  preferences: any;
}

interface PrefProviderState {
  userPreferences: PreferenceResponse | undefined | null;
  layouts: Array<Layout>;
  prefState: PrefData;
  prefDispatch: Function;
  updatePreferences: Function;
}

interface UpdatePrefInput {
  clientId: string;
  layoutArr: Array<Layout>;
  settledCallback: (data: SuccessResponse | null | undefined) => void;
  ownerUserId: string;
  ownerDisplayName: string;
}

export interface PrefData {
  [index: string]: string | undefined;
}

interface PrefActions {
  type: string;
  payload: string;
}

const initialState: PrefData = {
  [ReportViewSelectorValue.Holdings]: undefined,
  [ReportViewSelectorValue.Transactions]: undefined,
  [ReportViewSelectorValue.EarnedIncome]: undefined,
  [ReportViewSelectorValue.TrialBalance]: undefined,
  [ReportViewSelectorValue.AmortizedCostProgression]: undefined,
};

const PreferencesContext = createContext<PrefProviderState | undefined | null>(
  undefined,
);
const PrefDispatchContext = createContext<Dispatch<PrefActions> | undefined>(
  undefined,
);

const prefReducer = (state: PrefData, action: PrefActions): PrefData => {
  switch (action.type) {
    case ReportViewSelectorValue.Holdings:
      return {
        ...state,
        [ReportViewSelectorValue.Holdings]: action.payload,
      };
    case ReportViewSelectorValue.Transactions:
      return {
        ...state,
        [ReportViewSelectorValue.Transactions]: action.payload,
      };
    case ReportViewSelectorValue.EarnedIncome:
      return {
        ...state,
        [ReportViewSelectorValue.EarnedIncome]: action.payload,
      };
    case ReportViewSelectorValue.TrialBalance:
      return {
        ...state,
        [ReportViewSelectorValue.TrialBalance]: action.payload,
      };
    case ReportViewSelectorValue.AmortizedCostProgression:
      return {
        ...state,
        [ReportViewSelectorValue.AmortizedCostProgression]: action.payload,
      };
    default:
      return { ...state };
  }
};

export const PreferencesProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const { sub, clientID, clientIDOverride, onSubmitClientIDOverride } =
    useUser();

  const { data: userPreferences } = useQuery(
    ['preferences', clientID, sub],
    fetchPreferences,
    {
      refetchOnWindowFocus: false,
    },
  );

  // clientIDOverride should only be blank if we haven't applied the default/the user hasn't navigated yet.
  // If that's the case, set the default clientId from preferences.
  if (!clientIDOverride) {
    const preferencesDefaultClient =
      userPreferences?.preferences?.defaultClient;
    if (preferencesDefaultClient) {
      onSubmitClientIDOverride(preferencesDefaultClient.toString());
    }
  }
  const layouts = userPreferences?.preferences?.layouts || []; // `?.` needed for first time users (empty table)

  const [prefState, prefDispatch] = useReducer(prefReducer, initialState);
  const queryCache = useQueryCache();

  const [mutatePref] = useMutation(savePreference, {
    onSuccess: () => {
      queryCache.invalidateQueries('preferences');
    },
  });

  /**
   * Update the saved user preferences for the user.
   * @param {string} - clientId for the user's default client.
   * @param {Array} - (optional) Array of saved layouts, that should be written.  If absent, the layouts array
   * will be unchanged.
   * @param {Object} - (optional) Object of useMutation callbacks, https://react-query-v3.tanstack.com/reference/useMutation
   */
  const updatePreferences = ({
    clientId,
    layoutArr,
    settledCallback,
  }: UpdatePrefInput): void => {
    mutatePref(
      {
        userId: sub,
        clientId,
        layoutArr: layoutArr || layouts,
      },
      {
        onSettled: settledCallback,
      },
    );
  };

  return (
    <PreferencesContext.Provider
      value={{
        userPreferences,
        layouts,
        prefState,
        prefDispatch,
        updatePreferences,
      }}
    >
      <PrefDispatchContext.Provider value={prefDispatch}>
        {children}
      </PrefDispatchContext.Provider>
    </PreferencesContext.Provider>
  );
};

export const usePrefDispatch = (): Dispatch<PrefActions> => {
  const dispatch = useContext(PrefDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'usePrefDispatch must be used within a usePreferences Context Provider',
    );
  }
  return dispatch;
};

export const usePreferences = (): PrefProviderState | any => {
  const userPreferences = useContext(PreferencesContext);

  if (userPreferences === undefined) {
    throw new Error(
      'usePreferences must be used within a usePreferences Context Provider',
    );
  }
  return userPreferences;
};
