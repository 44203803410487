import React, { FC } from 'react';
import styled from 'styled-components';
import { Popover, Typography } from '@material-ui/core';
import useSetAnchorEl from '../../CustomHooks/useSetAnchorEl';
import '@ntet/fos-fed-styles/dist/css/styles.css';

const ContactUs: FC<{}> = (): JSX.Element => {
  const [anchorEl, handleClick, handleClose] = useSetAnchorEl();

  return (
    <>
      <StyledContactUsToggle
        onClick={handleClick}
        aria-label='Contact Us'
        data-testid='contact-menu-toggle'
      >
        <Typography variant='h5'>CONTACT US</Typography>
      </StyledContactUsToggle>
      <StyledPopOver
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <StyledContactUsContainer data-testid='contact-menu'>
          <StyledHeader>
            <Typography variant='h5'>Contact Us</Typography>
          </StyledHeader>
          <EmailDetails />
          <PhoneDetails />
        </StyledContactUsContainer>
      </StyledPopOver>
    </>
  );
};

const EmailDetails: FC<{}> = (): JSX.Element => (
  <StyledSubHeader>
    <span>
      <Typography variant='h6' gutterBottom>
        Insurance Investment Accounting Team:
      </Typography>
    </span>

    <StyledEmailLink>
      <StyledMailIcon>
        <i className='icon--mail' />
      </StyledMailIcon>
      <a href='mailto:insurance_investment_accounting@ntrs.com'>
        <Typography variant='h6' gutterBottom>
          insurance_investment_accounting@ntrs.com
        </Typography>
      </a>
    </StyledEmailLink>
  </StyledSubHeader>
);

const PhoneDetails: FC<{}> = (): JSX.Element => (
  <StyledPhoneContainer>
    <StyledPhoneDetails>
      <span>
        <Typography variant='h6' gutterBottom>
          Anthony Eifrid
        </Typography>
      </span>
      <StyledNumberContainer>
        <i className='icon--phone' />
        <Typography variant='subtitle1'>
          <a href='tel:312-444-7447'>312.444.7447</a>
        </Typography>
      </StyledNumberContainer>
    </StyledPhoneDetails>

    <StyledPhoneDetails>
      <span>
        <Typography variant='h6' gutterBottom>
          Kirsten Wieneke
        </Typography>
      </span>
      <StyledNumberContainer>
        <i className='icon--phone' />
        <Typography variant='subtitle1'>
          <a href='tel:312-444-7749'>312.444.7749</a>
        </Typography>
      </StyledNumberContainer>
    </StyledPhoneDetails>
  </StyledPhoneContainer>
);

const StyledContactUsToggle = styled.a`
  color: ${({ theme }): string => theme.colors.white};
`;

const StyledEmailLink = styled.div`
  display: flex;
`;

const StyledPopOver = styled(Popover)`
  margin-top: 3rem;
`;

const StyledContactUsContainer = styled.div`
  font-family: ${({ theme }): string => theme.fonts.robotoMedium};
  display: flex;
  flex-direction: column;
  margin: 2rem;

  span {
    margin-left: 1.9rem;
  }

  i {
    margin-bottom: 0.4rem;
    margin-right: 0.4rem;
  }
`;

const StyledHeader = styled.span`
  margin-left: 1.9rem;
`;

const StyledSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;

  a {
    color: ${({ theme }): string => theme.colors.green[500]};
  }
`;

const StyledMailIcon = styled.div`
  align-self: center;
  color: ${({ theme }): string => theme.colors.black};
`;

const StyledPhoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  a {
    text-decoration: none;
    color: black;
  }
`;

const StyledPhoneDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledNumberContainer = styled.div`
  display: flex;
`;

export default ContactUs;
