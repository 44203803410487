import React, { FC } from 'react';
import styled from 'styled-components';
import { ListItemIcon, ListItemText, Typography } from '@material-ui/core/';

import WrappedMUIListItem, {
  WrappedListItemProps,
} from '../../StyledWrappers/WrappedMUIListItem';

import { PickerView } from '../../../Context/PeriodSelector/types';

interface Props {
  label: string;
  onSelectionMade: (view: PickerView) => void;
  isSelected: boolean;
}

const DatePickerListItem: FC<Props> = ({
  label,
  onSelectionMade,
  isSelected,
}): JSX.Element => {
  return (
    <>
      <StyledListItem
        isSelected={isSelected}
        onClick={(): void => onSelectionMade(label as PickerView)}
      >
        <ListItemText
          id='1'
          primary={<Typography variant='h6'>{label}</Typography>}
        />
        <ListItemIcon>
          <i className='icon--chevron_right' />
        </ListItemIcon>
      </StyledListItem>
    </>
  );
};

const StyledListItem = styled(WrappedMUIListItem)<WrappedListItemProps>`
  height: 3rem;
  cursor: pointer;

  &.MuiListItem-root {
    background-color: ${(
      props: WrappedListItemProps & { theme: any },
    ): string => {
      return props.isSelected
        ? props.theme.colors.teal[100]
        : props.theme.colors.white;
    }};
  }

  .MuiListItemIcon-root {
    justify-content: flex-end;
  }
`;

export default DatePickerListItem;
