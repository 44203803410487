import { Dayjs } from 'dayjs';
import { PublishStatus } from 'Context/PeriodStatusProvider';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import { getVisualizationDataFetchProps } from '../Helpers/data-uri-helper';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import { ChartDataSummation } from '../Components/Visualizations/ChartData';
import isNotNull from '../Helpers/type-guards';
import { tagMappings } from '../Helpers/label-helper';

export interface CostProgressionDataPoint {
  domain: string;
  value: number;
}

export interface CostProgressionDataSlice {
  name: string;
  data: Array<CostProgressionDataPoint>;
}

export interface CostProgressionMonthlyData {
  accretionofdiscountbaseccy: string;
  amortizationofpremiumbaseccy: string;
  amortizedcostdisposedbaseccy: string;
  bopamortizedcostbaseccy: string;
  costofacquisitionbaseccy: string;
  eopamortizedcostbaseccy: string;
  timeunit: string;
}

export interface CostProgressionQuarterlyData {
  q1_total: string;
  q2_total?: string;
  q3_total?: string;
  q4_total?: string;
}

export interface CostProgressionJSONResponse {
  monthly: Array<CostProgressionMonthlyData>;
  quarterly: CostProgressionQuarterlyData;
  dataCategories: Array<ChartDataSummation>;
}

export const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<CostProgressionJSONResponse | null> => {
  const { URI, accessToken } = await getVisualizationDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedAccountSelections,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: CostProgressionJSONResponse) => {
        if (data) {
          const { monthly, quarterly } = data;
          const dataCategories: Array<ChartDataSummation> = Object.keys(
            monthly[0],
          )
            .map((key: string) => {
              const userFriendlyString = tagMappings.get(key);
              if (userFriendlyString) return { label: userFriendlyString };
              return null;
            })
            .filter(isNotNull);

          return {
            monthly,
            quarterly,
            dataCategories,
          };
        }
        return null;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};
