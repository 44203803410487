import React, { FC } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import ColorTags from '../../Visualizations/ColorTags';
import {
  AssetAllocationSlice,
  fetchChartData,
} from '../../../DataFetch/portfolio-holdings-api';
import DonutChart from '../../Visualizations/DonutChart';
import ChartData from '../../Visualizations/ChartData';
import { DonutChartColors } from '../../../Helpers/tag-color-helper';
import { StyledBodyText } from '../../StyledWrappers/WrappedTypography';
import { usePeriodState } from '../../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../../Context/View/ViewProvider';
import { useCompanyState } from '../../../Context/CompanyDataContext/CompanyProvider';
import CardLoader from '../../CardLoader';
import LoadingError from '../../LoadingError';
import { useUser } from '../../../Context/UserProvider';
import { usePeriodStatus } from '../../../Context/PeriodStatusProvider';
import WarningNote from '../../WarningNote';

interface Props {
  onAssetSuperCategoryClicked?: (category: string) => void;
}

const HoldingsDonutView: FC<Props> = ({
  onAssetSuperCategoryClicked,
}): JSX.Element => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { savedSelections } = useCompanyState();
  const { accountingBasis, reportType } = useReportState();
  const { clientID, clientIDOverride } = useUser();
  const { selectedStatus } = usePeriodStatus();

  const { status, data } = useQuery(
    [
      'portfolio-holdings-data',
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
    ],
    fetchChartData,
    { refetchOnWindowFocus: false },
  );

  if (status === 'loading') {
    return <CardLoader />;
  }

  if (status === 'error' || !data) {
    return <LoadingError />;
  }

  const { allocationSlices, summatedData } = data;

  if (allocationSlices.length === 0 || summatedData.length === 0) {
    return (
      <StyledChartContainer>
        <StyledBodyText>No Data Available</StyledBodyText>
      </StyledChartContainer>
    );
  }

  return (
    <StyledChartContainer>
      <DonutChart
        data={allocationSlices}
        onSliceClicked={onAssetSuperCategoryClicked}
      />
      <StyledHoldingsSidebarContainer>
        <ChartData
          dataTotals={summatedData}
          onValueClicked={onAssetSuperCategoryClicked}
        />
        <ColorTags
          onTagClicked={onAssetSuperCategoryClicked}
          tagColors={DonutChartColors}
          tagLabels={allocationSlices.map(
            (assetAllocation: AssetAllocationSlice): string =>
              assetAllocation.assetSuperCategory,
          )}
        />
        <WarningNote />
      </StyledHoldingsSidebarContainer>
    </StyledChartContainer>
  );
};

export default HoldingsDonutView;

const StyledHoldingsSidebarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
  padding-right: 4rem;
`;

const StyledChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 5.2rem;
  width: 100%;
`;
