import { useCallback } from 'react';
import { useQuery } from 'react-query';
import authService from '../Helpers/okta-helper';
import { useUser } from '../Context/UserProvider';

type UseUserSearchParams = {
  searchText: string;
  enabled: boolean;
};

export type UserSearchResponse = {
  userId: string;
  displayName: string;
  emailAddress: string;
};

const useUserSearch = (variables: UseUserSearchParams): any => {
  const { searchText, enabled } = variables;
  const { isAdminUser, sub, clientID, clientIDOverride } = useUser();

  const clientId = clientIDOverride ?? clientID;
  const userId = sub;
  const isNT = isAdminUser;
  const queryKey = ['SEARCH_ITEMS', variables];

  const getSearchItems = useCallback(async () => {
    const accessToken = await authService.getAccessToken();
    const URI = `${process.env.REACT_APP_BASE_URL}/shareUserSearch/${clientId}/${userId}?isNT=${isNT}&searchText=${searchText}`;

    if (!searchText) {
      return [];
    }
    try {
      return await fetch(URI, {
        headers: { Authorization: accessToken },
      })
        .then((res: Response) => res.json())
        .then((resData: any) => {
          return resData;
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`errorin useUserSearch. Error: ${error}`);
      return undefined;
    }
  }, [clientId, userId, isNT, searchText]);

  return useQuery(queryKey, getSearchItems, { enabled });
};

export default useUserSearch;
