import {} from 'react';
import { useCompanyContext } from '../Context/CompanyDataContext/CompanyProvider';
import { CompanyDataActions } from '../Context/CompanyDataContext/CompanyActions';

const useSelectedAccountsPane = (): [boolean, () => void] => {
  const [companyState, companyDispatch] = useCompanyContext();

  const onAccountPaneSwitchToggled = (): void => {
    companyDispatch(CompanyDataActions.toggleSelectedAccountsPane());
  };

  return [companyState.displaySelectedAccountsPane, onAccountPaneSwitchToggled];
};

export default useSelectedAccountsPane;
