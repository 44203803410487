import {
  AccountingBasisValue,
  ReportViewSelectorValue,
} from '../Context/View/types';
import { PublishStatus } from '../Context/PeriodStatusProvider';
import { getCalendarEnablerResponseFetchProps } from '../Helpers/data-uri-helper';

export interface CalendarEnablerResponse {
  monthly: string[];
  ytd: string[];
  quarterly: string[];
  annual: string[];
}

export const emptyCalendarEnablerResponse: CalendarEnablerResponse = {
  monthly: [],
  ytd: [],
  quarterly: [],
  annual: [],
};

export const fetchCalendarEnablerResponseData = async (
  _queryKey: string,
  reportType: ReportViewSelectorValue,
  clientId: string,
  accountingBasis: AccountingBasisValue,
  publishStatus: PublishStatus,
): Promise<CalendarEnablerResponse> => {
  const { URI, accessToken } = await getCalendarEnablerResponseFetchProps(
    reportType,
    clientId,
    accountingBasis,
    publishStatus,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: CalendarEnablerResponse) => data);
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin calendar enabler. Error: ${error}`);
    return emptyCalendarEnablerResponse;
  }
};
