import { Dayjs } from 'dayjs';
import { PublishStatus } from 'Context/PeriodStatusProvider';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import { getVisualizationDataFetchProps } from '../Helpers/data-uri-helper';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import { ChartDataSummation } from '../Components/Visualizations/ChartData';
import { tagMappings } from '../Helpers/label-helper';

export interface TransactionAllocationJSON {
  acquisitioncost: string;
  costdisposed: string;
  percentageweight: string;
  proceeds: string;
  tradegainloss: string;
  transactionscount: string;
  reportingtransactiontype: string;
}

export interface TransactionAllocationSlice {
  reportingtransactiontype: string;
  marketPercent: number;
}

export interface TransactionReportData {
  assetCategoryVisualzationData: Array<ChartDataSummation>;
  transactionDonutSlices: Array<TransactionAllocationSlice>;
  uniqueTransactionTypes: Array<string>;
}

const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<TransactionReportData | null> => {
  const { URI, accessToken } = await getVisualizationDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedAccountSelections,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: Array<TransactionAllocationJSON>) => {
        if (data.length === 0) {
          return null;
        }

        const assetCategoryVisualzationData = [
          {
            label: tagMappings.get('acquisitioncost') ?? '',
            value: Number(data[0].acquisitioncost),
          },
          {
            label: tagMappings.get('costdisposed') ?? '',
            value: Number(data[0].costdisposed),
          },
          {
            label: tagMappings.get('proceeds') ?? '',
            value: Number(data[0].proceeds),
          },
          {
            label: tagMappings.get('tradegainloss') ?? '',
            value: Number(data[0].tradegainloss),
          },
        ];

        const transactionDonutSlices: Array<TransactionAllocationSlice> = [];
        const uniqueTransactionTypes = new Set<string>();

        data.forEach((transactionJSON: TransactionAllocationJSON) => {
          const marketPercent = Number(transactionJSON.percentageweight);

          if (marketPercent < 2) {
            return;
          }

          transactionDonutSlices.push({
            reportingtransactiontype: transactionJSON.reportingtransactiontype,
            marketPercent,
          });

          uniqueTransactionTypes.add(transactionJSON.reportingtransactiontype);
        });

        return {
          assetCategoryVisualzationData,
          transactionDonutSlices,
          uniqueTransactionTypes: Array.from(uniqueTransactionTypes),
        };
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};

export default fetchChartData;
