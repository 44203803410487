import React, { FC, useCallback, MouseEvent } from 'react';
import { CardHeader, Select } from '@ntet/fos-ui';
import styled from 'styled-components';
import ExpandButton from './Buttons/ExpandButton';
import GridChartToggle from './Buttons/GridChartToggle';
import { ReportActions } from '../Context/View/ViewActions';
import SelectedPeriodText from './SelectedPeriodText';
import { useReportContext } from '../Context/View/ViewProvider';
import AccountingSelector from './Selectors/AccountingSelector';
import {
  ReportView,
  ReportViewSelectorValue,
  VisualizationMode,
} from '../Context/View/types';

interface SelectOptions {
  value: string;
  label: string;
}

const viewOptions: SelectOptions[] = [
  {
    label: ReportView.Holdings,
    value: ReportViewSelectorValue.Holdings,
  },
  {
    label: ReportView.Transactions,
    value: ReportViewSelectorValue.Transactions,
  },
  {
    label: ReportView.EarnedIncome,
    value: ReportViewSelectorValue.EarnedIncome,
  },
  {
    label: ReportView.TrialBalance,
    value: ReportViewSelectorValue.TrialBalance,
  },
  {
    label: ReportView.AmortizedCostProgression,
    value: ReportViewSelectorValue.AmortizedCostProgression,
  },
  // {
  //   label: ReportView.PerformanceAndBenchmarking,
  //   value: ReportViewSelectorValue.PerformanceAndBenchmarking,
  // },
  // {
  //   label: ReportView.CashflowProjection,
  //   value: ReportViewSelectorValue.CashflowProjection,
  // },
];

const ReportCardHeader: FC<{}> = (): JSX.Element => {
  const [reportState, reportDispatch] = useReportContext();

  const view = viewOptions.find(val => val.value === reportState.reportType);

  const onGridChartToggle = useCallback(
    (_event: MouseEvent, value: string | null): void => {
      if (value !== null) {
        reportDispatch(
          ReportActions.updateDisplayType(value as VisualizationMode),
        );
      }
    },
    [reportDispatch],
  );

  const onViewChange = useCallback(
    (event: SelectOptions): void => {
      reportDispatch(
        ReportActions.updateViewType(event.value as ReportViewSelectorValue),
      );
    },
    [reportDispatch],
  );

  return (
    <CardHeader small>
      <StyledHeaderContainer>
        <StyledReportSelectContainer>
          <StyledViewSelect
            data-testid='card-view-select'
            onChange={onViewChange}
            value={view}
            options={viewOptions}
          />
          <AccountingSelector />
          <SelectedPeriodText />
        </StyledReportSelectContainer>
        <StyledActionsContainer>
          <GridChartToggle
            value={reportState.displayType}
            onChange={onGridChartToggle}
          />
          {view && (
            <ExpandButton reportType={view.value as ReportViewSelectorValue} />
          )}
        </StyledActionsContainer>
      </StyledHeaderContainer>
    </CardHeader>
  );
};

const StyledReportSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledViewSelect = styled(Select)`
  min-width: 24rem;
  .fos__control {
    border-width: 0;
  }
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ReportCardHeader;
