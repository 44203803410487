import React, { FC } from 'react';
import { ListItem, ListItemProps } from '@material-ui/core';

export interface WrappedListItemProps extends ListItemProps {
  isSelected: boolean;
}

const WrappedMUIListItem: FC<{ isSelected: boolean }> = ({
  isSelected,
  ...props
}) => <ListItem {...props} />;

export default WrappedMUIListItem;
