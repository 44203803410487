import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from '@ntet/fos-ui';
import { useQuery } from 'react-query';
import { useReportContext } from '../../Context/View/ViewProvider';
import { ReportActions } from '../../Context/View/ViewActions';
import {
  AccountingBasisOption,
  AccountingBasisValue,
} from '../../Context/View/types';
import { fetchAccountingBasisEnumerationData } from '../../DataFetch/accounting-basis-enumeration';
import { useUser } from '../../Context/UserProvider';

interface SelectOptions {
  value: string;
  label: string;
}

export const allAccountingOptions: SelectOptions[] = [
  {
    label: AccountingBasisOption.StatutoryReporting,
    value: AccountingBasisValue.Stat,
  },
  {
    label: AccountingBasisOption.GaapReporting,
    value: AccountingBasisValue.Gaap,
  },
  {
    label: AccountingBasisOption.TaxReporting,
    value: AccountingBasisValue.Tax,
  },
];

const AccountingSelector: FC = (): JSX.Element => {
  const [reportState, reportDispatch] = useReportContext();
  const [disabled, setDisabled] = useState<boolean>(true);

  const { clientID: clientId, clientIDOverride: clientIdOverride } = useUser();
  const { data } = useQuery(
    ['accounting-basis-enumeration', clientIdOverride ?? clientId],
    fetchAccountingBasisEnumerationData,
    { refetchOnWindowFocus: false },
  );
  const availableAccountingOptions = data || [];

  const selectedValue = allAccountingOptions.find(
    option => option.value === reportState.accountingBasis,
  );

  const onAccountingChange = (event: SelectOptions): void => {
    reportDispatch(
      ReportActions.updateAccountingBasis(event.value as AccountingBasisValue),
    );
  };

  useEffect(() => {
    setDisabled(availableAccountingOptions.length === 0);
    if (availableAccountingOptions.length === 0) {
      reportDispatch(
        ReportActions.updateAccountingBasis(AccountingBasisValue.Stat),
      );
    } else {
      //  Explicitly prioritize the ordering
      const firstOptionAvailable = [
        AccountingBasisValue.Stat,
        AccountingBasisValue.Gaap,
        AccountingBasisValue.Tax,
      ]
        .filter(allOption =>
          availableAccountingOptions.includes(allOption.toLowerCase()),
        )
        .shift();
      reportDispatch(
        ReportActions.updateAccountingBasis(
          firstOptionAvailable ?? AccountingBasisValue.Stat,
        ),
      );
    }
  }, [
    availableAccountingOptions,
    availableAccountingOptions.length,
    reportDispatch,
  ]);

  const filteredAccountingOptions = allAccountingOptions.filter(allOption =>
    availableAccountingOptions.find(
      availableOption => availableOption === allOption.value.toLowerCase(),
    ),
  );

  return (
    <StyledAccountingSelect
      data-testid='card-accounting-basis-select'
      onChange={onAccountingChange}
      value={selectedValue}
      isDisabled={disabled}
      options={filteredAccountingOptions}
    />
  );
};

const StyledAccountingSelect = styled(Select)`
  min-width: 16rem;
  .fos__control {
    border-width: 0;
  }
`;

export default AccountingSelector;
