import React, { FC } from 'react';
import styled from 'styled-components';
import { Chip, ChipProps } from '@material-ui/core';
import { NTTagColor } from '../../Helpers/tag-color-helper';

interface Props {
  label: string;
  onDelete: (() => void) | null;
  parentSubsidiaryId?: string;
  tagColor: NTTagColor;
}

const Tag: FC<Props> = (props): JSX.Element => {
  const { onDelete, label, tagColor } = props;

  return onDelete ? (
    <StyledMUIChip
      label={label}
      onDelete={onDelete}
      backgroundColor={tagColor.backgroundColor}
      borderColor={tagColor.borderColor}
    />
  ) : (
    <StyledMUIChip
      label={label}
      backgroundColor={tagColor.backgroundColor}
      borderColor={tagColor.borderColor}
    />
  );
};

export default Tag;

const WrappedChip: FC<{ backgroundColor: string; borderColor: string }> = ({
  backgroundColor,
  borderColor,
  ...props
}) => <Chip {...props} />;

const StyledMUIChip = styled(WrappedChip)<NTTagColor & ChipProps>`
  &.MuiChip-root {
    background-color: ${({ backgroundColor }: NTTagColor): string =>
      backgroundColor};
    border: 1px solid ${({ borderColor }: NTTagColor): string => borderColor};
    font-size: 1.2rem;
    height: 2.8rem;
    margin: 0.5rem 0.5rem;
    min-width: 8rem;
  }
`;
