import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledDisclaimerText } from './StyledWrappers/WrappedTypography';

const warningMessage =
  '* Though included in the cumulative totals, any asset types comprised of less than 1% of the portfolio are not viewable on the figure shown.  Please refer to the small grid visual as well as AG grid for these position details.';

const WarningNote: FC<{ customMessage?: string }> = ({
  customMessage,
}): JSX.Element => {
  return (
    <StyledMessageContainer>
      <StyledDisclaimerText>
        {customMessage ?? warningMessage}
      </StyledDisclaimerText>
    </StyledMessageContainer>
  );
};

export default WarningNote;

const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  padding: 1rem;
`;
