/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction } from '../CreateActionHelper';
import {
  ReportData,
  AccountingBasisValue,
  ReportViewSelectorValue,
  ExpandReportOptions,
  VisualizationMode,
} from './types';
import { ActionsUnion } from '../SharedContextTypes';

export enum ViewActions {
  INIT_DATA = 'INIT_DATA',
  UPDATE_VIEW_TYPE = 'UPDATE_VIEW_TYPE',
  UPDATE_ACCOUNTING_BASIS = 'UPDATE_ACCOUNTING_BASIS',
  UPDATE_DISPLAY_TYPE = 'UPDATE_DISPLAY_TYPE',
  SET_EXPANDED_REPORT_TYPE = 'SET_EXPANDED_REPORT_TYPE',
}

export const ReportActions = {
  initializeData: (responseData: ReportData) =>
    createAction(ViewActions.INIT_DATA, responseData),
  updateViewType: (view: ReportViewSelectorValue) =>
    createAction(ViewActions.UPDATE_VIEW_TYPE, view),
  updateAccountingBasis: (accountingBasis: AccountingBasisValue) =>
    createAction(ViewActions.UPDATE_ACCOUNTING_BASIS, accountingBasis),
  updateDisplayType: (displayType: VisualizationMode) =>
    createAction(ViewActions.UPDATE_DISPLAY_TYPE, displayType),
  setExpandedReportType: (expandGridOptions: ExpandReportOptions) =>
    createAction(ViewActions.SET_EXPANDED_REPORT_TYPE, expandGridOptions),
};

export type ReportActions = ActionsUnion<typeof ReportActions>;
