/* eslint-disable no-restricted-globals */
import { ValueFormatterParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const formatPercentage = (params: ValueFormatterParams): string => {
  const value = parseFloat(params.value);
  if (isNaN(value)) {
    return '';
  }

  return `${value.toFixed(2)} %`;
};

export const formatQuantityLocaleString = (
  params: ValueFormatterParams,
): string => {
  const value = Number(params.value);
  if (isNaN(value)) {
    return '';
  }

  return `${value.toLocaleString(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  })}`;
};

export const formatMonetaryNumberLocaleString = (
  params: ValueFormatterParams,
): string => {
  const value = Number(params.value);
  if (isNaN(value)) {
    return '';
  }

  return value < 0
    ? `(${Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })})`
    : `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
};

export const formatNumberLocaleString = (
  params: ValueFormatterParams,
): string => {
  const value = Number(params.value);
  if (isNaN(value)) {
    return '';
  }
  return value < 0
    ? `(${Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })})`
    : `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
};

export const formatLocaleStringFromNumber = (val: number): string => {
  return val < 0
    ? `(${Math.abs(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })})`
    : `${val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
};

export const formatDateTime = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return '';
  }
  dayjs.extend(customParseFormat);
  const dateString = params.value;
  const parsedValue = dayjs(dateString, 'YYYYMMDD');
  return `${parsedValue.format('MM/DD/YYYY')}`;
};
