import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ReportCards from './ReportCards';

const Dashboard: FC<{}> = (): JSX.Element => {
  // this Grid component is a copy of the deprecated @fos/fos-shared/CardCollection component, but is missing the themeProvider
  // https://git.ntrs.com:8443/projects/FOS/repos/fos-shared/browse/src/cards/CardCollection/CardCollection.tsx
  return (
    <StyledGrid container spacing={2}>
      <ReportCards />
    </StyledGrid>
  );
};

export default Dashboard;

const StyledGrid = styled(Grid)`
  && {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;
