import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  List,
  Divider,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  Typography,
} from '@material-ui/core/';
import WrappedMUIListItem, {
  WrappedListItemProps,
} from '../../StyledWrappers/WrappedMUIListItem';
import { useCompanyDispatch } from '../../../Context/CompanyDataContext/CompanyProvider';
import {
  Subsidiary,
  SubsidiaryAccount,
} from '../../../Context/CompanyDataContext/types';
import { CompanyDataActions } from '../../../Context/CompanyDataContext/CompanyActions';
import SubsidiaryAccountListItem from './SubsidiaryAccountListItem';

const SubsidiaryListItem: FC<{ subsidiary: Subsidiary }> = ({
  subsidiary,
}): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useCompanyDispatch();

  const { SUBSIDIARY_NAME, accounts, ID, isChecked, tagColor } = subsidiary;
  const labelId = `subsidiary-checkbox-list-item-${ID}`;

  const toggleSubsidiaryAccountList = useCallback(() => {
    setExpanded((prevVal: boolean): boolean => {
      return !prevVal;
    });
  }, []);

  const getChevronIcon = useCallback((): JSX.Element | null => {
    if (accounts.length === 0) {
      return null;
    }

    return expanded ? (
      <i className='icon--chevron_up' />
    ) : (
      <i className='icon--chevron_down' />
    );
  }, [accounts.length, expanded]);

  return (
    <>
      <StyledListItem key={ID} isSelected={isChecked}>
        <StyledChevronButton
          onClick={toggleSubsidiaryAccountList}
          data-testid={`subsidiary-chevron-${ID}`}
        >
          {getChevronIcon()}
        </StyledChevronButton>

        <ListItemIcon>
          <Checkbox
            data-testid={labelId}
            checked={isChecked}
            disableRipple
            edge='start'
            inputProps={{ 'aria-labelledby': labelId }}
            tabIndex={-1}
            onClick={(): void => {
              if (!expanded && !isChecked) {
                toggleSubsidiaryAccountList();
              }
              dispatch(CompanyDataActions.toggleSubsidiarySelected(ID));
            }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={<Typography variant='h6'>{SUBSIDIARY_NAME}</Typography>}
        />
        <StyledDot
          color={tagColor.borderColor}
          className='icon--media_record'
        />
      </StyledListItem>

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <List component='div'>
          {accounts.map((subsidiaryAccount: SubsidiaryAccount): JSX.Element => {
            return (
              <SubsidiaryAccountListItem
                key={subsidiaryAccount.ID}
                subsidiaryAccount={subsidiaryAccount}
                subsidiaryId={ID}
              />
            );
          })}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default SubsidiaryListItem;

const StyledListItem = styled(WrappedMUIListItem)<WrappedListItemProps>`
  .MuiListItemText-root {
    flex: none;
    margin-top: 0.5rem;
    padding-right: 0.2rem;
  }

  background-color: ${(
    props: WrappedListItemProps & { theme: any },
  ): string => {
    return props.isSelected
      ? props.theme.colors.teal[100]
      : props.theme.colors.white;
  }};

  height: 3rem;
`;

const StyledChevronButton = styled(Button)`
  &.MuiButtonBase-root {
    padding-right: 1.8rem;
    min-width: 3rem;
  }
`;

const StyledDot = styled.i<{ color: string }>`
  color: ${({ color }): string => color};
`;
