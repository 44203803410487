import { useState, MouseEvent } from 'react';

const useSetAnchorEl = (
  handleSet?: () => void | null,
  handleUnset?: () => void,
): [
  HTMLAnchorElement | null,
  (event: MouseEvent<Element, globalThis.MouseEvent>) => void,
  () => void,
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const handleClick = (event: MouseEvent): void => {
    if (handleSet && typeof handleSet === 'function') {
      handleSet();
    }
    setAnchorEl(event.currentTarget as HTMLAnchorElement);
  };

  const handleClose = (): void => {
    if (handleUnset && typeof handleUnset === 'function') {
      handleUnset();
    }
    setAnchorEl(null);
  };

  return [anchorEl, handleClick, handleClose];
};

export default useSetAnchorEl;
