import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Modal, Input, Notification, AsyncSelect } from '@ntet/fos-ui';
import useNotification from '@ntet/fos-ui/dist/Components/Notification/useNotification';
import debounce from 'debounce-promise';
import { usePreferences } from '../../Context/PreferencesProvider';
import { Layout } from '../../DataFetch/layouts';
import ErrorMessage, {
  MIN_NAME_LENGTH,
  ErrorMessageStyle,
} from './ErrorMessage';
import useUserSearch, {
  UserSearchResponse,
} from '../../CustomHooks/useUserSearch';

type Action = {
  action: string;
  option: UserSearchResponse;
};

interface Props {
  handleSubmit: Function;
  expandedReportType: string;
  inputModalOpen: boolean;
  setInputModalOpen: Function;
  setModalType: Function;
  adjustedLayouts: Array<Layout>;
  notificationTitle: Function;
  modalTitle: string;
  subHeader?: string;
  placeholder?: string;
  postSubmitAction?: () => void;
  hasInput: boolean;
  confirmBtnText: string;
  selectedLayout?: Layout;
  isSharing?: boolean;
}

const loadOptions = async (
  refetch: Function,
  data: UserSearchResponse | undefined,
): Promise<any> => {
  // if the data is already present, use it - else, return a promise
  if (data) {
    return data;
  }
  const d = await refetch();
  return d;
};

const InputModal: FC<Props> = ({
  handleSubmit,
  expandedReportType,
  inputModalOpen,
  setInputModalOpen,
  setModalType,
  adjustedLayouts,
  notificationTitle,
  modalTitle,
  subHeader,
  placeholder,
  postSubmitAction,
  hasInput,
  confirmBtnText,
  selectedLayout,
  isSharing,
}): JSX.Element => {
  const [userInput, setUserInput] = useState('');
  const [searchedName, setSearchedName] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const { prefDispatch } = usePreferences();

  const { queueMessage, closeMessage } = useNotification();

  const isFavorited = !!selectedLayout?.isFavorited;
  const existingLayoutId = selectedLayout?.layoutId;

  const showToast = (): any => {
    setTimeout(() => {
      closeMessage('saveSnackBar');
    }, 5000);

    queueMessage({
      title: 'Save Notification',
      key: 'saveSnackBar',
      content: key => {
        return (
          <Notification
            id={key}
            title={notificationTitle(searchedName || userInput)}
            onClose={closeMessage}
            variant='success'
          />
        );
      },
    });
  };

  const submitName = (): void => {
    const trimmedUserInput = userInput.trim();
    if (
      (!hasErrors && trimmedUserInput.length >= MIN_NAME_LENGTH) ||
      !hasInput
    ) {
      setInputModalOpen(false);
      setModalType('');

      // used for any adjustments before submissions.
      // ex: Remove renamed layout for arr || Remove deleted layout from arr.
      postSubmitAction?.();
      showToast();

      handleSubmit(
        expandedReportType,
        trimmedUserInput,
        adjustedLayouts,
        isFavorited,
        existingLayoutId,
      );

      if (!isSharing) {
        prefDispatch({ type: expandedReportType, payload: userInput });
      }

      setUserInput('');
    } else {
      setHasErrors(true);
      // eslint-disable-next-line no-console
      console.log('error in name submission');
    }
  };

  const { data, refetch } = useUserSearch({
    searchText: userInput,
    enabled: false,
  });

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const onInputChange = (newInputValue: string, action: Action): string => {
    // We setErrors to true here because we would not want users to be
    // able to submit if there is not a name selected (from below onChange)
    if (!searchedName) {
      setHasErrors(true);
    }

    if (action.action === 'set-value') {
      return userInput;
    }

    // only want to set userInput when user is typing in the input field
    // if the `menu-close` || `input-blur` a user has been selected
    // from the drop down and we do not want to set the input anymore
    if (action.action === 'input-change') {
      setUserInput(newInputValue);
    }
    return newInputValue;
  };

  const noOptionsMessage = (obj: { inputValue: string }): string => {
    if (obj.inputValue === '') {
      return 'Type for results';
    }
    return 'No results';
  };

  const onChange = (selected: any, action: any): void => {
    if (action.action === 'select-option') {
      setUserInput(selected.userId);
      setSearchedName(selected.displayName);
      setHasErrors(false);
    }
  };

  return (
    <>
      <Modal
        title={modalTitle}
        closeBtnText='Cancel'
        confirmBtnText={confirmBtnText}
        open={inputModalOpen}
        onClose={(): void => {
          setInputModalOpen(false);
          setModalType('');
          setUserInput('');
        }}
        onConfirm={(): void => {
          setSubmitClicked(true);
          submitName();
        }}
      >
        <SubHeader>{subHeader}</SubHeader>
        {hasInput && !isSharing && (
          <>
            <Input
              value={userInput}
              onChange={(e): void => {
                const textInput = e.target.value;
                setUserInput(textInput);
              }}
              style={{ width: '500px' }}
              placeholder={placeholder}
            />
            <ErrorMessage
              userInput={userInput}
              adjustedLayouts={adjustedLayouts}
              expandedReportType={expandedReportType}
              setHasErrors={setHasErrors}
              submitClicked={submitClicked}
            />
          </>
        )}
        {isSharing && (
          <div style={{ width: '500px' }}>
            <AsyncSelect
              onInputChange={onInputChange}
              loadOptions={(): any => debouncedLoadOptions(refetch, data)}
              getOptionValue={(d: any): void => d.userId}
              // prettier-ignore
              getOptionLabel={(d: any): string => `${d.displayName} - ${d.emailAddress}`}
              hideSelectedOptions={false}
              noOptionsMessage={noOptionsMessage}
              menuPortalTarget={document.body}
              onChange={onChange}
              openMenuOnFocus
            />
            {submitClicked && !searchedName && (
              <ErrorMessageStyle>
                Please select a user from the dropdown
              </ErrorMessageStyle>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default InputModal;

const SubHeader = styled.div`
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: 500;
`;
