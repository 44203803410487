import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  ReactNode,
  FC,
  useContext,
} from 'react';

import styled from 'styled-components';

interface ModalState {
  node: HTMLDivElement | undefined;
  isModalShown: boolean;
  setModalVisibility: (showModal: boolean) => void;
}

const ModalContext = createContext<ModalState | undefined>(undefined);

const ModalProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [node, setNode] = useState<HTMLDivElement>();
  const [isModalShown, setIsModalShown] = useState(false);

  const setModalVisibility = (showModal: boolean): void => {
    setIsModalShown(showModal);
  };

  useEffect(() => {
    const modalNode = modalRef.current;
    if (modalNode) {
      setNode(modalNode);
    }
  }, []);

  return (
    <StyledContainer>
      <ModalContext.Provider value={{ node, isModalShown, setModalVisibility }}>
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </StyledContainer>
  );
};

export const useModal = (): ModalState => {
  const modalState = useContext(ModalContext);

  if (modalState === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return modalState;
};

export default ModalProvider;

const StyledContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
`;
