import { theme } from '@ntet/fos-ui';

export interface NTTagColor {
  borderColor: string;
  backgroundColor: string;
}

const {
  colors: { teal, purple, lightGreen, yellow, darkBlue, orange, aqua, red },
} = theme;

const TagColors: Array<NTTagColor> = [
  {
    backgroundColor: teal[100],
    borderColor: teal[500],
  },
  {
    backgroundColor: purple[100],
    borderColor: purple[500],
  },
  {
    backgroundColor: lightGreen[100],
    borderColor: lightGreen[500],
  },
  {
    backgroundColor: yellow[100],
    borderColor: yellow[500],
  },
  {
    backgroundColor: darkBlue[100],
    borderColor: darkBlue[500],
  },
  {
    backgroundColor: orange[100],
    borderColor: orange[500],
  },
  {
    backgroundColor: aqua[100],
    borderColor: aqua[500],
  },
  {
    backgroundColor: red[100],
    borderColor: red[500],
  },
];

export const DonutChartColors: Array<string> = [
  '#088B94',
  '#104866',
  '#2E8FC2',
  '#86D2F9',
  '#1F8A6F',
];

export const ChangeAnalysisColors: Array<string> = [
  '#1F8A6F',
  '#00A0AA',
  '#088B94',
  '#86D2F9',
  '#2E8FC2',
  '#104866',
];

export const EarnedIncomeColors: Array<string> = [
  '#1F8A6F',
  '#00A0AA',
  '#088B94',
  '#86D2F9',
  '#2E8FC2',
  '#104866',
];

export const getColor = (index: number): NTTagColor => {
  return TagColors[index % TagColors.length];
};

export const getDonutChartColor = (index: number): string => {
  return DonutChartColors[index % DonutChartColors.length];
};

export const getChangeAnalysisColor = (index: number): string => {
  return ChangeAnalysisColors[index % ChangeAnalysisColors.length];
};
