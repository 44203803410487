import { Dayjs } from 'dayjs';
import { ChartDataSummation } from '../Components/Visualizations/ChartData';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import {
  AssetSuperCategory,
  getVisualizationDataFetchProps,
} from '../Helpers/data-uri-helper';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import isNotNull from '../Helpers/type-guards';
import { tagMappings } from '../Helpers/label-helper';
import { PublishStatus } from '../Context/PeriodStatusProvider';

export interface AssetAllocationJSON {
  assetsupercategory: AssetSuperCategory;
  categorybookadjcarryingvalue: string;
  categoryamortizedcostbase: string;
  categoryunrealizedgainloss: string;
  categorymarketvaluetotal: string;
  bookadjcarryingvaluetotal: string;
  amortizedcostbasetotal: string;
  unrealizedgainlosstotal: string;
  marketvaluetotal: string;
  percentageweight: string;
  categorymarketvalue: string;
}

export interface AssetAllocationSlice {
  assetSuperCategory: AssetSuperCategory | string;
  marketPercent: number;
}

export interface PortfolioHoldingsResponseData {
  allocationSlices: Array<AssetAllocationSlice>;
  summatedData: Array<ChartDataSummation>;
}

const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedSelections: Subsidiary[],
): Promise<PortfolioHoldingsResponseData | null> => {
  const { URI, accessToken } = await getVisualizationDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedSelections,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: AssetAllocationJSON[]) => {
        if (data.length === 0) {
          return null;
        }

        const summatedData = [
          {
            label: tagMappings.get('bookadjcarryingvaluetotal') ?? '',
            value: Number(data[0].bookadjcarryingvaluetotal),
          },
          {
            label: tagMappings.get('amortizedcostbasetotal') ?? '',
            value: Number(data[0].amortizedcostbasetotal),
          },
          {
            label: tagMappings.get('marketvaluetotal') ?? '',
            value: Number(data[0].marketvaluetotal),
          },
          {
            label: tagMappings.get('unrealizedgainlosstotal') ?? '',
            value: Number(data[0].unrealizedgainlosstotal),
          },
        ];

        const allocationSlices = data
          .map((allocationJSON: AssetAllocationJSON) => {
            const marketPercent = Number(allocationJSON.percentageweight);
            // TODO this will potentially need to be looked at in the future to roll up or include
            // small percentages in a graceful manner. It creates a lot of chart noise right now for demo purposes.
            if (marketPercent < 1) {
              return null;
            }

            return {
              assetSuperCategory: allocationJSON.assetsupercategory,
              marketPercent,
            };
          })
          .filter(isNotNull);

        return {
          allocationSlices,
          summatedData,
        };
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};

export { fetchChartData };
