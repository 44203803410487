import { Dayjs } from 'dayjs';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import { getVisualizationDataFetchProps } from '../Helpers/data-uri-helper';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import { ChartDataSummation } from '../Components/Visualizations/ChartData';
import isNotNull from '../Helpers/type-guards';
import { tagMappings } from '../Helpers/label-helper';
import { PublishStatus } from '../Context/PeriodStatusProvider';

export interface EarnedIncomeDataPoint {
  domain: string;
  value: number;
}

export interface EarnedIncomeSlice {
  name: string;
  data: Array<EarnedIncomeDataPoint>;
}

export interface EarnedIncomeMonthlyData {
  accretionofdiscountbaseccy: string;
  accruedincomepurchasedbaseccy: string;
  accruedincomesoldbaseccy: string;
  amortizationofpremiumbaseccy: string;
  earnedincomebaseccy: string;
  netincomeaccrualbaseccy: string;
  otherexpensesbaseccy: string;
  incomereceivedbaseccy: string;
  timeunit: string;
}

export interface EarnedIncomeQuarterlyData {
  q1_total: string;
  q2_total?: string;
  q3_total?: string;
  q4_total?: string;
}

export interface EarnedIncomeJSONResponse {
  monthly: Array<EarnedIncomeMonthlyData>;
  quarterly: EarnedIncomeQuarterlyData;
  dataCategories: Array<ChartDataSummation>;
}

export const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<EarnedIncomeJSONResponse | null> => {
  const { URI, accessToken } = await getVisualizationDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedAccountSelections,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: EarnedIncomeJSONResponse) => {
        if (data) {
          const { monthly, quarterly } = data;

          const dataCategories: Array<ChartDataSummation> = Object.keys(
            monthly[0],
          )
            .map((key: string) => {
              const userFriendlyString = tagMappings.get(key);
              if (userFriendlyString) return { label: userFriendlyString };
              return null;
            })
            .filter(isNotNull);

          return {
            monthly,
            quarterly,
            dataCategories,
          };
        }
        return null;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};
