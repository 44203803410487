import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledBodyText } from './StyledWrappers/WrappedTypography';

const LoadingError: FC = (): JSX.Element => (
  <StyledLoaderContainer>
    <StyledBodyText>
      Data is currently unavailable for your current selections
    </StyledBodyText>
  </StyledLoaderContainer>
);

export default LoadingError;

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 5rem);
  width: 100%;
`;
