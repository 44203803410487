import React, { FC } from 'react';
import styled from 'styled-components';
import { IconButton } from '@ntet/fos-ui';

// instances of this IconButton component were previously `@fos/fos-shared/SecondaryButton
// and hover colors have been adjusted

const BackButton: FC<{
  onClick?: () => void;
}> = ({ onClick }): JSX.Element => {
  return (
    <StyledBackButton
      data-testid='card-back-button'
      icon='arrow_back'
      onClick={(): void => onClick && onClick()}
      disabled={false}
    />
  );
};

const StyledBackButton = styled(IconButton)`
  &.MuiButtonBase-root {
    margin-left: 1rem;
    min-width: auto;
    width: 3.2rem;
    padding: 0;
  }
  i {
    font-size: 1.8rem;
    margin: 0 0 0 0.5rem;
  }
`;
export default BackButton;
