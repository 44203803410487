import React, { FC } from 'react';
import { Loader } from '@ntet/fos-ui';

import styled from 'styled-components';

const CardLoader: FC = (): JSX.Element => (
  <StyledLoaderContainer>
    <Loader />
  </StyledLoaderContainer>
);

export default CardLoader;

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 5rem);
  width: 100%;
  position: relative;
`;
