import React, { FC, useState } from 'react';
import {
  format as d3format,
  max as d3max,
  stack,
  scaleLinear,
  stackOrderNone,
  stackOffsetNone,
} from 'd3';

import { useQuery } from 'react-query';
import { usePeriodStatus } from 'Context/PeriodStatusProvider';
import { CardHeader } from '@ntet/fos-ui';
import styled from 'styled-components';
import { TransactionType, Aggregate } from 'Helpers/data-uri-helper';
import LoadingError from '../../LoadingError';
import ChangeAnalysisChart from '../../Visualizations/ChangeAnalysisChart';
import { ChangeAnalysisColors } from '../../../Helpers/tag-color-helper';
import ColorTags from '../../Visualizations/ColorTags';
import ChartData from '../../Visualizations/ChartData';
import { usePeriodState } from '../../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../../Context/View/ViewProvider';
import { useCompanyState } from '../../../Context/CompanyDataContext/CompanyProvider';
import { StyledBodyText } from '../../StyledWrappers/WrappedTypography';
import CardLoader from '../../CardLoader';
import { fetchChartData } from '../../../DataFetch/change-analysis';
import { VisualizationMode } from '../../../Context/View/types';
import ChangeAnalysisSmallGridView from './ChangeAnalysisSmallGridView';
import BackButton from '../../Buttons/BackButton';
import { useUser } from '../../../Context/UserProvider';

const ChangeAnalysisCard: FC<{}> = (): JSX.Element => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();
  const { clientID, clientIDOverride } = useUser();
  const { savedSelections } = useCompanyState();
  const { accountingBasis } = useReportState();
  const { selectedStatus } = usePeriodStatus();

  const [visualizationMode, setVisualizationMode] = useState<VisualizationMode>(
    VisualizationMode.Chart,
  );
  const [selectedChartCategory, setSelectedChartCategory] = useState<
    string | null
  >(null);

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType | null>(null);

  const [selectedAnalysisType, setSelectedAnalysisType] = useState<Aggregate>(
    Aggregate.AggregateHoldings,
  );

  const onDataCategoryClicked = (category: string): void => {
    // TODO to handle 'REALIZED GAIN/LOSS' for now.
    let formattedCat = category.replace('/', ' ');

    if (category === 'CHG IN DUE AND ACCRUED') {
      formattedCat = 'Net Change in due and accrued';
    }
    setVisualizationMode(VisualizationMode.Grid);
    setSelectedChartCategory(formattedCat);
    setSelectedTransactionType(null);
    setSelectedAnalysisType(Aggregate.AggregateHoldings);
  };

  const onTransactionTypeClicked = (type: string): void => {
    setVisualizationMode(VisualizationMode.Grid);
    setSelectedTransactionType(type as TransactionType);
    setSelectedChartCategory(null);
    setSelectedAnalysisType(Aggregate.AggregateTransactions);
  };

  const onBackButtonClicked = (): void => {
    setSelectedTransactionType(null);
    setSelectedChartCategory(null);
    setVisualizationMode(VisualizationMode.Chart);
  };

  const getFormattedCategoryLabel = (
    category: TransactionType | string | null,
  ): string => {
    if (!category) {
      return '';
    }
    const lowerCaseLabel = category.toLocaleLowerCase();
    return `- ${lowerCaseLabel.charAt(0).toUpperCase()}${lowerCaseLabel.slice(
      1,
    )}`;
  };

  const { status, data } = useQuery(
    [
      'change-analysis-data',
      accountingBasis,
      'Change Analysis',
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
    ],
    fetchChartData,
    { refetchOnWindowFocus: false },
  );

  if (status === 'loading') {
    return (
      <>
        <CardHeader small>
          <StyledHeaderContainer>
            <StyledBodyText>Change Analysis</StyledBodyText>
            {visualizationMode === VisualizationMode.Grid && (
              <BackButton onClick={onBackButtonClicked} />
            )}
          </StyledHeaderContainer>
        </CardHeader>
        <CardLoader />
      </>
    );
  }

  if (status === 'error' || !data) {
    return (
      <>
        <CardHeader small>
          <StyledHeaderContainer>
            <StyledBodyText>Change Analysis</StyledBodyText>
            {visualizationMode === VisualizationMode.Grid && (
              <BackButton onClick={onBackButtonClicked} />
            )}
          </StyledHeaderContainer>
        </CardHeader>
        <LoadingError />
      </>
    );
  }

  const {
    changeInIncome,
    chartYAxisLabel,
    changeInHoldings,
    transactionTypes,
    attributeTotals,
    analysisTotals,
  } = data;
  const format = d3format('$,');

  const incomeColumnLabel = `Change In Income, Net Total: ${format(
    analysisTotals.income,
  )}`;

  const holdingsColumnLabel = `Change In Holdings, Net Total: ${format(
    analysisTotals.holdings,
  )}`;

  // get labels from changeInIncome
  // there is no reason why we are using changeInIncome
  // over changeInHoldings; they should both provide identical labels
  const chartLabels = changeInIncome.map(c => c.domain);

  const incomeKeys = Object.keys(changeInIncome[0]).filter(
    key => key !== 'domain',
  );
  const holdingsKeys = Object.keys(changeInHoldings[0]).filter(
    key => key !== 'domain',
  );

  const incomeStack = stack()
    .keys(incomeKeys)
    .order(stackOrderNone)
    .offset(stackOffsetNone);

  const holdingsStack = stack()
    .keys(holdingsKeys)
    .order(stackOrderNone)
    .offset(stackOffsetNone);

  const incomeLayers = incomeStack(changeInIncome as any) || [];
  const holdingsLayers = holdingsStack(changeInHoldings as any) || [];

  const xIncome = scaleLinear()
    .domain([
      0,
      d3max(incomeLayers[incomeLayers.length - 1], d => d[0] + d[1]) || 0,
    ])
    .nice();

  const xHoldings = scaleLinear()
    .domain([
      0,
      d3max(holdingsLayers[holdingsLayers.length - 1], d => d[0] + d[1]) || 0,
    ])
    .nice();

  const incomeData1 = incomeLayers.map(l => ({ data: l[0], key: l.key }));
  const incomeData2 = incomeLayers.map(l => ({ data: l[1], key: l.key }));

  const holdingsData1 = holdingsLayers.map(l => ({ data: l[0], key: l.key }));
  const holdingsData2 = holdingsLayers.map(l => ({ data: l[1], key: l.key }));

  return (
    <>
      <CardHeader small>
        <StyledHeaderContainer>
          <StyledBodyText>
            {`Change Analysis ${getFormattedCategoryLabel(
              selectedChartCategory ?? selectedTransactionType,
            )}`}
          </StyledBodyText>
          {visualizationMode === VisualizationMode.Grid && (
            <BackButton onClick={onBackButtonClicked} />
          )}
        </StyledHeaderContainer>
      </CardHeader>
      {visualizationMode === VisualizationMode.Grid ? (
        <ChangeAnalysisSmallGridView
          selectedChartValue={selectedChartCategory ?? selectedTransactionType}
          analysisType={selectedAnalysisType}
        />
      ) : (
        <StyledCardContainer>
          <StyledChangeAnalysisTable>
            <colgroup>
              <StyledLabelColumn />
              <StyledChartColumn />
              <StyledChartColumn />
            </colgroup>
            <thead>
              <tr>
                <th>{chartYAxisLabel}</th>
                <th>{incomeColumnLabel}</th>
                <th>{holdingsColumnLabel}</th>
              </tr>
            </thead>
            <tbody>
              <tr className='chart-row'>
                <td>{chartLabels[0]}</td>
                <td>
                  <ChangeAnalysisChart data={incomeData1} xScale={xIncome} />
                </td>
                <td>
                  <ChangeAnalysisChart
                    data={holdingsData1}
                    xScale={xHoldings}
                  />
                </td>
              </tr>
              <tr className='chart-row'>
                <td>{chartLabels[1]}</td>
                <td>
                  <ChangeAnalysisChart data={incomeData2} xScale={xIncome} />
                </td>
                <td>
                  <ChangeAnalysisChart
                    data={holdingsData2}
                    xScale={xHoldings}
                  />
                </td>
              </tr>
              <tr className='bubble-legend-row'>
                <td />
                <td rowSpan={2}>
                  <ChartData
                    dataTotals={attributeTotals.slice(0, 6)}
                    colorLabels={ChangeAnalysisColors}
                    onValueClicked={onDataCategoryClicked}
                    useLabelForSmallGrid
                  />
                </td>
                <td>
                  <ColorTags
                    tagColors={ChangeAnalysisColors}
                    tagLabels={transactionTypes}
                    onTagClicked={onTransactionTypeClicked}
                  />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <ChartData
                    colorLabels={ChangeAnalysisColors}
                    dataTotals={attributeTotals.slice(
                      6,
                      attributeTotals.length,
                    )}
                    onValueClicked={onDataCategoryClicked}
                    useLabelForSmallGrid
                  />
                </td>
              </tr>
            </tbody>
          </StyledChangeAnalysisTable>
        </StyledCardContainer>
      )}
    </>
  );
};

export default ChangeAnalysisCard;

// 5.2rem below is calculated from 3.5rem (height of header, specified below)
// + top padding (0.8) + bottom padding (0.8) + border (0.1)
const StyledCardContainer = styled.div`
  height: calc(100% - 5.2rem);
  width: 100%;
  padding: 2rem 3rem;
`;

const StyledChangeAnalysisTable = styled.table`
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  td,
  th {
    font-weight: 300;
    text-align: left;
  }
  th:nth-child(1) {
    text-align: right;
    padding-right: 2rem;
  }
  tr {
    &.chart-row {
      height: 9rem;
      td {
        padding: 2rem 1rem 2rem 0;
      }
      td:nth-child(1) {
        text-align: right;
        padding-right: 2rem;
      }
    }
    &.bubble-legend-row {
      vertical-align: top;
    }
  }
`;

const StyledLabelColumn = styled.col`
  width: 10%;
`;

const StyledChartColumn = styled.col`
  width: 45%;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3.5rem;
`;
