import React, { FC } from 'react';
import styled from 'styled-components';
import { useReportState } from 'Context/View/ViewProvider';
import CloseButton from '../Buttons/CloseButton';
import {
  StyledBoldText,
  StyledBodyText,
} from '../StyledWrappers/WrappedTypography';
import SelectedPeriodText from '../SelectedPeriodText';
import { allAccountingOptions } from '../Selectors/AccountingSelector';
import SelectedAccountsToggle from '../Navigation/SelectedAccountsToggle';
import {
  ReportViewSelectorValue,
  ReportView,
  ExpandedReportView,
} from '../../Context/View/types';
import PositionLotToggle from '../Buttons/PositionLotToggle';
import ManageButton from '../Buttons/ManageButton';
import { Layout } from '../../DataFetch/layouts';

interface Props {
  toggleShowAccountSelections: () => void;
  selectedAccountsPaneShown: boolean;
  handleSubmit: (
    view: string,
    layoutName: string,
    layoutArr: Array<Layout>,
    isFavorited: boolean,
    existingLayoutId?: string,
  ) => void;
  handleReset: (view: string) => void;
  isResetting: boolean;
  setIsSaving: Function;
}

const GridHeader: FC<Props> = ({
  toggleShowAccountSelections,
  selectedAccountsPaneShown,
  handleSubmit,
  handleReset,
  isResetting,
  setIsSaving,
}): JSX.Element => {
  const {
    expandGridOptions: { expandedReportType },
    accountingBasis,
  } = useReportState();

  const accountingBasisOption = allAccountingOptions.find(
    val => val.value === accountingBasis,
  );

  const getReportViewText = (view: ExpandedReportView | null): string => {
    if (!view) {
      return ReportView.Holdings;
    }

    if (view === 'Change Analysis') {
      return 'Change Analysis';
    }

    return ReportView[view];
  };

  return (
    <StyledHeaderContainer>
      <StyledLeftContainer>
        <GridHeaderBoldText>
          {getReportViewText(expandedReportType)}
        </GridHeaderBoldText>
        {expandedReportType !== 'Change Analysis' && (
          <StyledBodyText>
            {accountingBasisOption ? accountingBasisOption.label : ''}
          </StyledBodyText>
        )}
        <SelectedAccountsToggle
          toggleShowAccountSelections={toggleShowAccountSelections}
          selectedAccountsPaneShown={selectedAccountsPaneShown}
        />
        <ManageButton
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          expandedReportType={expandedReportType}
          isResetting={isResetting}
          setIsSaving={setIsSaving}
        />
      </StyledLeftContainer>
      <StyledRightContainer>
        <SelectedPeriodText />
        {expandedReportType === ReportViewSelectorValue.Holdings && (
          <PositionLotToggle />
        )}
        <CloseButton />
      </StyledRightContainer>
    </StyledHeaderContainer>
  );
};

export default GridHeader;

const GridHeaderBoldText = styled(StyledBoldText)`
  padding-left: 0.7rem;
  padding-bottom: 0.1rem;
`;

const StyledHeaderContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.white};
  display: flex;
  height: 6rem;
  width: 100%;
`;

const StyledLeftContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 63rem;
`;

const StyledRightContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  max-width: 35rem;
  width: auto;
`;
