import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { usePreferences } from '../../Context/PreferencesProvider';
import { Layout } from '../../DataFetch/layouts';
import { getLayoutViewType } from '../../Helpers/preferences-helper';

export const MIN_NAME_LENGTH = 1;
export const MAX_NAME_LENGTH = 30;

interface Props {
  userInput: string;
  adjustedLayouts: Array<Layout>;
  expandedReportType: string;
  setHasErrors: Function;
  submitClicked: boolean;
}

const ErrorMessage: FC<Props> = ({
  userInput,
  adjustedLayouts,
  expandedReportType,
  setHasErrors,
  submitClicked,
}): JSX.Element | null => {
  const [hasCharLengthErr, setHasCharLengthErr] = useState(true);
  const [hasInvalidCharErr, setHasInvalidCharErr] = useState(false);
  const [hasUniquenErr, setHasUniquenErr] = useState(false);

  const { prefState } = usePreferences();
  const validRegex = /^[A-Za-z0-9 ]+$/;

  const trimmedInput = userInput.trim();

  const isCurrentName = prefState[expandedReportType] === trimmedInput;

  // lowercase to compare for uniqueness
  const namesForUniqValidation = adjustedLayouts
    ?.filter(
      (layout: Layout) => getLayoutViewType(layout) === expandedReportType,
    )
    ?.map((layout: Layout) => layout.layoutLabel.toLowerCase());

  const setValidCharCountErr = useCallback(
    (): void =>
      trimmedInput.length >= MIN_NAME_LENGTH &&
      trimmedInput.length <= MAX_NAME_LENGTH
        ? setHasCharLengthErr(false)
        : setHasCharLengthErr(true),
    [trimmedInput],
  );

  const setValidCharErr = useCallback(
    (): void =>
      trimmedInput && trimmedInput.match(validRegex)
        ? setHasInvalidCharErr(false)
        : setHasInvalidCharErr(true),
    [trimmedInput, validRegex],
  );

  const setUniqueNameErr = useCallback(
    (): void =>
      trimmedInput &&
      namesForUniqValidation.includes(trimmedInput.toLowerCase())
        ? setHasUniquenErr(true)
        : setHasUniquenErr(false),
    [trimmedInput, namesForUniqValidation],
  );

  const hasErrors =
    (hasInvalidCharErr ||
      hasCharLengthErr ||
      hasUniquenErr ||
      prefState[expandedReportType] === trimmedInput) &&
    trimmedInput.length >= MIN_NAME_LENGTH;

  useEffect(() => {
    if (trimmedInput) {
      setValidCharCountErr();
      setValidCharErr();
      setUniqueNameErr();
    }
    if (hasErrors) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  }, [
    trimmedInput,
    setValidCharCountErr,
    setValidCharErr,
    setUniqueNameErr,
    hasErrors,
    setHasErrors,
  ]);

  // prettier-ignore
  return hasErrors ||
    (submitClicked && trimmedInput.length < MIN_NAME_LENGTH) ? (
      <ErrorMessageStyle>
        <span>One or more requirements have not been met:</span>
        <ul>
          {hasInvalidCharErr && (
            <li>
              This analysis set name contains an invalid character. Please rename
              the analysis set to only contain: a-z, A-Z, 0-9, spaces
            </li>
          )}
          {trimmedInput.length < MIN_NAME_LENGTH && (
            <li>
              This analysis set name must contain at least 1 non space character
            </li>
          )}
          {hasCharLengthErr && (
            <li>
              This analysis set name must be between 1 and 30 characters in
              length.
            </li>
          )}
          {hasUniquenErr && !isCurrentName && (
            <li>
              This analysis set name is already in use. Please select a new name.
            </li>
          )}
          {isCurrentName && <li>Cannot rename analysis set to current name.</li>}
        </ul>
      </ErrorMessageStyle>
  ) : null;
};
export default ErrorMessage;

export const ErrorMessageStyle = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`;
