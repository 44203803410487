import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  color: string;
  label: string;
};

// this was previously in @fos/fos-shared, consider moving to a shared
// component library
const ColorTag: FC<Props> = ({ color, label }): JSX.Element => (
  <StyledColorTag>
    <StyledColorIcon color={color} />
    {label}
  </StyledColorTag>
);

export default ColorTag;

const StyledColorTag = styled.div`
  background-color: white;
  border-radius: 1.3rem;
  padding: 0.3rem 1.2rem;
  margin: 0.2rem;
  border: solid 1px #eaeaea;
  display: flex;
  flex-direction: row;
  align-items: center;
  &&:hover {
    filter: brightness(0.9) saturate(3);
    cursor: pointer;
  }
`;

// eslint-disable-next-line no-unused-vars
const StyledColorIcon = styled(({ color, ...props }) => <div {...props} />)`
  && {
      width: 0.8rem;
      height: 0.8rem;
      margin: 0 0.6rem 0 0.2rem;
      border-radius: 50%;
      background-color: ${({ color }): string => color};
    }
  }
`;
