import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  FC,
  useCallback,
} from 'react';
import { useQuery } from 'react-query';
import authService, {
  getClientIdFromToken,
  getActualUserTypeFromToken,
  getPermissionFromToken,
} from '../Helpers/okta-helper';
import CardLoader from '../Components/CardLoader';
import LoadingError from '../Components/LoadingError';

const fetchUserStatus = async (): Promise<{
  clientID: string;
  isAdminUser: boolean;
  sub: string;
  userDisplayName: string;
  permissionLevel: number;
}> => {
  const accessToken = await authService.getAccessToken();
  const user = await authService.getUser();
  const clientID = getClientIdFromToken(accessToken);
  const isAdminUser = getActualUserTypeFromToken(accessToken) === 'NT';
  const permissionLevel = getPermissionFromToken(accessToken);

  return {
    clientID,
    isAdminUser,
    sub: user.sub,
    userDisplayName: user.name,
    permissionLevel,
  };
};

const UserContext = createContext<UserAdminData | undefined>(undefined);

interface UserAdminData {
  isAdminUser: boolean;
  clientIDOverride?: string;
  clientID: string;
  sub: string;
  userDisplayName: string;
  onSubmitClientIDOverride: (value: string) => void;
  permissionLevel: number;
}

const UserProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const [clientIDOverride, setClientIDOverride] = useState<string | undefined>(
    undefined,
  );

  const onSubmitClientIDOverride = useCallback((value: string): void => {
    setClientIDOverride(value.length ? value : undefined);
  }, []);

  const { status, data } = useQuery(['user-status'], fetchUserStatus, {
    refetchOnWindowFocus: false,
  });

  const { localStorage } = window;

  if (!localStorage.getItem('sessionKey')) {
    // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
    const r = Math.random().toString(36).substring(7);
    localStorage.setItem('sessionKey', r);
  }

  if (status === 'loading') {
    return <CardLoader />;
  }

  if (status === 'error' || !data) {
    return <LoadingError />;
  }

  const { isAdminUser, clientID, sub, userDisplayName, permissionLevel } = data;

  return (
    <UserContext.Provider
      value={{
        isAdminUser,
        clientIDOverride,
        clientID,
        sub,
        userDisplayName,
        onSubmitClientIDOverride,
        permissionLevel,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useUser = (): UserAdminData => {
  const userData = useContext(UserContext);

  if (userData === undefined) {
    throw new Error(
      'useUserData must be used within a useUserData Context Provider',
    );
  }
  return userData;
};
