import React, { FC } from 'react';
import { useUser } from 'Context/UserProvider';
import { usePreferences } from '../../Context/PreferencesProvider';
import InputModal from './InputModal';
import { selectedLayoutByView } from '../../Helpers/preferences-helper';

interface Props {
  expandedReportType: string;
  inputModalOpen: boolean;
  setInputModalOpen: Function;
  setModalType: Function;
  setIsSaving: Function;
}

const RenameModal: FC<Props> = ({
  expandedReportType,
  inputModalOpen,
  setInputModalOpen,
  setModalType,
  setIsSaving,
}): JSX.Element => {
  const { layouts, prefState } = usePreferences();
  const { clientID, clientIDOverride } = useUser();
  const selectedLayout = selectedLayoutByView(
    layouts,
    expandedReportType,
    prefState,
  );

  const { updatePreferences } = usePreferences();

  const handleRename = (view: string, layoutName: string): void => {
    // Something has gone very wrong if we're lacking an id for the layout we want to delete.
    const index = layouts.indexOf(selectedLayout);
    layouts[index].layoutLabel = layoutName;
    setIsSaving(true);

    updatePreferences({
      clientId: clientIDOverride ?? clientID,
      layouts,
      settledCallback: () => {
        setIsSaving(false);
      },
    });
  };

  const notificationTitle = (userInput: string): string => {
    return `Renamed ${prefState[expandedReportType]} to ${userInput}`;
  };

  return (
    <InputModal
      handleSubmit={handleRename}
      expandedReportType={expandedReportType}
      inputModalOpen={inputModalOpen}
      setInputModalOpen={setInputModalOpen}
      setModalType={setModalType}
      adjustedLayouts={layouts}
      notificationTitle={notificationTitle}
      modalTitle={`Rename ${prefState[expandedReportType]}`}
      subHeader='New Name'
      placeholder={prefState[expandedReportType]}
      hasInput
      confirmBtnText='Save'
      selectedLayout={selectedLayout}
    />
  );
};

export default RenameModal;
