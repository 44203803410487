import { Dayjs } from 'dayjs';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import { getTrialBalanceCardFetchProps } from '../Helpers/data-uri-helper';
import {
  AccountingBasisValue,
  ExpandedReportView,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import { getFormattedChangeAnalysisLabels } from '../Helpers/label-helper';
import { PublishStatus } from '../Context/PeriodStatusProvider';

export interface TrialBalanceData {
  description: string;
  category?: string;
  previousPeriodValue: string;
  netChange: string;
  currentPeriodValue: string;
}

export interface TrailBalanceWithLabels {
  prevLabel: string;
  currLabel: string;
  data: TrialBalanceData[];
}

export const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedLegalEntitiesSelection: Subsidiary[],
): Promise<TrailBalanceWithLabels | undefined> => {
  const { URI, accessToken } = await getTrialBalanceCardFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedLegalEntitiesSelection,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: TrialBalanceData[]) => {
        const chartLabels = getFormattedChangeAnalysisLabels(
          selectedYear,
          selectedMonth,
          selectedQuarter,
          isYTD,
        );
        return {
          data,
          prevLabel: chartLabels.prevXLabel,
          currLabel: chartLabels.currXLabel,
        };
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`error in fetchTrialBalanceData. Error: ${error}`);
    return undefined;
  }
};
