import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Typography,
  Menu,
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core/';

import useSetAnchorEl from '../../CustomHooks/useSetAnchorEl';
import {
  useCompanyContext,
  fetchCompanyData,
} from '../../Context/CompanyDataContext/CompanyProvider';
import { Subsidiary } from '../../Context/CompanyDataContext/types';
import { CompanyDataActions } from '../../Context/CompanyDataContext/CompanyActions';
import SubsidiaryListItem from './ListItems/SubsidiaryListItem';
import ConfirmationArea from '../Buttons/ConfirmationArea';
import { StyledBodyText } from '../StyledWrappers/WrappedTypography';
import { useUser } from '../../Context/UserProvider';
import { usePeriodStatus } from '../../Context/PeriodStatusProvider';
import { usePeriodContext } from '../../Context/PeriodSelector/PeriodProvider';

const CompanyDropdown: FC<{}> = (): JSX.Element => {
  const [anchorEl, handleClick, handleClose] = useSetAnchorEl();
  const [companyState, dispatch] = useCompanyContext();
  const { clientID, clientIDOverride } = useUser();
  const { selectedStatus } = usePeriodStatus();
  const [periodSelectorState] = usePeriodContext();

  // TODO: This useEffect is for demo'ing purposes. The period Selector/accounting basis dropdown
  // should dictate what accounts are available for selection. This relates to the commented out
  // useEffect in useConnectedState as well
  useEffect(() => {
    const fetchInitialData = async (): Promise<void> =>
      fetchCompanyData(
        periodSelectorState.savedSelections.selectedYear,
        periodSelectorState.savedSelections.selectedMonth,
        periodSelectorState.savedSelections.selectedQuarter,
        clientIDOverride ?? clientID,
        periodSelectorState.savedSelections.isYearToDate,
        selectedStatus,
        dispatch,
      );
    fetchInitialData();
  }, [
    clientID,
    clientIDOverride,
    dispatch,
    selectedStatus,
    periodSelectorState.savedSelections.selectedYear,
    periodSelectorState.savedSelections.selectedMonth,
    periodSelectorState.savedSelections.selectedQuarter,
    periodSelectorState.savedSelections.isYearToDate,
  ]);

  if (companyState.isLoading) {
    return (
      <StyledSpinnerContainer>
        <StyledSpinner size={30} />
      </StyledSpinnerContainer>
    );
  }

  const onCancel = (): void => {
    handleClose();
    dispatch(CompanyDataActions.cancelSelections());
  };

  const onApply = (): void => {
    dispatch(CompanyDataActions.saveSelections());
    handleClose();
  };

  return (
    <StyledDropdownContainer>
      <StyledCompanyToggle onClick={handleClick}>
        <Typography variant='h6'>{companyState.companyName}</Typography>
      </StyledCompanyToggle>
      <ClickAwayListener
        mouseEvent={false}
        onClickAway={(): void => {
          handleClose();
          dispatch(CompanyDataActions.cancelSelections());
        }}
      >
        <StyledMenu
          data-testid='company-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
        >
          {companyState.currentSelections.length !== 0 ? (
            <div>
              {companyState.currentSelections.map(
                (subsidiary: Subsidiary): JSX.Element => {
                  return (
                    <SubsidiaryListItem
                      key={subsidiary.ID}
                      subsidiary={subsidiary}
                    />
                  );
                },
              )}
              <ConfirmationArea onCancel={onCancel} onApply={onApply} />
            </div>
          ) : (
            <StyledNoAccountsContainer>
              <StyledBodyText>
                No accounts available for the period and/or accounting basis
                selections
              </StyledBodyText>
            </StyledNoAccountsContainer>
          )}
        </StyledMenu>
      </ClickAwayListener>
    </StyledDropdownContainer>
  );
};

export default CompanyDropdown;

const StyledDropdownContainer = styled.div`
  width: 18.8rem;
`;

const StyledCompanyToggle = styled(Button)`
  &.MuiButtonBase-root {
    border-bottom: 2px solid ${({ theme }): string => theme.colors.black};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 4.5rem;
    margin-left: 2.8rem;
    margin-right: 3rem;
    width: 13rem;
  }
`;

const StyledMenu = styled(Menu)`
  padding: 0;
  width: 100%;

  .MuiList-root {
    min-width: 40rem;
  }

  .MuiList-padding {
    padding: 0;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }): string => theme.colors.NTGreen[600]};
  }

  .MuiListItemIcon-root {
    min-width: 2.5rem;
  }
`;

const StyledSpinnerContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 4.5rem;
  margin-left: 2.8rem;
  margin-right: 3rem;
  width: 13rem;
`;

const StyledSpinner = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    height: 3rem;
    width 3rem;
  }
  
  &.MuiCircularProgress-colorPrimary {
  
    color: ${({ theme }): string => theme.colors.NTGreen[600]};
  }
`;

const StyledNoAccountsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 10rem;
`;
