import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// this Card component should be in @ntet/fos-ui at some point
interface CardProps {
  totalCount: number;
}

const getSizing = (
  totalCount: number,
): { xs: 12; md: 6 | 12; lg: 4 | 6 | 12 } => {
  const xs: 12 = 12;
  let md: 6 | 12 = 6;
  let lg: 4 | 6 | 12 = 6;

  switch (totalCount) {
    case 1:
      md = 12;
      lg = 12;
      break;
    case 2:
    case 3:
    case 4:
      lg = 6;
      break;
    case 5:
    case 6:
      lg = 4;
      break;
    default:
      lg = 4;
      break;
  }

  return { xs, md, lg };
};

const Card: React.FC<CardProps> = forwardRef<HTMLDivElement, CardProps>(
  ({ totalCount, children, ...rest }) => {
    const { xs, md, lg } = getSizing(totalCount);

    return (
      <StyledGrid item xs={xs} md={md} lg={lg} {...rest}>
        <StyledContainer>{children}</StyledContainer>
      </StyledGrid>
    );
  },
);

export default Card;

const StyledContainer = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  border-top: 2px solid ${({ theme }): string => theme.colors.aqua[700]};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-width: 34.8rem;
  min-height: 30.4rem;
  width: 100%;
  height: 100%;
`;

export const StyledGrid = styled(Grid)`
  background-color: ${({ theme }): string => theme.colors.NTDarkGrey[100]};
`;
