import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import WrappedMUIButton, {
  ButtonProps,
} from '../StyledWrappers/WrappedMUIButton';

interface Props extends ButtonProps {
  label: string;
}

const PickerOption: FC<Props> = ({
  isSelected,
  height,
  width,
  label,
  onClick,
  disabled,
}): JSX.Element => {
  return (
    <StyledPickerButton
      height={height}
      width={width}
      isSelected={isSelected}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant='h5'>{label}</Typography>
    </StyledPickerButton>
  );
};

const StyledPickerButton = styled(WrappedMUIButton)<ButtonProps>`
  &.MuiButtonBase-root {
    background-color: ${(props: ButtonProps & { theme: any }): string => {
      return props.isSelected ? props.theme.colors.teal[100] : 'transparent';
    }};
    height: ${({ height }): number => height}rem;
    width: ${({ width }): number => width}rem;
  }
`;

export default PickerOption;
