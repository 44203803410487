import React, { FC } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import ColorTags from '../../Visualizations/ColorTags';
import DonutChart from '../../Visualizations/DonutChart';
import ChartData from '../../Visualizations/ChartData';
import { DonutChartColors } from '../../../Helpers/tag-color-helper';
import { usePeriodState } from '../../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../../Context/View/ViewProvider';
import fetchChartData from '../../../DataFetch/transactions';
import { useCompanyState } from '../../../Context/CompanyDataContext/CompanyProvider';
import CardLoader from '../../CardLoader';
import LoadingError from '../../LoadingError';
import { useUser } from '../../../Context/UserProvider';
import { usePeriodStatus } from '../../../Context/PeriodStatusProvider';
import WarningNote from '../../WarningNote';

interface Props {
  onTransactionTypeClicked?: (transactionType: string) => void;
}

const TransactionsDonutView: FC<Props> = ({
  onTransactionTypeClicked,
}): JSX.Element => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { savedSelections } = useCompanyState();
  const { clientID, clientIDOverride } = useUser();
  const { accountingBasis, reportType } = useReportState();
  const { selectedStatus } = usePeriodStatus();

  const { status, data } = useQuery(
    [
      'transactions-chart-data',
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
    ],
    fetchChartData,
    { refetchOnWindowFocus: false },
  );

  if (status === 'loading') {
    return <CardLoader />;
  }

  if (status === 'error' || !data) {
    return <LoadingError />;
  }

  const {
    assetCategoryVisualzationData,
    transactionDonutSlices,
    uniqueTransactionTypes,
  } = data;

  return (
    <StyledChartContainer>
      <DonutChart
        data={transactionDonutSlices}
        onSliceClicked={onTransactionTypeClicked}
      />
      <StyledTransactionsSidebarContainer>
        <ChartData
          onValueClicked={onTransactionTypeClicked}
          dataTotals={assetCategoryVisualzationData}
        />
        <ColorTags
          onTagClicked={onTransactionTypeClicked}
          tagColors={DonutChartColors}
          tagLabels={uniqueTransactionTypes}
        />
        <WarningNote />
      </StyledTransactionsSidebarContainer>
    </StyledChartContainer>
  );
};

export default TransactionsDonutView;

const StyledTransactionsSidebarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
  padding-right: 4rem;
`;

const StyledChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 5.2rem);
`;
