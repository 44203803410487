// @ts-nocheck
import React, { FC, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import {
  DonutChart as FosUIDonutChart,
  createDonutColorScale,
} from '@ntet/fos-ui';
import styled from 'styled-components';
import { DonutChartColors } from '../../Helpers/tag-color-helper';
import { AssetAllocationSlice } from '../../DataFetch/portfolio-holdings-api';
import { TransactionAllocationSlice } from '../../DataFetch/transactions';

interface DonutChartProps {
  data: Array<AssetAllocationSlice> | Array<TransactionAllocationSlice>;
  onSliceClicked?: (category: string) => void;
}

const DonutChart: FC<DonutChartProps> = ({
  data,
  onSliceClicked,
}): JSX.Element => {
  const ref = useRef<SVGGElement | null | HTMLElement>(null);

  const donutData = data.map(d => ({
    label: d.assetSuperCategory || d.reportingtransactiontype,
    percent: d.marketPercent,
  }));

  const donutColorScale = createDonutColorScale(donutData, DonutChartColors);

  useEffect(() => {
    const chart = d3.select(ref.current);
    if (chart && onSliceClicked) {
      const arcs = chart.selectAll('path'); // this selector may need to be more specific in the future?
      arcs.on('click', d => {
        onSliceClicked(d.data.label);
      });
    }
  });
  return (
    // this container is only here to attach the ref to
    // TODO: can be removed if the DonutChart in fos-ui is
    // adjusted to accept a ref
    <StyledChartRefContainer ref={ref}>
      <FosUIDonutChart
        data={donutData}
        colorScale={donutColorScale}
        hideChartHeader
      />
    </StyledChartRefContainer>
  );
};

const StyledChartRefContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 380px; //temporary to keep donut chart from cras hing, TODO: replace fos-shared card
`;
export default DonutChart;
