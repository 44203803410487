import React, { FC, useState, useCallback, useRef } from 'react';
import { Button, Typography, Menu } from '@material-ui/core/';
import styled from 'styled-components';
// import dayjs from 'dayjs';
import useConnectedState from '../../../CustomHooks/useConnectedState';
import PeriodPicker from './PeriodPicker';
import { PickerView } from '../../../Context/PeriodSelector/types';
import useSetAnchorEl from '../../../CustomHooks/useSetAnchorEl';
import DatePickerListItem from '../ListItems/DatePickerListItem';
import ConfirmationArea from '../../Buttons/ConfirmationArea';
import { PeriodSelectorActions } from '../../../Context/PeriodSelector/PeriodActions';
import { getFormatttedSelectorLabel } from '../../../Helpers/label-helper';

const PeriodSelector: FC<{}> = (): JSX.Element => {
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, handleClick, handleClose] = useSetAnchorEl();
  const [pickerView, setPickerView] = useState<PickerView | null>(null);

  const {
    periodSelectorState: {
      currentSelections: { selectedMonth, selectedQuarter, selectedYear },
    },
    periodSelectorDispatch,
  } = useConnectedState();

  const initialSelectorLabel = getFormatttedSelectorLabel(
    PickerView.YTD,
    selectedYear,
    selectedMonth,
    selectedQuarter,
  );
  const [selectorLabel, setSelectorLabel] =
    useState<string>(initialSelectorLabel);

  const updateCalendarView = async (view: PickerView): Promise<void> => {
    if (view === 'YTD') {
      periodSelectorDispatch(PeriodSelectorActions.onYtdViewSelected());
    } else {
      periodSelectorDispatch(PeriodSelectorActions.onYtdViewDeselected());
    }
    setPickerView(view);
  };

  const onCancel = (): void => {
    periodSelectorDispatch(PeriodSelectorActions.cancelSelections());
    setPickerView(null);
  };

  const onApply = useCallback((): void => {
    setSelectorLabel(
      getFormatttedSelectorLabel(
        pickerView,
        selectedYear,
        selectedMonth,
        selectedQuarter,
      ),
    );
    periodSelectorDispatch(
      PeriodSelectorActions.persistSelections(pickerView === PickerView.Annual),
    );
    handleClose();
    setPickerView(null);
  }, [
    handleClose,
    periodSelectorDispatch,
    pickerView,
    selectedMonth,
    selectedQuarter,
    selectedYear,
  ]);

  return (
    <>
      <StyledCalendarToggle
        onClick={handleClick}
        variant='outlined'
        endIcon={<i className='icon--calendar_today' />}
      >
        <Typography variant='body1'>{selectorLabel}</Typography>
      </StyledCalendarToggle>

      <StyledMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <StyledDatePickerListItemContainer ref={selectorRef}>
          {Object.values(PickerView).map((label: string) => (
            <DatePickerListItem
              label={label}
              key={label}
              onSelectionMade={updateCalendarView}
              isSelected={label === pickerView}
            />
          ))}
        </StyledDatePickerListItemContainer>

        {pickerView && (
          <StyledMenu
            open={Boolean(pickerView) && Boolean(anchorEl)}
            anchorEl={selectorRef.current || undefined}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <StyledPickerContainer>
              <PeriodPicker pickerViewType={pickerView} />
              <ConfirmationArea
                borderTop
                onCancel={onCancel}
                onApply={onApply}
              />
            </StyledPickerContainer>
          </StyledMenu>
        )}
      </StyledMenu>
    </>
  );
};

export default PeriodSelector;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    display: flex;
    min-width: 20rem;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const StyledCalendarToggle = styled(Button)`
  &.MuiButtonBase-root {
    border: 1px solid black;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    width: 20rem;
  }
`;

const StyledPickerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 33rem;
`;

const StyledDatePickerListItemContainer = styled.div`
  width: 100%;
`;
