import React, { FC } from 'react';
import { Button } from '@material-ui/core';

export interface ButtonProps {
  isSelected: boolean;
  height: number;
  width: number;
  onClick?: () => void;
  disabled?: boolean;
}

const WrappedMUIButton: FC<ButtonProps> = ({
  height,
  width,
  isSelected,
  ...props
}) => <Button {...props} />;

export default WrappedMUIButton;
