import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { useModal } from './ModalContext';

interface ModalProps {
  onClose?: () => void;
}

const Modal: FC<ModalProps> = ({ children }): JSX.Element => {
  const { node } = useModal();

  return createPortal(
    <Overlay>
      <Dialog>{children}</Dialog>
    </Overlay>,
    node || document.createElement('div'),
  );
};

export default Modal;

const fadeIn = keyframes`from { opacity: 0; }`;

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const Dialog = styled.div`
  border-top: 3px solid ${({ theme }): string => theme.colors.aqua[700]};
  background: ${({ theme }): string => theme.colors.white};
  border-radius: 3px;
  padding: 0.7rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 98%;
  height: 98%;
  overflow-y: scroll;
`;
