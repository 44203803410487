import React, { FC, useState } from 'react';
import { ReportActions } from '../../Context/View/ViewActions';
import {
  useReportDispatch,
  useReportState,
} from '../../Context/View/ViewProvider';
import EarnedIncomeSmallGridView from './EarnedIncome/EarnedIncomeSmallGridView';
import { VisualizationMode } from '../../Context/View/types';
import EarnedIncomeBarChartView from './EarnedIncome/EarnedIncomeBarChartView';

const EarnedIncomeCard: FC = (): JSX.Element => {
  const [selectedChartCategory, setSelectedChartCategory] =
    useState<string>('ALL');

  const reportDispatch = useReportDispatch();
  const { displayType } = useReportState();

  const onDataCategoryClicked = (category: string): void => {
    setSelectedChartCategory(category as string);
    reportDispatch(ReportActions.updateDisplayType(VisualizationMode.Grid));
  };

  return displayType === VisualizationMode.Grid ? (
    <EarnedIncomeSmallGridView selectedChartCategory={selectedChartCategory} />
  ) : (
    <EarnedIncomeBarChartView onDataCategoryClicked={onDataCategoryClicked} />
  );
};

export default EarnedIncomeCard;
