import React, { FC } from 'react';
import styled from 'styled-components';
import ColorTag from './ColorTag';

type Props = {
  tagColors: Array<string>;
  tagLabels: Array<string>;
  onTagClicked?: (value: string) => void;
};

const ColorTags: FC<Props> = ({
  tagColors,
  tagLabels,
  onTagClicked,
}): JSX.Element => {
  return (
    <div>
      {tagLabels.map(
        (label: string, i: number): JSX.Element => (
          <StyledButton
            type='button'
            key={label}
            onClick={(): void => {
              if (onTagClicked) {
                onTagClicked(label);
              }
            }}
          >
            <ColorTag label={label} color={tagColors[i % tagColors.length]} />
          </StyledButton>
        ),
      )}
    </div>
  );
};

export default ColorTags;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`;
