import { Dayjs } from 'dayjs';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';

import {
  AssetSuperCategory,
  getSmallGridDataFetchProps,
  getEarnedIncomeSmallGridDataFetchProps,
  getChangeAnalysisSmallGridDataFetchProps,
  TransactionType,
  Aggregate,
} from '../Helpers/data-uri-helper';
import { PublishStatus } from '../Context/PeriodStatusProvider';

export interface AssetSuperCategoryJSON {
  assetsubcategory: string;
  categoryamortizedcostbase: string;
  categorybookadjcarryingvalue: string;
  categorymarketvaluetotal: string;
  categoryunrealizedgainloss: string;
  percentageweight: string;
}

export const fetchSmallGridData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedSelections: Subsidiary[],
  _analysisType?: Aggregate,
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<AssetSuperCategoryJSON[]> => {
  const { URI, accessToken } = await getSmallGridDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedSelections,
    queryTarget,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: AssetSuperCategoryJSON[]) => {
        return data;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchSmallGridData. Error: ${error}`);
    return [];
  }
};

export const fetchEarnedIncomeSmallGridData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedSelections: Subsidiary[],
  _analysisType?: Aggregate,
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<AssetSuperCategoryJSON[]> => {
  const { URI, accessToken } = await getEarnedIncomeSmallGridDataFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedSelections,
    queryTarget,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: AssetSuperCategoryJSON[]) => data);
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchEarnedIncomeSmallGridData. Error: ${error}`);
    return [];
  }
};

export const fetchChangeAnalysisSmallGridData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  _reportType: ReportViewSelectorValue,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedSelections: Subsidiary[],
  analysisType?: Aggregate,
  queryTarget?: string | AssetSuperCategory | TransactionType | null,
): Promise<AssetSuperCategoryJSON[]> => {
  const { URI, accessToken } = await getChangeAnalysisSmallGridDataFetchProps(
    accountingBasis,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    selectedStatus,
    savedSelections,
    analysisType,
    queryTarget,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: AssetSuperCategoryJSON[]) => data);
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin change analysis small grid. Error: ${error}`);
    return [];
  }
};
