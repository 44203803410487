import { Dayjs } from 'dayjs';
import { useQuery } from 'react-query';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import {
  ReportViewSelectorValue,
  AccountingBasisValue,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import { usePeriodState } from '../Context/PeriodSelector/PeriodProvider';
import { useCompanyState } from '../Context/CompanyDataContext/CompanyProvider';
import { useReportState } from '../Context/View/ViewProvider';
import {
  AssetSuperCategory,
  TransactionType,
  Aggregate,
} from '../Helpers/data-uri-helper';
import { useUser } from '../Context/UserProvider';
import {
  usePeriodStatus,
  PublishStatus,
} from '../Context/PeriodStatusProvider';

interface Props {
  queryTarget: string | AssetSuperCategory | TransactionType | null;
  analysisType?: Aggregate;
  queryKey: string;
  queryFunction: (
    queryKey: string,
    accountingBasis: AccountingBasisValue,
    reportType: ReportViewSelectorValue,
    selectedYear: Dayjs,
    selectedMonth: Month | null,
    selectedQuarter: Quarter | null,
    clientID: string,
    isYTD: boolean,
    selectedStatus: PublishStatus,
    savedSelections: Subsidiary[],
    analysisType?: Aggregate,
    queryTarget?: string | AssetSuperCategory | TransactionType | null,
  ) => Promise<any>;
}

const useSmallGridData = (props: Props): any => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { selectedStatus } = usePeriodStatus();
  const { clientID, clientIDOverride } = useUser();
  const { savedSelections } = useCompanyState();
  const { accountingBasis, reportType } = useReportState();

  const { data } = useQuery(
    [
      props.queryKey,
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
      props.analysisType,
      props.queryTarget,
    ],
    props.queryFunction,
    { refetchOnWindowFocus: false },
  );

  return data;
};

export default useSmallGridData;
