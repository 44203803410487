import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import RoutePaths from './Helpers/route-constants';
import AuthContainer from './AuthApp';

const AppRoutes: FC<{}> = (): JSX.Element => {
  return (
    <Switch>
      <SecureRoute
        exact
        path={RoutePaths.Dashboard}
        component={AuthContainer}
      />
      <Route path='/implicit/callback' component={LoginCallback} />
    </Switch>
  );
};

export default AppRoutes;
