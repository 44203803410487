import { Layout } from '../DataFetch/layouts';
import { PrefData } from '../Context/PreferencesProvider';

// report type as defined: /Context/View/types.ts: ReportViewSelectorValue
// AKA view || expandedReportType
// layoutId format: view-userId-layoutLabel:
const getLayoutViewType = (layout: Layout): string => {
  return layout.layoutId.split('-')[0];
};

const selectedLayoutByView = (
  layouts: Array<Layout>,
  view: string,
  prefState: PrefData,
): Layout | undefined => {
  return layouts
    ?.filter((layout: Layout) => getLayoutViewType(layout) === view)
    ?.find((layout: Layout) => layout.layoutLabel === prefState[view]);
};

const findFavoritedLayout = (
  layouts: Array<Layout>,
  view: string,
): Layout | undefined => {
  return layouts
    ?.filter((layout: Layout) => getLayoutViewType(layout) === view)
    ?.find((layout: Layout) => layout.isFavorited === true);
};

const hasNewSharedLayout = (layoutsByView: Array<Layout>): boolean => {
  return layoutsByView.some((layout: Layout) => layout.isNew);
};

const findNewLayout = (
  layouts: Array<Layout>,
  view: string,
  layoutName: string,
): Layout | undefined => {
  return layouts
    ?.filter((layout: Layout) => getLayoutViewType(layout) === view)
    ?.find(
      (layout: Layout) => layout.isNew && layout.layoutLabel === layoutName,
    );
};

export {
  selectedLayoutByView,
  getLayoutViewType,
  findFavoritedLayout,
  hasNewSharedLayout,
  findNewLayout,
};
