import React, { FC } from 'react';
import styled from 'styled-components';
import { IconButton } from '@ntet/fos-ui';
import { ReportActions } from '../../Context/View/ViewActions';
import { useModal } from '../../Context/ModalContext/ModalContext';
import { useReportDispatch } from '../../Context/View/ViewProvider';
import { ExpandedReportView } from '../../Context/View/types';

const ExpandButton: FC<{
  reportType: ExpandedReportView;
}> = ({ reportType }): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();
  const dispatch = useReportDispatch();

  return (
    <StyledExpandButton
      data-testid='card-expand-button'
      icon='arrow_expand'
      onClick={(): void => {
        dispatch(
          ReportActions.setExpandedReportType({
            expandedReportType: reportType,
          }),
        );
        setModalVisibility(!isModalShown);
      }}
      disabled={false}
    />
  );
};

const StyledExpandButton = styled(IconButton)`
  &.MuiButtonBase-root {
    margin-left: 1rem;
    min-width: auto;
    width: 3.2rem;
    padding: 0;
  }
  i {
    font-size: 1.8rem;
    margin: 0 0 0 0.5rem;
  }
`;
export default ExpandButton;
