import React, { FC } from 'react';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import '@ntet/fos-fed-styles/dist/css/styles.css';

// TODO: update to use FOS-ui ToggleButton when/if available
export interface ToggleButtonProps {
  value: string;
  onChange: (_event: any, value: string | null) => void;
}

const GridChartToggle: FC<ToggleButtonProps> = (props: ToggleButtonProps) => {
  const { onChange, value } = props;
  return (
    <StyledContainer>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        aria-label='text alignment'
      >
        <ToggleButton aria-label='Grid' data-testid='grid-toggle' value='grid'>
          <StyledIcon className='icon--grid' />
        </ToggleButton>
        <ToggleButton
          data-testid='chart-toggle'
          value='chart'
          aria-label='chart'
        >
          <StyledIcon className='icon--chart_bar' />
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-block;
  margin-right: 1rem;
  button {
    border: ${({ theme }): string =>
      `1px solid ${theme.colors.button.secondary}`};
  }
  button.Mui-disabled {
    border: ${({ theme }): string =>
      `1px solid ${theme.colors.NTDarkGrey[300]}`};
  }
  button {
    border-radius: 0;
    height: 3.5rem;

    i {
      color: ${({ theme }): string => theme.colors.text.main};
    }
    &:hover {
      background-color: ${({ theme }): string => theme.colors.button.secondary};
      i {
        color: ${({ theme }): string => theme.colors.primary.contrastText};
      }
    }
    &.Mui-selected {
      background-color: ${({ theme }): string => theme.colors.text.main};
      i {
        color: ${({ theme }): string => theme.colors.primary.contrastText};
      }
    }
  }
`;

const StyledIcon = styled.i`
  font-size: 2rem;
`;

export default GridChartToggle;
