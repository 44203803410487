import React, {
  useState,
  useContext,
  useCallback,
  createContext,
  ReactNode,
  FC,
} from 'react';
import { useQuery } from 'react-query';
import authService from '../Helpers/okta-helper';
import CardLoader from '../Components/CardLoader';
import LoadingError from '../Components/LoadingError';
import { PeriodStatusSelectOptions } from '../Components/Navigation/PeriodStatus';
import { useUser } from './UserProvider';

export enum PublishStatus {
  FINAL = 'Final',
  REVIEW = 'Ready to Review',
  BEST = 'Best Available',
  CERTIFIED = 'Certified',
}

const PeriodStatusContext = createContext<PeriodStatusState | undefined>(
  undefined,
);

interface PeriodDatum {
  acctbasis: string;
  closedate: string;
}

interface PeriodData {
  acctBasisCloseDateData: PeriodDatum[];
}

interface PeriodStatusState {
  acctBasisCloseDateData: PeriodDatum[];
  updateSelectedStatus: (event: PeriodStatusSelectOptions) => void;
  selectedStatus: PublishStatus;
}

const fetchPeriodData = async (
  _queryKey: string,
  selectedPublishStatus: PublishStatus,
  clientId: string,
): Promise<PeriodData> => {
  const accessToken = await authService.getAccessToken();
  const URI = `${process.env.REACT_APP_BASE_URL}/client-status/${clientId}/${selectedPublishStatus}`;

  return fetch(URI, {
    headers: {
      Authorization: accessToken,
    },
  })
    .then((res: Response) => res.json())
    .then((data: PeriodDatum[]) => {
      return {
        acctBasisCloseDateData: data,
      };
    });
};

const PeriodStatusProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const { clientID, clientIDOverride, isAdminUser } = useUser();
  const [selectedStatus, setSelectedStatus] = useState<PublishStatus>(
    isAdminUser ? PublishStatus.REVIEW : PublishStatus.FINAL,
  );

  const updateSelectedStatus = useCallback(
    (event: PeriodStatusSelectOptions): void => {
      setSelectedStatus(event.value as PublishStatus);
    },
    [],
  );

  const { status, data } = useQuery(
    ['period-status', selectedStatus, clientIDOverride ?? clientID],
    fetchPeriodData,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (status === 'loading') {
    return <CardLoader />;
  }

  if (status === 'error' || !data) {
    return <LoadingError />;
  }

  return (
    <PeriodStatusContext.Provider
      value={{
        ...data,
        updateSelectedStatus,
        selectedStatus,
      }}
    >
      {children}
    </PeriodStatusContext.Provider>
  );
};

export default PeriodStatusProvider;

export const usePeriodStatus = (): PeriodStatusState => {
  const periodStatus = useContext(PeriodStatusContext);

  if (periodStatus === undefined) {
    throw new Error(
      'usePeriodStatus must be used within a Period Status Context Provider',
    );
  }
  return periodStatus;
};
