import React, { FC, ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core/';
import { toast } from 'react-toastify';
import { usePeriodState } from '../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../Context/View/ViewProvider';
import { usePeriodStatus } from '../../Context/PeriodStatusProvider';
import { usePreferences } from '../../Context/PreferencesProvider';
import CompanyDropdown from '../Selectors/CompanyDropdown';
import PeriodSelector from '../Selectors/PeriodSelector/PeriodSelector';
import PeriodStatusText from './PeriodStatus';
import SelectedAccountsToggle from './SelectedAccountsToggle';
import { useUser } from '../../Context/UserProvider';
import { getXLSDownloadFetchProps } from '../../Helpers/data-uri-helper';
import { PERMISSION_LEVEL_RESTRICTED } from '../../Helpers/okta-helper';
import { useCompanyDispatch } from '../../Context/CompanyDataContext/CompanyProvider';
import { CompanyDataActions } from '../../Context/CompanyDataContext/CompanyActions';

interface Props {
  toggleShowAccountSelections: () => void;
  selectedAccountsPaneShown: boolean;
}

const SubNavBar: FC<Props> = ({
  toggleShowAccountSelections,
  selectedAccountsPaneShown,
}): JSX.Element => {
  const [draftClientIDOverride, setDraftClientIDOverride] =
    useState<string>('');
  const [isXLSLoading, setIsXLSLoading] = useState<boolean>(false);

  const updateDraftClientIDOverride = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setDraftClientIDOverride(event.target.value);
  };

  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { accountingBasis, reportType } = useReportState();
  const {
    isAdminUser,
    clientID,
    clientIDOverride,
    onSubmitClientIDOverride,
    permissionLevel: currentUserPermissionLevel,
  } = useUser();

  const { updatePreferences } = usePreferences();

  const { selectedStatus } = usePeriodStatus();

  const companyDispatch = useCompanyDispatch();

  const showToast = (): any => {
    return toast.warn('Error downloading Quickview Report', {
      hideProgressBar: true,
      closeOnClick: true,
      autoClose: 1000 * 5, // 5 seconds
    });
  };

  const downloadXLS = async (): Promise<void> => {
    setIsXLSLoading(true);

    const { URI, accessToken } = await getXLSDownloadFetchProps(
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
    );
    try {
      await fetch(URI, {
        headers: { Authorization: accessToken },
      })
        .then((res: Response) => res.json())
        .then(data => {
          const { signedUrl, fileName, error } = data;

          if (error) {
            // eslint-disable-next-line no-console
            console.log(`Error downloading XLS report: ${error.code}`);
            showToast();
          } else {
            // create link element to download XLS file from presigned URL
            const link = document.createElement('a');
            link.href = signedUrl;
            link.setAttribute('download', `${fileName}.xls`);
            document.body.appendChild(link);

            // click the link and remove it from the DOM
            link.click();
            link.parentNode?.removeChild(link);
          }

          // end loading state
          setIsXLSLoading(false);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error in xls download. Error: ${error}`);
      showToast();

      setIsXLSLoading(false);
    }
  };

  return (
    <StyledSubNavContainer>
      <StyledLeftSection>
        <CompanyDropdown />
        <SelectedAccountsToggle
          toggleShowAccountSelections={toggleShowAccountSelections}
          selectedAccountsPaneShown={selectedAccountsPaneShown}
        />
        {isAdminUser && (
          <StyledClientIDFormContainer>
            <StyledForm
              noValidate
              autoComplete='off'
              onSubmit={(event: FormEvent<HTMLFormElement>): void => {
                event.preventDefault();

                onSubmitClientIDOverride(draftClientIDOverride || '');
                companyDispatch(CompanyDataActions.resetSelections());

                updatePreferences({
                  clientId: draftClientIDOverride,
                  layoutArr: null,
                });
              }}
            >
              <StyledClientIDTextField
                id='clientId-input'
                label={`Client ID: ${clientIDOverride ?? clientID}`} // TODO: Do this.. better and for the actually selected value.
                value={draftClientIDOverride}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  updateDraftClientIDOverride(event);
                }}
              />
              <StyledButton
                disabled={draftClientIDOverride === clientIDOverride}
                variant='outlined'
                type='submit'
              >
                <Typography variant='button'>Submit</Typography>
              </StyledButton>
            </StyledForm>
          </StyledClientIDFormContainer>
        )}
        {currentUserPermissionLevel > PERMISSION_LEVEL_RESTRICTED && (
          <StyledDownloadContainer>
            <StyledDownloadButton
              disabled={isXLSLoading}
              variant='outlined'
              type='submit'
              fullWidth
              onClick={downloadXLS}
            >
              {isXLSLoading ? (
                <StyledSpinner size={25} />
              ) : (
                <Typography variant='button'>
                  <i className='icon--download' />
                  QuickView Report
                </Typography>
              )}
            </StyledDownloadButton>
          </StyledDownloadContainer>
        )}
      </StyledLeftSection>
      <StyledRightSection>
        <PeriodSelector />
        <PeriodStatusText />
      </StyledRightSection>
    </StyledSubNavContainer>
  );
};

const StyledDownloadContainer = styled.div`
  display: flex;
`;

const StyledRightSection = styled.div`
  display: flex;
  max-width: 60rem;
  max-height: 3.3rem;
  width: 100%;
`;

const StyledLeftSection = styled.div`
  display: flex;
  max-width: 79rem;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
`;

const StyledClientIDFormContainer = styled.div`
  width: 30rem;
  display: flex;
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 3rem;
    margin: auto 0px auto 2rem;
  }
`;

const StyledDownloadButton = styled(Button)`
  i {
    margin-right: 1rem;
  }
  &.MuiButton-root {
    height: 3rem;
    margin: auto 0px auto 2rem;
    width: 18rem;
  }
`;

const StyledClientIDTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-left: 2rem;
  }
  .MuiFormLabel-root {
    font-size: 1.3rem;
  }
`;

const StyledSubNavContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.white};
  border-bottom: 1px solid rgb(206, 206, 206);
  display: flex;
  height: 5rem;
  justify-content: space-between;
  width: 100%;
`;

const StyledSpinner = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    height: 3rem;
    width 3rem;
  }
  
  &.MuiCircularProgress-colorPrimary {
  
    color: ${({ theme }): string => theme.colors.NTGreen[600]};
  }
`;

export default SubNavBar;
