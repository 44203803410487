import React, { FC } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { usePeriodState } from '../../Context/PeriodSelector/PeriodProvider';
import { useReportState } from '../../Context/View/ViewProvider';
import { useCompanyState } from '../../Context/CompanyDataContext/CompanyProvider';
import { fetchChartData } from '../../DataFetch/trial-balance';
import { formatMonetaryNumberLocaleString } from '../../Helpers/grid-formatters';
import '../Grid/Grid.css';
import CardLoader from '../CardLoader';

import '@ntet/fos-fed-styles/dist/css/styles.css';
import { tagMappings } from '../../Helpers/label-helper';
import { useUser } from '../../Context/UserProvider';
import { usePeriodStatus } from '../../Context/PeriodStatusProvider';

const TrialBalanceCard: FC = (): JSX.Element => {
  const {
    savedSelections: {
      selectedYear,
      selectedQuarter,
      selectedMonth,
      isYearToDate,
    },
  } = usePeriodState();

  const { selectedStatus } = usePeriodStatus();
  const { clientID, clientIDOverride } = useUser();
  const { savedSelections } = useCompanyState();
  const { accountingBasis, reportType } = useReportState();

  const { status, data } = useQuery(
    [
      'trial-balance-data',
      accountingBasis,
      reportType,
      selectedYear,
      selectedMonth,
      selectedQuarter,
      clientIDOverride ?? clientID,
      isYearToDate,
      selectedStatus,
      savedSelections,
    ],
    fetchChartData,
    { refetchOnWindowFocus: false },
  );

  const mySum = (values: any): any => {
    let result: number = 0;
    values.forEach((value: any) => {
      result += Number(value);
    });
    return result;
  };

  const gridOptions: any = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
    },
    autoGroupColumnDef: {
      minWidth: 200,
      headerName: 'Category',
    },
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
  };

  const colDefs: Array<ColDef | ColGroupDef> = [
    {
      field: 'category',
      hide: true,
      enableRowGroup: true,
      rowGroup: true,
      openByDefault: true,
    },
    {
      field: 'description',
      headerName: tagMappings.get('description'),
      width: 350,
      cellStyle: { 'font-weight': 500 },
    },
    {
      field: 'previousPeriodValue',
      headerName: data ? data.prevLabel : '-',
      aggFunc: mySum,
      type: 'numericColumn',
      width: 210,
      valueFormatter: formatMonetaryNumberLocaleString,
    },
    {
      field: 'netChange',
      headerName: 'Net Period Change',
      aggFunc: mySum,
      type: 'numericColumn',
      width: 210,
      valueFormatter: formatMonetaryNumberLocaleString,
    },
    {
      field: 'currentPeriodValue',
      headerName: data ? data.currLabel : '-',
      aggFunc: mySum,
      type: 'numericColumn',
      width: 210,
      valueFormatter: formatMonetaryNumberLocaleString,
    },
  ];
  if (status === 'loading') {
    return <CardLoader />;
  }
  return (
    <StyledAgGridContainer className='ag-theme-views'>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={gridOptions.defaultColDef}
        gridOptions={gridOptions}
        rowData={data ? data.data : []}
        statusBar={undefined}
      />
    </StyledAgGridContainer>
  );
};

const StyledAgGridContainer = styled.div`
  border-top: 2px solid
    ${(props): string => props.theme.colors.NTDarkGrey[700]}};
  height: calc(100% - 5.2rem);
  width: 100%;
`;

export default TrialBalanceCard;
