import React, { FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '@ntet/fos-ui';
import { SnackbarProvider } from '@ntet/fos-ui/dist/Components/Notification/NotificationContext';
import NavBar from './Components/Navigation/NavBar';
import SubNavContainer from './Components/Navigation/SubNavContainer';
import AppContentContainer from './Components/AppContentContainer';
import { ReportDataProvider } from './Context/View/ViewProvider';
import { PeriodProvider } from './Context/PeriodSelector/PeriodProvider';
import ModalProvider from './Context/ModalContext/ModalContext';
import { CompanyDataProvider } from './Context/CompanyDataContext/CompanyProvider';
import GridOverlay from './Components/Grid/GridOverlay';
import CardLoader from './Components/CardLoader';
import PeriodStatusProvider from './Context/PeriodStatusProvider';
import UserAdminProvider from './Context/UserProvider';
import ContentRoutes from './ContentRoutes';
import { PreferencesProvider } from './Context/PreferencesProvider';

const AuthContainer: FC<{}> = (): JSX.Element => {
  const { authState } = useOktaAuth();
  if (authState.isAuthenticated) {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <UserAdminProvider>
            <PeriodStatusProvider>
              <CompanyDataProvider>
                <PeriodProvider>
                  <ReportDataProvider>
                    <PreferencesProvider>
                      <StyledAppContainer>
                        <NavBar />
                        <ModalProvider>
                          <GridOverlay />
                          <SubNavContainer />
                          <AppContentContainer>
                            <ContentRoutes />
                          </AppContentContainer>
                        </ModalProvider>
                      </StyledAppContainer>
                    </PreferencesProvider>
                  </ReportDataProvider>
                </PeriodProvider>
              </CompanyDataProvider>
            </PeriodStatusProvider>
          </UserAdminProvider>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
  return (
    <StyledAppContainer>
      <CardLoader />
    </StyledAppContainer>
  );
};

export default AuthContainer;

const StyledAppContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
