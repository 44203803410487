import React, { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  GridOptions,
  ColDef,
  ColGroupDef,
  CellClickedEvent,
} from 'ag-grid-community';
import '../../Grid/Grid.css';
import { ReportActions } from '../../../Context/View/ViewActions';
import { formatMonetaryNumberLocaleString } from '../../../Helpers/grid-formatters';
import '@ntet/fos-fed-styles/dist/css/styles.css';
import { fetchSmallGridData } from '../../../DataFetch/small-grid-api';
import { useModal } from '../../../Context/ModalContext/ModalContext';
import { useReportDispatch } from '../../../Context/View/ViewProvider';
import {
  ReportViewSelectorValue,
  ColumnModifier,
} from '../../../Context/View/types';
import useSmallGridData from '../../../CustomHooks/useSmallGridData';
import { TransactionType } from '../../../Helpers/data-uri-helper';
import { tagMappings } from '../../../Helpers/label-helper';

const TransactionsSmallGridView: FC<{
  transactionType: TransactionType | null;
}> = ({ transactionType }): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();
  const dispatch = useReportDispatch();

  const data = useSmallGridData({
    queryTarget: transactionType,
    queryKey: `transactiosn-small-grid-${transactionType}`,
    queryFunction: fetchSmallGridData,
  });

  const gridOptions: GridOptions = {
    animateRows: true,
    defaultColDef: {
      filter: false,
      resizable: true,
      suppressMenu: true,
    },
    suppressContextMenu: true,
    onCellClicked: (event: CellClickedEvent): void => {
      const { colDef, value } = event;
      if (
        (colDef.field && colDef.field === 'assetsupercategory') ||
        colDef.field === 'reportingtransactiontype'
      ) {
        const columnModifiers = new Map<string, ColumnModifier>();

        const columnModifier: ColumnModifier = {
          field: colDef.field,
          filter: true,
          pin: 'left',
          value,
        };

        columnModifiers.set(colDef.field, columnModifier);

        const typeColMod: ColumnModifier = {
          field: 'reportingtransactiontype',
          filter: true,
          pin: 'left',
          value:
            colDef.field === 'reportingtransactiontype'
              ? value
              : transactionType ?? ' ',
        };

        columnModifiers.set('reportingtransactiontype', typeColMod);

        setModalVisibility(!isModalShown);
        dispatch(
          ReportActions.setExpandedReportType({
            expandedReportType: ReportViewSelectorValue.Transactions,
            columnModifiers,
          }),
        );
      }
    },
  };

  const colDefs: Array<ColDef | ColGroupDef> = [
    {
      field: 'reportingtransactiontype',
      headerName: tagMappings.get('reportingtransactiontype'),
      width: 300,
      cellStyle: { 'font-weight': 500 },
    },
    {
      field: 'transactioncount',
      headerName: tagMappings.get('transactioncount'),
      type: 'numericColumn',
    },
    {
      field: 'amortizedcostdisposedbaseccy',
      headerName: tagMappings.get('amortizedcostdisposedbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'proceedsbaseccy',
      headerName: tagMappings.get('proceedsbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'tradegainslossesbaseccy',
      headerName: tagMappings.get('tradegainslossesbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
  ];

  if (transactionType !== TransactionType.ALL) {
    colDefs.unshift({
      field: 'assetsupercategory',
      headerName: 'Asset Type',
      width: 300,
      cellStyle: { 'font-weight': 500 },
    });
  }

  return (
    <StyledAgGridContainer className='ag-theme-views'>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={gridOptions.defaultColDef}
        gridOptions={gridOptions}
        rowData={data}
        statusBar={undefined}
      />
    </StyledAgGridContainer>
  );
};

export default TransactionsSmallGridView;

const StyledAgGridContainer = styled.div`
  border-top: 2px solid
    ${(props): string => props.theme.colors.NTDarkGrey[700]}};
  height: calc(100% - 5.2rem);
  width: 100%;
`;
