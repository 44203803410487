import authService from '../Helpers/okta-helper';
import { Layout } from './layouts';

interface SuccessResponse {
  id: string;
  preferences: any;
}

interface Preferences {
  selectedAccounts: Array<any>;
  selectedLegalEntities: Array<any>;
  defaultClient: number;
  layouts: Array<Layout>;
}

export interface PreferenceResponse {
  id: string;
  lastModified: number;
  preferences: Preferences;
}

export interface PreferenceUpdateInput {
  userId: string;
  clientId: string;
  layoutArr?: Array<Layout>;
}

const fetchPreferences = async (
  _queryKey: string,
  clientId: string,
  userId: string,
): Promise<PreferenceResponse | undefined | null> => {
  const accessToken = await authService.getAccessToken();
  const URI = `${process.env.REACT_APP_BASE_URL}/preferences/${clientId}/${userId}`;
  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        if (data.length === 0) {
          return null;
        }
        return data;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    return null;
  }
};

const savePreference = async ({
  userId,
  clientId,
  layoutArr,
}: PreferenceUpdateInput): Promise<SuccessResponse | null> => {
  const accessToken = await authService.getAccessToken();
  const URI = `${process.env.REACT_APP_BASE_URL}/preferences/${clientId}/${userId}`;

  const prefBodyObj = {
    id: userId,
    preferences: {
      layouts: layoutArr,
      selectedAccounts: [],
      selectedLegalEntities: [],
      defaultClient: clientId,
    },
    lastModified: Date.now(),
  };

  try {
    return await fetch(URI, {
      method: 'POST',
      headers: { Authorization: accessToken },
      body: JSON.stringify(prefBodyObj),
    })
      .then((res: Response) => res.json())
      .then((resData: any) => {
        return resData;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin savePreference. Error: ${error}`);
    return null;
  }
};

export { fetchPreferences, savePreference };
