import React, { FC } from 'react';
import { getFormattedReportLabel } from 'Helpers/label-helper';
import { usePeriodState } from '../Context/PeriodSelector/PeriodProvider';
import { StyledBodyText } from './StyledWrappers/WrappedTypography';

const SelectedPeriodText: FC = (): JSX.Element => {
  const {
    savedSelections: {
      isYearToDate,
      selectedMonth,
      selectedQuarter,
      selectedYear,
    },
  } = usePeriodState();

  return (
    <StyledBodyText variant='body1'>
      {getFormattedReportLabel(
        selectedYear,
        selectedMonth,
        selectedQuarter,
        isYearToDate,
      )}
    </StyledBodyText>
  );
};

export default SelectedPeriodText;
