import authService from '../Helpers/okta-helper';

export interface Layout {
  isFavorited: boolean | undefined;
  isNew: boolean;
  layoutId: string; // format: view-userId-UserDisplayName-uuid
  layoutLabel: string; // format: userDisplayName-layoutName
}

interface SuccessResponse {
  id: string;
  preferences: any;
}

interface AgGridModel {
  params: {
    valueCols: Array<any>;
    sortModel: Array<any>;
    filterModel: any;
    rowGroupCols: Array<any>;
  };
  columns: Array<any>;
}

interface LayoutResponse {
  fullLayoutName: string; // ownerId-UserDisplayName-uuid
  cardView: string;
  ownerDisplayName: string;
  layoutDef: {
    AgGridModel: AgGridModel;
  };
  lastModified: number;
  layoutName: string;
  owner: string;
  sharedTo: Array<string>;
}

export interface LayoutUpdateInput {
  userId: string;
  clientID: string;
  view: string;
  layoutName: string;
  data: any;
  userDisplayName: string;
  uuid: string;
  existingLayoutId?: string | undefined;
}

interface LayoutShareInput {
  clientID: string;
  sharedToUserId: string;
  existingLayoutId: string;
}

interface LayoutDeleteInput {
  userId: string;
  clientID: string;
  existingLayoutId: string;
}

const fetchLayout = async (
  _queryKey: string,
  clientID: string,
  layoutId: string,
): Promise<LayoutResponse | null> => {
  const accessToken = await authService.getAccessToken();
  const lastModifiedDate = Date.now();
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/savedLayouts/${clientID}/${encodeURIComponent(
    layoutId,
  )}/${lastModifiedDate}`;

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        if (data.length === 0) {
          return null;
        }
        return data;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin fetchLayout. Error: ${error}`);
    return null;
  }
};

const saveLayout = async ({
  userId,
  clientID,
  view,
  layoutName,
  data,
  userDisplayName,
  uuid,
  existingLayoutId,
}: LayoutUpdateInput): Promise<SuccessResponse | null> => {
  const lastModifiedDate = Date.now();
  const fullLayoutName = `${userId}-${userDisplayName}-${uuid}`;
  const layoutId = existingLayoutId || `${view}-${fullLayoutName}`;
  const accessToken = await authService.getAccessToken();
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/savedLayouts/${clientID}/${encodeURIComponent(
    layoutId,
  )}/${lastModifiedDate}`;

  const layoutBodyObj = {
    cardView: encodeURIComponent(view),
    fullLayoutName,
    layoutName,
    layoutDef: {
      AgGridModel: data,
    },
    owner: userId,
    ownderDisplayName: userDisplayName,
    sharedTo: [userId],
    lastModified: lastModifiedDate,
  };

  try {
    return await fetch(URI, {
      method: 'POST',
      headers: { Authorization: accessToken },
      body: JSON.stringify(layoutBodyObj),
    })
      .then((res: Response) => res.json())
      .then((resData: any) => {
        return resData;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin saveLayout. Error: ${error}`);
    return null;
  }
};

const shareLayout = async ({
  clientID,
  sharedToUserId,
  existingLayoutId,
}: LayoutShareInput): Promise<SuccessResponse | null> => {
  const lastModifiedDate = Date.now();
  const accessToken = await authService.getAccessToken();
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/shareLayout/${clientID}/${encodeURIComponent(
    existingLayoutId,
  )}/toUser/${sharedToUserId}/${lastModifiedDate}`;

  try {
    return await fetch(URI, {
      method: 'POST',
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((resData: any) => {
        return resData;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin shareLayout. Error: ${error}`);
    return null;
  }
};

const deleteLayout = async ({
  userId,
  clientID,
  existingLayoutId,
}: LayoutDeleteInput): Promise<SuccessResponse | null> => {
  const lastModifiedDate = Date.now();
  const accessToken = await authService.getAccessToken();
  const URI = `${
    process.env.REACT_APP_BASE_URL
  }/savedLayouts/${clientID}/${encodeURIComponent(
    existingLayoutId,
  )}/${lastModifiedDate}`;

  const layoutBodyObj = {
    currentUser: userId,
  };

  try {
    return await fetch(URI, {
      method: 'DELETE',
      headers: { Authorization: accessToken },
      body: JSON.stringify(layoutBodyObj),
    })
      .then((res: Response) => res.json())
      .then((resData: any) => {
        return resData;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`errorin deleteLayout. Error: ${error}`);
    return null;
  }
};

export { saveLayout, shareLayout, fetchLayout, deleteLayout };
