import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, SelectableListItem, Button } from '@ntet/fos-ui';
import RenameModal from '../Modals/RenameModal';
import SaveModal from '../Modals/SaveModal';
import DeleteModal from '../Modals/DeleteModal';
import ShareModal from '../Modals/ShareModal';
import { usePreferences } from '../../Context/PreferencesProvider';
import ChooseDropDown from '../DropDowns/ChooseDropDown';
import { Layout } from '../../DataFetch/layouts';
import {
  getLayoutViewType,
  hasNewSharedLayout,
} from '../../Helpers/preferences-helper';

const RENAME = 'Rename';
const SAVE = 'Save';
const DELETE = 'Delete';
const SHARE = 'Share';

interface Props {
  handleSubmit: Function;
  handleReset: Function;
  expandedReportType: string;
  isResetting: boolean;
  setIsSaving: Function;
}

const ManageButton: FC<Props> = ({
  handleSubmit,
  handleReset,
  expandedReportType,
  isResetting,
  setIsSaving,
}): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [manageAnchorEl, setManageAnchorEl] = useState(null);
  const [chooseAnchorEl, setChooseAnchorEl] = useState(null);

  const [inputModalOpen, setInputModalOpen] = useState(false);

  const [modalType, setModalType] = useState('');

  const [isChooseDropDownOpen, setIsChooseDropDownOpen] = useState(false);
  const { prefState, layouts } = usePreferences();

  const onClickManageDropdown = (event: any): void => {
    setManageAnchorEl(event.currentTarget);
    setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
  };
  const onClickChooseDropdown = (event: any): void => {
    setChooseAnchorEl(event.currentTarget);
    setIsChooseDropDownOpen(
      prevIsChooseDropDownOpen => !prevIsChooseDropDownOpen,
    );
  };
  const onClose = (): void => {
    setManageAnchorEl(null);
    setChooseAnchorEl(null);
    setIsDropdownOpen(false);
    setIsChooseDropDownOpen(false);
  };

  const layoutsByView = layouts
    ? layouts.filter(
        (layout: Layout) => getLayoutViewType(layout) === expandedReportType,
      )
    : null;

  const modalSelector = (): JSX.Element | null => {
    switch (modalType) {
      case RENAME:
        return (
          <RenameModal
            expandedReportType={expandedReportType}
            inputModalOpen={inputModalOpen}
            setInputModalOpen={setInputModalOpen}
            setModalType={setModalType}
            setIsSaving={setIsSaving}
          />
        );
      case SAVE:
        return (
          <SaveModal
            handleSubmit={handleSubmit}
            expandedReportType={expandedReportType}
            inputModalOpen={inputModalOpen}
            setInputModalOpen={setInputModalOpen}
            setModalType={setModalType}
          />
        );
      case DELETE:
        return (
          <DeleteModal
            expandedReportType={expandedReportType}
            inputModalOpen={inputModalOpen}
            setInputModalOpen={setInputModalOpen}
            setModalType={setModalType}
            setIsDeleting={setIsDeleting}
          />
        );
      case SHARE:
        return (
          <ShareModal
            expandedReportType={expandedReportType}
            inputModalOpen={inputModalOpen}
            setInputModalOpen={setInputModalOpen}
            setModalType={setModalType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Button
        buttonStyle='SECONDARY'
        className='box-shadow'
        icon='content_save'
        onClick={onClickManageDropdown}
        isFocused={isDropdownOpen}
        includeDropdownButton
        isActive={isDropdownOpen}
        isDropdownOpen={isDropdownOpen}
      >
        MANAGE
      </Button>
      <Dropdown
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={manageAnchorEl}
        open={isDropdownOpen}
        onClose={onClose}
      >
        <StylesDropDown>
          <SelectableListItem
            label='Reset'
            onClick={(): void => {
              handleReset(expandedReportType as string);
              onClose();
            }}
          />
          <NewLayoutContainerDiv>
            {hasNewSharedLayout(layoutsByView) ? (
              <i className='icon--media_record' />
            ) : (
              // Spacing was off - couldnt figure out the CSS, so hack fix below.
              <HiddenIcon>
                <i className='icon--media_record' />
              </HiddenIcon>
            )}
            <SelectableListItem
              label='Select'
              disabled={layoutsByView?.length === 0 || !layoutsByView}
              onClick={onClickChooseDropdown}
            />
          </NewLayoutContainerDiv>
          <SelectableListItem
            label={SAVE}
            onClick={(): void => {
              setInputModalOpen(true);
              setModalType(SAVE);
              onClose();
            }}
          />
          <SelectableListItem
            label={RENAME}
            disabled={!prefState[expandedReportType]}
            onClick={(): void => {
              setModalType(RENAME);
              setInputModalOpen(true);
              onClose();
            }}
          />
          <SelectableListItem
            label={DELETE}
            disabled={!prefState[expandedReportType]}
            onClick={(): void => {
              setModalType(DELETE);
              setInputModalOpen(true);
              onClose();
            }}
          />
          <SelectableListItem
            label={SHARE}
            disabled={!prefState[expandedReportType]}
            onClick={(): void => {
              setModalType(SHARE);
              setInputModalOpen(true);
              onClose();
            }}
          />
        </StylesDropDown>
      </Dropdown>
      <ChooseDropDown
        expandedReportType={expandedReportType}
        isChooseDropDownOpen={isChooseDropDownOpen}
        setIsChooseDropDownOpen={setIsChooseDropDownOpen}
        chooseAnchorEl={chooseAnchorEl}
        setIsDropdownOpen={setIsDropdownOpen}
        isResetting={isResetting}
        isDeleting={isDeleting}
      />
      {modalSelector()}
    </>
  );
};

export default ManageButton;

const StylesDropDown = styled.div`
  width: 150px;
  margin: 10px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 5px;

  i {
    color: ${({ theme }): string => theme.colors.aqua[700]};
    align-items: center;
  }
`;

const NewLayoutContainerDiv = styled.div`
  display: flex;

  i {
    margin: -3px;
  }
`;

const HiddenIcon = styled.div`
  visibility: hidden;
`;
