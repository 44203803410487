import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { useUser } from '../../Context/UserProvider';
import { usePreferences } from '../../Context/PreferencesProvider';
import InputModal from './InputModal';
import { shareLayout } from '../../DataFetch/layouts';
import { selectedLayoutByView } from '../../Helpers/preferences-helper';

interface Props {
  expandedReportType: string;
  inputModalOpen: boolean;
  setInputModalOpen: Function;
  setModalType: Function;
}

const ShareModal: FC<Props> = ({
  expandedReportType,
  inputModalOpen,
  setInputModalOpen,
  setModalType,
}): JSX.Element => {
  const { prefState, layouts } = usePreferences();
  const { clientID, clientIDOverride } = useUser();

  const selectedLayout = selectedLayoutByView(
    layouts,
    expandedReportType,
    prefState,
  );
  const existingLayoutId = selectedLayout?.layoutId;

  const [mutateShare] = useMutation(shareLayout);

  // userInput here will be the userID of the shared user.
  const handleShare = (view: string, userInput: string): void => {
    // Something has gone very wrong if we lack an existing layoutId to share..
    if (existingLayoutId) {
      mutateShare({
        clientID: clientIDOverride ?? clientID,
        sharedToUserId: userInput,
        existingLayoutId,
      });
    }
  };

  const notificationTitle = (userInput: string): string => {
    return `${prefState[expandedReportType]} was shared with ${userInput}.`;
  };

  return (
    <InputModal
      handleSubmit={handleShare}
      expandedReportType={expandedReportType}
      inputModalOpen={inputModalOpen}
      setInputModalOpen={setInputModalOpen}
      setModalType={setModalType}
      notificationTitle={notificationTitle}
      modalTitle={`Share ${prefState[expandedReportType]}`}
      adjustedLayouts={layouts}
      hasInput
      confirmBtnText='Share'
      subHeader='To: '
      isSharing
    />
  );
};

export default ShareModal;
