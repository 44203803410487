import React, { FC } from 'react';
import styled from 'styled-components';
import DateSlider from './DateSlider';
import QuarterlyView from './Views/QuarterlyView';
import MonthlyView from './Views/MonthlyView';
import { PickerView } from '../../../Context/PeriodSelector/types';
import { useReportState } from '../../../Context/View/ViewProvider';
import { usePeriodStatus } from '../../../Context/PeriodStatusProvider';
import { useUser } from '../../../Context/UserProvider';
import useCalendarEnabler from '../../../CustomHooks/useCalendarEnabler';
import { emptyCalendarEnablerResponse } from '../../../DataFetch/calendar-enabler';

interface Props {
  pickerViewType: PickerView | null;
}

const PeriodPicker: FC<Props> = ({ pickerViewType }): JSX.Element => {
  const { selectedStatus } = usePeriodStatus();
  const { clientID: clientId, clientIDOverride: clientIdOverride } = useUser();
  const { accountingBasis, reportType } = useReportState();
  const calendarEnablerResponse = useCalendarEnabler({
    reportType,
    clientId: clientIdOverride ?? clientId,
    accountingBasis,
    selectedStatus,
  });

  const data = calendarEnablerResponse || emptyCalendarEnablerResponse;

  return (
    <StyledPeriodPickerContainer>
      <DateSlider />
      {(pickerViewType === PickerView.Monthly ||
        pickerViewType === PickerView.YTD) && (
        <MonthlyView
          isYTD={pickerViewType === PickerView.YTD}
          availableDates={
            pickerViewType === PickerView.YTD ? data.ytd : data.monthly
          }
        />
      )}
      {pickerViewType === PickerView.Quarterly && (
        <QuarterlyView availableDates={data.quarterly} />
      )}
    </StyledPeriodPickerContainer>
  );
};

export default PeriodPicker;

const StyledPeriodPickerContainer = styled.div`
  margin-bottom: 1rem;
`;
