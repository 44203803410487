/* eslint-disable no-param-reassign */
import React, {
  FC,
  ReactNode,
  useContext,
  useReducer,
  createContext,
  Dispatch,
  useMemo,
} from 'react';

import produce, { Draft } from 'immer';
import { Dayjs } from 'dayjs';
import { PublishStatus } from 'Context/PeriodStatusProvider';
import {
  CompanyState,
  CompanyDataWhitelist,
  JSONResponseCompanyData,
  JSONResponseSubsidiary,
  JSONResponseSubsidiaryAccount,
  Subsidiary,
  SubsidiaryAccount,
  SubsidiaryAccountCheckboxData,
} from './types';

import { Month, Quarter } from '../PeriodSelector/types';
import { CompanyDataActions, CompanyActions } from './CompanyActions';
import { getColor, NTTagColor } from '../../Helpers/tag-color-helper';
import { getCompanyDropdownFetchProps } from '../../Helpers/data-uri-helper';

/**
 * TODO: This should eventually change to a better data structure. Probably a Map of Subsidiary -> array of accounts
 * or something similar.
 */

const initialState: CompanyState = {
  isLoading: true,
  companyName: '',
  id: '',
  currentSelections: [],
  savedSelections: [],
  sessionSelections: null,
  displaySelectedAccountsPane: false,
};

const CompanyStateContext = createContext<CompanyState | undefined>(undefined);
const CompanyDispatchContext = createContext<
  Dispatch<CompanyDataActions> | undefined
>(undefined);

const fetchCompanyData = async (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  publishStatus: PublishStatus,
  dispatch: Dispatch<CompanyDataActions>,
): Promise<void> => {
  dispatch(CompanyDataActions.initializeUpdate());

  const { URI, accessToken } = await getCompanyDropdownFetchProps(
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    publishStatus,
  );

  try {
    const headers = {
      Authorization: accessToken,
    };
    await fetch(URI, { headers })
      .then((res: Response) => res.json())
      .then((data: Array<JSONResponseCompanyData>) => {
        dispatch(CompanyDataActions.finishUpdate(data[0]));
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetchCompanyData. Error: ${error}`);
    // Dispatch a blank response in order to display no companies.
    dispatch(
      CompanyDataActions.finishUpdate({
        COMPANY_NAME: '',
        ID: '',
        SUBSIDIARIES: [],
      }),
    );
  }
};

const populateCheckboxData = (
  draft: Draft<CompanyState>,
  fetchResponse: JSONResponseCompanyData,
): void => {
  let COMPANY_NAME = '';
  let ID = '';
  let subsidiaries: Array<Subsidiary> = [];

  // fetchResponse will be blank if no Companies are returned from the API.
  if (fetchResponse) {
    ({ COMPANY_NAME, ID } = fetchResponse);

    subsidiaries = fetchResponse.SUBSIDIARIES.map(
      (subsidiary: JSONResponseSubsidiary, i: number): Subsidiary => {
        const tagColor: NTTagColor = getColor(i);
        return {
          ...subsidiary,
          // If the whitelist is present, check if the whitelist for whether or not the subsidiary is checked.
          // If it is not specified in the whitelist, a subsidiary is deselected by default.
          // If the whitelist is not present, all subsidiaries are selected by default
          isChecked: draft.sessionSelections
            ? draft.sessionSelections.subsidiaries.get(subsidiary.ID) ?? false
            : true,
          tagColor,
          accounts: subsidiary.ACCOUNTS.map(
            (account: JSONResponseSubsidiaryAccount): SubsidiaryAccount => {
              return {
                ...account,
                isChecked: draft.sessionSelections
                  ? draft.sessionSelections.accounts.get(
                      subsidiary.ID + account.ID,
                    ) ?? false
                  : true,
                tagColor,
              };
            },
          ),
        };
      },
    );
  }

  draft.id = ID;
  draft.companyName = COMPANY_NAME === '' ? draft.companyName : COMPANY_NAME;
  draft.savedSelections = subsidiaries;

  draft.currentSelections = draft.savedSelections;
};

const updateSubsidiaryCheckbox = (
  draftSubsidiaries: Draft<Array<Subsidiary>>,
  subsidiaryId: string,
  valueToSet?: boolean,
): void => {
  draftSubsidiaries.forEach((subsidiary: Draft<Subsidiary>) => {
    if (subsidiary.ID === subsidiaryId) {
      subsidiary.isChecked = valueToSet || !subsidiary.isChecked;
      subsidiary.accounts.forEach((account: Draft<SubsidiaryAccount>) => {
        account.isChecked = subsidiary.isChecked;
      });
    }
  });
};

const updateSubsidiaryAccountCheckbox = (
  draftSubsidiaries: Draft<Array<Subsidiary>>,
  checkboxData: SubsidiaryAccountCheckboxData,
  valueToSet?: boolean,
): void => {
  const { id, subsidiaryId } = checkboxData;
  draftSubsidiaries.forEach((subsidiary: Draft<Subsidiary>) => {
    if (subsidiary.ID === subsidiaryId) {
      let selectedAccounts = 0;
      subsidiary.accounts.forEach((account: Draft<SubsidiaryAccount>) => {
        if (account.ID === id) {
          account.isChecked = valueToSet || !account.isChecked;
        }
        if (account.isChecked) {
          selectedAccounts += 1;
        }
      });
      subsidiary.isChecked = selectedAccounts !== 0;
    }
  });
};

const toggleSubsidiaryCheckboxState = (
  draftSubsidiaries: Draft<Array<Subsidiary>>,
  subsidiaryId: string,
): void => {
  updateSubsidiaryCheckbox(draftSubsidiaries, subsidiaryId);
};

/**
 * Generate a whitelist representing the currently selected subsidiaries and accounts.
 * A new whitelist should be generated every time the selections is updated in the UI.
 * @param subsidiaries: the array of subsidiaries (and nested accounts) in the current state
 */
const generateSessionSelectionWhitelist = (
  subsidiaries: Array<Subsidiary>,
): CompanyDataWhitelist => {
  const selectedWhitelist: CompanyDataWhitelist = {
    subsidiaries: new Map<string, boolean>(),
    accounts: new Map<string, boolean>(),
  };

  subsidiaries.forEach((subsidiary: Subsidiary) => {
    selectedWhitelist.subsidiaries.set(subsidiary.ID, subsidiary.isChecked);
    subsidiary.accounts.forEach((account: Draft<SubsidiaryAccount>) => {
      // Because Account IDs are only unique WITHIN a given Subsidiary, we need to make the account ID
      // in the whitelist unique ACROSS Subsidiaries, we need to append the Subsidiary ID.
      selectedWhitelist.accounts.set(
        subsidiary.ID + account.ID,
        account.isChecked,
      );
    });
  });

  return selectedWhitelist;
};

const deselectSavedSubsidaryCheckboxState = (
  draft: Draft<CompanyState>,
  subsidiaryId: string,
): void => {
  updateSubsidiaryCheckbox(draft.savedSelections, subsidiaryId, false);
  draft.currentSelections = draft.savedSelections;
  draft.sessionSelections = generateSessionSelectionWhitelist(
    draft.currentSelections,
  );
};

const toggleSubsidiaryAccountCheckboxState = (
  draftSubsidiaries: Draft<Array<Subsidiary>>,
  checkboxData: SubsidiaryAccountCheckboxData,
): void => {
  updateSubsidiaryAccountCheckbox(draftSubsidiaries, checkboxData);
};

const deselectSavedSubsidaryAccountCheckboxState = (
  draft: Draft<CompanyState>,
  checkboxData: SubsidiaryAccountCheckboxData,
): void => {
  updateSubsidiaryAccountCheckbox(draft.savedSelections, checkboxData, false);
  draft.currentSelections = draft.savedSelections;
  draft.sessionSelections = generateSessionSelectionWhitelist(
    draft.currentSelections,
  );
};

const companyReducer = (
  state: CompanyState,
  action: CompanyDataActions,
): CompanyState => {
  return produce(state, (draft: Draft<CompanyState>) => {
    switch (action.type) {
      case CompanyActions.INITIALIZE_UPDATE: {
        draft.isLoading = true;
        break;
      }
      case CompanyActions.FINISH_UPDATE: {
        populateCheckboxData(draft, action.payload);
        draft.isLoading = false;
        break;
      }
      case CompanyActions.CANCEL_SELECTIONS: {
        draft.currentSelections = draft.savedSelections;
        break;
      }
      case CompanyActions.ON_SUBSIDIARY_TAG_DELETE: {
        deselectSavedSubsidaryCheckboxState(draft, action.payload);
        break;
      }
      case CompanyActions.ON_SUBSIDIARY_ACCOUNT_TAG_DELETE: {
        deselectSavedSubsidaryAccountCheckboxState(draft, action.payload);
        break;
      }
      case CompanyActions.SAVE_SELECTIONS: {
        draft.savedSelections = draft.currentSelections;
        draft.displaySelectedAccountsPane = true;
        draft.sessionSelections = generateSessionSelectionWhitelist(
          draft.savedSelections,
        );
        break;
      }
      case CompanyActions.TOGGLE_SUBSIDIARY_SELECTED: {
        toggleSubsidiaryCheckboxState(draft.currentSelections, action.payload);
        break;
      }
      case CompanyActions.TOGGLE_SUBSIDIARY_ACCOUNT_SELECTED: {
        toggleSubsidiaryAccountCheckboxState(
          draft.currentSelections,
          action.payload,
        );
        break;
      }
      case CompanyActions.TOGGLE_SELECTED_ACCOUNTS_PANE: {
        draft.displaySelectedAccountsPane = !draft.displaySelectedAccountsPane;
        break;
      }
      case CompanyActions.RESET_SELECTIONS: {
        draft.sessionSelections = null;
        break;
      }
      default: {
        break;
      }
    }
  });
};

const CompanyDataProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer<
    (state: CompanyState, action: CompanyDataActions) => CompanyState
  >(companyReducer, initialState);

  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, [dispatch]);

  return (
    <CompanyStateContext.Provider value={memoizedState}>
      <CompanyDispatchContext.Provider value={memoizedDispatch}>
        {children}
      </CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
};

const useCompanyState = (): CompanyState => {
  const data = useContext(CompanyStateContext);
  if (data === undefined) {
    throw new Error(
      'useCompanyState must be used within a CompanyDataProvider',
    );
  }
  return data;
};

const useCompanyDispatch = (): Dispatch<CompanyDataActions> => {
  const dispatch = useContext(CompanyDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'useCompanyDispatch must be used within a CompanyDispatchProvider',
    );
  }
  return dispatch;
};

const useCompanyContext = (): [CompanyState, Dispatch<CompanyDataActions>] => {
  return [useCompanyState(), useCompanyDispatch()];
};

export {
  CompanyDataProvider,
  useCompanyState,
  useCompanyDispatch,
  useCompanyContext,
  fetchCompanyData,
};
