import React, { FC, Suspense } from 'react';
import { Loader } from '@ntet/fos-ui';
import styled from 'styled-components';
import Card from './Cards/ReportCard';

import ReportCardHeader from './ReportCardHeader';

import PortfolioHoldingsCard from './Cards/PortfolioHoldingsCard';
import TransactionsCard from './Cards/TransactionsCard';
import ChangeAnalysisCard from './Cards/ChangeAnalysis/ChangeAnalysisCard';
import EarnedIncomeCard from './Cards/EarnedIncomeCard';
import TrialBalanceCard from './Cards/TrialBalanceCard';
import CostProgressionCard from './Cards/CostProgressionCard';
import { useReportState } from '../Context/View/ViewProvider';
import { ReportViewSelectorValue } from '../Context/View/types';
// import EmptyReportCard from './EmptyReportCard';

const getCurrentReport = (reportType: ReportViewSelectorValue): JSX.Element => {
  // TODO: should this be a map instead? also change prop passing to use context hook in cards?
  switch (reportType) {
    case ReportViewSelectorValue.Holdings: {
      return <PortfolioHoldingsCard />;
    }
    case ReportViewSelectorValue.Transactions: {
      return <TransactionsCard />;
    }
    case ReportViewSelectorValue.EarnedIncome: {
      return <EarnedIncomeCard />;
    }
    case ReportViewSelectorValue.TrialBalance: {
      return <TrialBalanceCard />;
    }
    case ReportViewSelectorValue.AmortizedCostProgression: {
      return <CostProgressionCard />;
    }
    // TODO: when the time comes for P&B + CFP cards, revert changes made in this MR:
    // https://gitlab.com/ntet/iaa/insurance-accounting-analysis/-/merge_requests/179
    // case ReportViewSelectorValue.PerformanceAndBenchmarking: {
    //   return <EmptyReportCard />;
    // }
    // case ReportViewSelectorValue.CashflowProjection: {
    //   return <EmptyReportCard />;
    // }
    default: {
      return <div>no card available</div>;
    }
  }
};

const ReportCards: FC<{}> = (): JSX.Element => {
  const { reportType } = useReportState();
  return (
    <>
      <Suspense fallback={<Loader />}>
        <StyledCard totalCount={2}>
          <ReportCardHeader />
          {getCurrentReport(reportType)}
        </StyledCard>
      </Suspense>
      <StyledCard totalCount={2}>
        <Suspense fallback={<Loader />}>
          <ChangeAnalysisCard />
        </Suspense>
      </StyledCard>
    </>
  );
};

export default ReportCards;

const StyledCard = styled(Card)`
  max-height: 50rem;
`;
