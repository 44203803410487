import React, { FC } from 'react';
import styled from 'styled-components';
import { IconButton } from '@ntet/fos-ui';

import { useModal } from '../../Context/ModalContext/ModalContext';

const CloseButton: FC<{}> = (): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();

  return (
    <StyledCloseButton
      data-testid='card-expand-button'
      icon='close'
      onClick={(): void => setModalVisibility(!isModalShown)}
      disabled={false}
    />
  );
};

const StyledCloseButton = styled(IconButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
    min-width: auto;
    width: 3.2rem;
    padding: 0;

    .button_icon {
      font-size: 2rem;
      margin: 0 0 0 0.5rem;
    }
  }
`;
export default CloseButton;
