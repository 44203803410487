import { useRef, useEffect, MutableRefObject } from 'react';

// Custom hook to get previous props or state
function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
