import get from 'lodash/get';
import { categoryTagMappings } from '../Components/Cards/EarnedIncome/EarnedIncomeSmallGridView';
import {
  EarnedIncomeJSONResponse,
  EarnedIncomeMonthlyData,
} from '../DataFetch/earned-income';

const hasEarnedIncomeData = (
  data: EarnedIncomeJSONResponse | null | undefined,
): boolean => {
  let hasData = false;
  if (data && data.monthly) {
    // only checking data.monthly for now
    // as data.quarterly cant exist without it
    // but this may change in the future
    data.monthly.forEach((m: EarnedIncomeMonthlyData) => {
      categoryTagMappings.forEach((t: string) => {
        if (get(m, t, null)) {
          hasData = true;
        }
      });
    });
  }
  return hasData;
};

export default hasEarnedIncomeData;
