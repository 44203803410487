import React, { FC } from 'react';

export interface ButtonContainerProps {
  borderTop: boolean | undefined;
  theme: any;
}

const WrappedButtonContainer: FC<ButtonContainerProps> = ({
  borderTop,
  ...props
}) => <div {...props} />;

export default WrappedButtonContainer;
