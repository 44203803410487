/* eslint-disable no-param-reassign */
import React, {
  FC,
  ReactNode,
  useContext,
  useReducer,
  createContext,
  Dispatch,
} from 'react';

import produce, { Draft } from 'immer';

import {
  AccountingBasisValue,
  ReportData,
  ReportViewSelectorValue,
  VisualizationMode,
} from './types';

import { ReportActions, ViewActions } from './ViewActions';

const initialState: ReportData = {
  expandGridOptions: {
    expandedReportType: ReportViewSelectorValue.Holdings,
  },
  reportType: ReportViewSelectorValue.Holdings,
  accountingBasis: AccountingBasisValue.Stat,
  displayType: VisualizationMode.Chart,
};

const ReportDataContext = createContext<ReportData | undefined>(undefined);
const ReportDispatchContext = createContext<
  Dispatch<ReportActions> | undefined
>(undefined);

// TODO: update/normalize this to use key's as properties.
const populateInitialReportData = (
  draft: Draft<ReportData>,
  fetchResponse: ReportData,
): void => {
  draft.reportType = fetchResponse.reportType;
  draft.displayType = fetchResponse.displayType;
  draft.accountingBasis = fetchResponse.accountingBasis;
};

const updateViewType = (
  draft: Draft<ReportData>,
  view: ReportViewSelectorValue,
): void => {
  draft.reportType = view;
};

const updateAccountingBasis = (
  draft: Draft<ReportData>,
  accountingBasis: AccountingBasisValue,
): void => {
  draft.accountingBasis = accountingBasis;
};

const updateDisplayType = (
  draft: Draft<ReportData>,
  displayType: VisualizationMode,
): void => {
  draft.displayType = displayType;
};

const reportReducer = (
  state: ReportData,
  action: ReportActions,
): ReportData => {
  return produce(state, (draft: Draft<ReportData>) => {
    switch (action.type) {
      // TODO: this action will not be used until v2 with live data
      case ViewActions.INIT_DATA: {
        populateInitialReportData(draft, action.payload);
        break;
      }
      case ViewActions.UPDATE_VIEW_TYPE: {
        updateViewType(draft, action.payload);
        break;
      }
      case ViewActions.UPDATE_ACCOUNTING_BASIS: {
        updateAccountingBasis(draft, action.payload);
        break;
      }
      case ViewActions.UPDATE_DISPLAY_TYPE: {
        updateDisplayType(draft, action.payload);
        break;
      }
      case ViewActions.SET_EXPANDED_REPORT_TYPE: {
        draft.expandGridOptions = action.payload;
        break;
      }
      default: {
        break;
      }
    }
  });
};

const ReportDataProvider: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer<
    (state: ReportData, action: ReportActions) => ReportData
  >(reportReducer, initialState);

  return (
    <ReportDataContext.Provider value={state}>
      <ReportDispatchContext.Provider value={dispatch}>
        {children}
      </ReportDispatchContext.Provider>
    </ReportDataContext.Provider>
  );
};

const useReportState = (): ReportData => {
  const data = useContext(ReportDataContext);
  if (data === undefined) {
    throw new Error('useReportState must be used within a ReportDataProvider');
  }
  return data;
};

const useReportDispatch = (): Dispatch<ReportActions> => {
  const dispatch = useContext(ReportDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'useReportDispatch must be used within a ReportDispatchProvider',
    );
  }
  return dispatch;
};

const useReportContext = (): [ReportData, Dispatch<ReportActions>] => {
  return [useReportState(), useReportDispatch()];
};

export {
  ReportDataProvider,
  useReportState,
  useReportDispatch,
  useReportContext,
};
