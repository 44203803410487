import React, { FC, useState } from 'react';
import { ReportActions } from '../../Context/View/ViewActions';
import {
  useReportDispatch,
  useReportState,
} from '../../Context/View/ViewProvider';
import CostProgressionSmallGridView from './CostProgression/CostProgressionSmallGridView';
import { VisualizationMode } from '../../Context/View/types';
import CostProgressionBarChartView from './CostProgression/CostProgressionBarChartView';

const CostProgressionCard: FC = (): JSX.Element => {
  const [selectedChartCategory, setSelectedChartCategory] =
    useState<string>('ALL');

  const reportDispatch = useReportDispatch();
  const { displayType } = useReportState();

  const onDataCategoryClicked = (category: string): void => {
    reportDispatch(ReportActions.updateDisplayType(VisualizationMode.Grid));
    setSelectedChartCategory(category as string);
  };

  return displayType === VisualizationMode.Grid ? (
    <CostProgressionSmallGridView
      selectedChartCategory={selectedChartCategory}
    />
  ) : (
    <CostProgressionBarChartView
      onDataCategoryClicked={onDataCategoryClicked}
    />
  );
};

export default CostProgressionCard;
