import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { LicenseManager } from 'ag-grid-enterprise/';
import App from './App';
import * as serviceWorker from './serviceWorker';

LicenseManager.setLicenseKey(
  'Liquid_PC_LLC_on_behalf_of_Northern_Trust_Chicago_Front_Office_Solutions_Single_Application_20_Devs_1_Deployment_License_16_December_2020_[v2]_MTYwODA3NjgwMDAwMA==12cc11ad4975605a6c3aeb8be0c44369',
);

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
