import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core/';
import PickerOption from '../../../Buttons/PickerOption';
import { usePeriodContext } from '../../../../Context/PeriodSelector/PeriodProvider';
import { PeriodState, Quarter } from '../../../../Context/PeriodSelector/types';
import { PeriodSelectorActions } from '../../../../Context/PeriodSelector/PeriodActions';

const QuarterlyView: FC<{
  availableDates: string[];
}> = ({ availableDates = [] }): JSX.Element => {
  const [periodState, dispatch] = usePeriodContext();

  const {
    currentSelections: { selectedQuarter, selectedYear },
  }: PeriodState = periodState;

  const selectYearString = selectedYear.year().toString();
  const yearStringLength = 4;
  const quarterStringIndex = 5;

  //  Filter to current year and trim to only quarter substring
  const availableQuarters = availableDates
    .filter(dateString => {
      return dateString.substr(0, yearStringLength) === selectYearString;
    })
    .map(dateString => dateString.substr(quarterStringIndex, 1));

  return (
    <StyledQuarterlyOptionContainer>
      <Grid container spacing={2}>
        {Object.keys(Quarter).map((label: string, index: number) => {
          //  Convert the Quarter into a String
          //  If the Quarter is NOT present in availableQuarters,
          //  there is no data and the picker should be disabled.
          const isDisabled =
            availableQuarters.indexOf((index + 1).toString()) === -1;
          return (
            <StyledGridItem item md={6} key={label} spacing={6}>
              <PickerOption
                label={label}
                isSelected={label === selectedQuarter && !isDisabled}
                onClick={(): void => {
                  dispatch(
                    PeriodSelectorActions.onQuarterSelected(label as Quarter),
                  );
                }}
                height={5}
                width={10}
                disabled={isDisabled}
              />
            </StyledGridItem>
          );
        })}
      </Grid>
    </StyledQuarterlyOptionContainer>
  );
};

export default QuarterlyView;

const StyledQuarterlyOptionContainer = styled.div`
  padding: 1rem;
`;

const StyledGridItem = styled(Grid)`
  width: 6rem;
`;
