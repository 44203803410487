import dayjs, { Dayjs } from 'dayjs';
import {
  Quarter,
  Month,
  PickerView,
  NumericMonth,
  NumericQuarter,
} from '../Context/PeriodSelector/types';

export interface ChangeAnalysisPeriod {
  YLabel: string;
  prevXLabel: string;
  currXLabel: string;
}

export const tagMappings = new Map<string, string>([
  ['accretionofdiscountbaseccy', 'Accretion of Discount'],
  ['accruedincomesoldbaseccy', 'Income Sold'],
  ['accruedincomepurchasedbaseccy', 'Income Purchased'],
  ['acquisitioncost', 'Acquisition Costs'],
  ['amortizedcostbasetotal', 'Amortized Cost'],
  ['amortizedcostdisposedbaseccy', 'Amortized Cost Disposed'],
  ['amortizationofpremiumbaseccy', 'Amortization of Premium'],
  ['amortizedcostbaseccy', 'Amortized Cost'],
  ['assetsupercategory', 'Asset Super Category'],
  ['assetsubcategory', 'Asset Sub Category'],
  ['assettype', 'Asset Type'],
  ['bookadjcarryingvaluetotal', 'Book Adjusted Carrying Value'],
  ['bopamortizedcostbaseccy', 'Beginning Amortized Cost'],
  ['bopamortizedcostlocalccy', 'Beginning Amortized Cost'],
  ['capitalgainslossesbaseccy', 'Realized Gain/Loss'],
  ['category', 'Category'],
  ['categoryamortizedcostbase', 'Amortized Cost'],
  ['categorybookadjcarryingvalue', 'Book Adjusted Carrying Value'],
  ['categorymarketvaluetotal', 'Market Value'],
  ['categoryunrealizedgainloss', 'Unrealized Gain/Loss'],
  ['costofacquisitionbaseccy', 'Cost of Acquisition'],
  ['earnedIncomeNetChange', 'Change in Earned Income'],
  ['netChange', 'Net Change'],
  ['costdisposed', 'Cost Disposed'],
  ['current', 'Current Period Total'],
  ['description', 'Description'],
  ['earnedincomebaseccy', 'Total Earned Income'],
  ['eopamortizedcostbaseccy', 'Ending Amortized Cost'],
  ['marktomarketbaseccy', 'Realized Gain/Loss'],
  ['marketvaluebaseccy', 'Market Value'],
  ['marketvaluetotal', 'Market Value'],
  ['incomereceivedbaseccy', 'Income Received'],
  ['netincomereceivedbaseccy', 'Net Income received'],
  ['netincomeaccrualbaseccy', 'Chg in Due and Accrued'],
  ['otherexpensesbaseccy', 'Other Expenses'],
  ['netproceedsbaseccy', 'Net Money of Transactions'],
  ['percentageweight', 'Allocation %'],
  ['prior', 'Prior Period Total'],
  ['proceedsbaseccy', 'Proceeds'],
  ['proceeds', 'Proceeds'],
  ['reportingtransactiontype', 'Transaction Type'],
  ['tradegainloss', 'Trade Gain/Loss'],
  ['tradegainslossesbaseccy', 'Realized Gain/Loss'],
  ['transactioncount', 'Transaction Count'],
  ['unrealizedgainlosstotal', 'Unrealized Gain/Loss'],
]);

export const getFormattedChangeAnalysisLabels = (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYTD: boolean,
): ChangeAnalysisPeriod => {
  const formattedPrevPeriodYear = dayjs(selectedYear)
    .subtract(1, 'year')
    .format('YYYY');
  const formattedSelectedYear = dayjs(selectedYear).format('YYYY');

  if (selectedMonth) {
    let previousMonthAsNumber = NumericMonth[selectedMonth] - 1;
    // NOTE: Huh, should investigate what this is about
    if (previousMonthAsNumber < 0) {
      previousMonthAsNumber = 11;
    }
    const currMonththAsNumber = NumericMonth[selectedMonth];

    const months = Object.values(Month);
    const currentMonthLabel = months[currMonththAsNumber];

    if (isYTD) {
      return {
        YLabel: 'YTD',
        prevXLabel: `${currentMonthLabel} ${formattedPrevPeriodYear}`,
        currXLabel: `${currentMonthLabel} ${formattedSelectedYear}`,
      };
    }
    const prevMonthLabel = months[previousMonthAsNumber];

    return {
      // This label could either be monthly
      YLabel: 'Monthly',
      prevXLabel: prevMonthLabel,
      currXLabel: currentMonthLabel,
    };
  }
  if (selectedQuarter) {
    let previousQuarterAsNumber = NumericQuarter[selectedQuarter] - 1;
    if (previousQuarterAsNumber < 0) {
      previousQuarterAsNumber = 3;
    }
    const currQuarterAsNumber = NumericQuarter[selectedQuarter];

    const quarters = Object.values(Quarter);
    const prevQuarterLabel = quarters[previousQuarterAsNumber];
    const currentQuarterLabel = quarters[currQuarterAsNumber];

    return {
      YLabel: 'Quarterly',
      prevXLabel: prevQuarterLabel,
      currXLabel: currentQuarterLabel,
    };
  }
  // Yearly view is show as the default if no month or quarter is selected.
  // As the default, yearly will also be shown if we somehow get into an unexpected state.
  return {
    YLabel: 'Yearly',
    prevXLabel: formattedPrevPeriodYear,
    currXLabel: formattedSelectedYear,
  };
};

export const getFormatttedSelectorLabel = (
  view: PickerView | null,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
): string => {
  const formattedYear = selectedYear.format('YYYY');
  switch (view) {
    case PickerView.Monthly: {
      return `Monthly - ${selectedMonth} ${formattedYear}`;
    }
    case PickerView.Quarterly: {
      return `Quarterly - ${selectedQuarter} ${formattedYear}`;
    }
    case PickerView.Annual: {
      return `Annual - ${formattedYear}`;
    }
    case PickerView.YTD: {
      return `Year to Date - ${selectedMonth} ${formattedYear}`;
    }
    default:
      return '';
  }
};

export const getFormattedReportLabel = (
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  isYearToDate: boolean,
): string => {
  const formattedYear = selectedYear.format('YYYY');

  if (selectedMonth) {
    if (isYearToDate) {
      return `YTD - ${selectedMonth} ${formattedYear}`;
    }
    return `${selectedMonth} ${formattedYear}`;
  }

  if (selectedQuarter) {
    return `${selectedQuarter} ${formattedYear}`;
  }

  return `Annual ${formattedYear}`;
};
