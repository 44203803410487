import React, { FC } from 'react';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { StyledBodyText } from '../StyledWrappers/WrappedTypography';
import {
  usePositionLot,
  PositionLotOption,
} from '../../Context/PositionLotContext';

const PositionLotToggle: FC = (): JSX.Element => {
  const { positionLotValue, onPositionLotToggle } = usePositionLot();

  return (
    <StyledContainer>
      <ToggleButtonGroup
        value={positionLotValue}
        exclusive
        onChange={onPositionLotToggle}
        aria-label='text alignment'
      >
        <ToggleButton
          aria-label='Position'
          data-testid='position-toggle'
          value={PositionLotOption.Position}
        >
          <StyledBodyText>POSITION</StyledBodyText>
        </ToggleButton>
        <ToggleButton
          data-testid='lot-toggle'
          value={PositionLotOption.Lot}
          aria-label='lot'
        >
          <StyledBodyText>LOT</StyledBodyText>
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-block;
  margin-right: 1rem;
  margin-left: 4rem;
  button {
    border: ${({ theme }): string =>
      `1px solid ${theme.colors.button.secondary}`};
  }
  button.Mui-disabled {
    border: ${({ theme }): string =>
      `1px solid ${theme.colors.NTDarkGrey[300]}`};
  }
  button {
    border-radius: 0;
    height: 3.5rem;

    color: ${({ theme }): string => theme.colors.text.main};

    &:hover {
      background-color: ${({ theme }): string => theme.colors.button.secondary};
      p {
        color: ${({ theme }): string => theme.colors.primary.contrastText};
      }
    }
    &.Mui-selected {
      background-color: ${({ theme }): string => theme.colors.text.main};
      p {
        color: ${({ theme }): string => theme.colors.primary.contrastText};
      }
    }
  }
`;

export default PositionLotToggle;
