import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledBoldText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 1.3rem;
    font-weight: 600;
  }
`;

export const StyledBodyText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 1.3rem;
  }
`;

export const StyledSubtitleText = styled(Typography)`
  &.MuiTypography-subtitle1 {
    font-size: 1.2rem;
    color: ${({ theme }): string => theme.colors.green[500]};
    text-decoration: underline;
  }
`;

export const StyledDisclaimerText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 7px;
  }
`;
