import { Dayjs } from 'dayjs';
import { Month, Quarter } from '../Context/PeriodSelector/types';
import { getChangeAnalysisCardFetchProps } from '../Helpers/data-uri-helper';
import {
  AccountingBasisValue,
  ExpandedReportView,
} from '../Context/View/types';
import { Subsidiary } from '../Context/CompanyDataContext/types';
import {
  getFormattedChangeAnalysisLabels,
  tagMappings,
} from '../Helpers/label-helper';
import { ChartDataSummation } from '../Components/Visualizations/ChartData';
import { PublishStatus } from '../Context/PeriodStatusProvider';

import isNotNull from '../Helpers/type-guards';

// export interface StackedBarChartGroupType {
//   group: Date | string;
//   values: Array<{
//     y: number | null;
//     subGroupID: number | string;
//     aggregateTypeID: number | string;
//   }>;
// }

// export interface ChangeAnalysisStack {
// }
export interface ChangeAnalysisChartSlice {
  key: string;
  data: Array<number>;
}
export interface ChangeAnalysisDataPoint {
  domain: string;
  value: number;
}
export interface ChangeAnalysisSlice {
  name: string;
  data: Array<ChangeAnalysisDataPoint>;
}

export interface SmallGridSlice {
  name: string;
  total: number;
  values: SmallGridAsset[];
}

export interface SmallGridAsset {
  assetsubcategory: string;
  current: string;
  prior: string;
  netChange: number;
}
export interface AnalysisIncomeDataValue {
  accretionofdiscountbaseccy: string;
  accruedincomepurchasedbaseccy: string;
  accruedincomesoldbaseccy: string;
  amortizationofpremiumbaseccy: string;
  amortizedcostdisposedbaseccy: string;
  marketvaluebaseccy: string;
  capitalgainslossesbaseccy: string;
  netincomeaccrualbaseccy: string;
  incomereceivedbaseccy: string;
  netproceedsbaseccy: string;
}

export interface IncomeData extends AnalysisIncomeDataValue {
  domain: string;
}

export interface HoldingsData extends AnalysisHoldingsDataValue {
  domain: string;
}

export interface AnalysisHoldingsDataValue {
  [transactionType: string]: string;
}

export interface AnalysisTotals extends AnalysisIncomeDataValue {
  holdings: number;
  income: number;
}

export interface ChangeAnalysisData {
  chartYAxisLabel: string;
  changeInIncome: IncomeData[];
  changeInHoldings: HoldingsData[];
  attributeTotals: ChartDataSummation[];
  transactionTypes: string[];
  analysisTotals: AnalysisTotals;
}

interface ChangeAnlysisJSONResponse {
  currentIncome: AnalysisIncomeDataValue;
  priorIncome: AnalysisIncomeDataValue;
  currentHoldings: AnalysisHoldingsDataValue;
  priorHoldings: AnalysisHoldingsDataValue;
  totals: AnalysisTotals;
}

export const fetchChartData = async (
  _queryKey: string,
  accountingBasis: AccountingBasisValue,
  reportType: ExpandedReportView,
  selectedYear: Dayjs,
  selectedMonth: Month | null,
  selectedQuarter: Quarter | null,
  clientID: string,
  isYTD: boolean,
  selectedStatus: PublishStatus,
  savedAccountSelections: Subsidiary[],
): Promise<ChangeAnalysisData | null> => {
  const { URI, accessToken } = await getChangeAnalysisCardFetchProps(
    accountingBasis,
    reportType,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    clientID,
    isYTD,
    savedAccountSelections,
    selectedStatus,
  );

  try {
    return await fetch(URI, {
      headers: { Authorization: accessToken },
    })
      .then((res: Response) => res.json())
      .then((data: ChangeAnlysisJSONResponse) => {
        // Ensure we have a non-blank response, and return null if we do.  Holdings will be blank if nothing comes back
        // Income will have keys but contain only null values, and finally total will have keys but only 0 values.
        // TODO: Eventually move this logic to the API's Shaper ?
        const isHoldingsEmpty =
          Object.keys(data.currentHoldings).length +
            Object.keys(data.priorHoldings).length <
          1;
        const isCurrentIncomeEmpty = Object.values(data.currentIncome).every(
          el => el === null,
        );
        const isPriorIncomeEmpty = Object.values(data.priorIncome).every(
          el => el === null,
        );
        const isTotalsEmpty = Object.values(data.totals).every(el => el === 0);
        if (
          isHoldingsEmpty &&
          isCurrentIncomeEmpty &&
          isPriorIncomeEmpty &&
          isTotalsEmpty
        ) {
          return null;
        }
        const chartLabels = getFormattedChangeAnalysisLabels(
          selectedYear,
          selectedMonth,
          selectedQuarter,
          isYTD,
        );

        const groomedIncomeData = [
          {
            domain: chartLabels.prevXLabel,
            ...data.priorIncome,
          },
          {
            domain: chartLabels.currXLabel,
            ...data.currentIncome,
          },
        ];

        const groomeHoldingsdData = [
          {
            domain: chartLabels.prevXLabel,
            ...data.priorHoldings,
          },
          {
            domain: chartLabels.currXLabel,
            ...data.currentHoldings,
          },
        ];

        const attributeTotals = Object.entries(data.totals)
          .map(([key, value]) => {
            const userFriendlyLabel = tagMappings.get(key);
            if (userFriendlyLabel) {
              return {
                label: userFriendlyLabel,
                value,
              };
            }
            return null;
          })
          .filter(isNotNull);

        // No .map method here used in order to preserve order on labels/chart
        const transactionTypes: string[] = [];

        Object.keys(data.currentHoldings).forEach((key: string) => {
          transactionTypes.push(key.toUpperCase());
        });

        return {
          chartYAxisLabel: chartLabels.YLabel,
          changeInIncome: groomedIncomeData,
          changeInHoldings: groomeHoldingsdData,
          attributeTotals,
          transactionTypes,
          analysisTotals: data.totals,
        };
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    // TODO: Logging system/pattern in app.
    console.log(`errorin fetch change anaylsis data. Error: ${error}`);
    return null;
  }
};
