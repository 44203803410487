import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core/';
import styled from 'styled-components';
import WrappedButtonContainer, {
  ButtonContainerProps,
} from '../StyledWrappers/WrappedButtonContainer';

interface Props {
  borderTop?: boolean;
  onApply: () => void;
  onCancel?: () => void;
}

const ConfirmationArea: FC<Props> = ({
  borderTop,
  onApply,
  onCancel,
}): JSX.Element => {
  return (
    <StyledButtonContainer borderTop={borderTop}>
      <Button variant='outlined' onClick={onCancel}>
        <Typography variant='button'>Cancel</Typography>
      </Button>
      <Button variant='outlined' onClick={onApply}>
        <Typography variant='button'>Apply</Typography>
      </Button>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled(
  WrappedButtonContainer,
)<ButtonContainerProps>`
  border-top: ${(props: ButtonContainerProps): string => {
    return props.borderTop
      ? `1px solid${props.theme.colors.NTDarkGrey[200]}`
      : 'none';
  }};
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  width: 100%;

  .MuiButton-root {
    border-radius: 0;
  }

  .MuiTypography-button {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .MuiButton-root:first-of-type {
    margin-right: 1rem;
  }

  .MuiButton-root:last-of-type {
    color: ${({ theme }): string => theme.colors.white};
    background-color: ${({ theme }): string => theme.colors.darkGreen[600]};
  }
`;

export default ConfirmationArea;
