import React, { ReactNode, FC } from 'react';
import styled from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import { useOktaAuth } from '@okta/okta-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppContentContainer: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const { authService } = useOktaAuth();
  const notify = (): string | number =>
    toast.warn(
      'You have been idle for 5 minutes, you will be logged out after 15 minutes of being idle',
      {
        hideProgressBar: true,
        closeOnClick: true,
        autoClose: 1000 * 60 * 10,
      },
    );
  const handleOnFiveIdle = (): void => {
    notify();
  };
  const handleOnFifteenIdle = (): void => {
    authService.logout();
  };
  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnFiveIdle,
    debounce: 500,
  });
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnFifteenIdle,
    debounce: 500,
  });
  return (
    <StyledWrapper>
      {children}
      <ToastContainer position='bottom-right' closeOnClick autoClose={3000} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.NTDarkGrey[100]};
  height: calc(100vh - 13rem);
  padding: 1.6rem 2rem;
`;

export default AppContentContainer;
