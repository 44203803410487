import React, { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  GridOptions,
  ColDef,
  ColGroupDef,
  CellClickedEvent,
} from 'ag-grid-community';
import '../../Grid/Grid.css';
import { ReportActions } from '../../../Context/View/ViewActions';
import { formatMonetaryNumberLocaleString } from '../../../Helpers/grid-formatters';
import '@ntet/fos-fed-styles/dist/css/styles.css';
import { fetchSmallGridData } from '../../../DataFetch/small-grid-api';
import { useModal } from '../../../Context/ModalContext/ModalContext';
import { useReportDispatch } from '../../../Context/View/ViewProvider';

import {
  ReportViewSelectorValue,
  ColumnModifier,
} from '../../../Context/View/types';
import useSmallGridData from '../../../CustomHooks/useSmallGridData';
import { tagMappings } from '../../../Helpers/label-helper';
import { AssetSuperCategory } from '../../../Helpers/data-uri-helper';

const CostProgressionSmallGridView: FC<{
  selectedChartCategory: string;
}> = ({ selectedChartCategory }): JSX.Element => {
  const { isModalShown, setModalVisibility } = useModal();
  const dispatch = useReportDispatch();

  const data = useSmallGridData({
    queryTarget: selectedChartCategory,
    queryKey: `cost-prog-small-grid-${selectedChartCategory}`,
    queryFunction: fetchSmallGridData,
  });

  const gridOptions: GridOptions = {
    animateRows: true,
    defaultColDef: {
      filter: false,
      resizable: true,
      suppressMenu: true,
    },
    suppressContextMenu: true,
    onCellClicked: (event: CellClickedEvent): void => {
      const { colDef, value } = event;
      if (
        (colDef.field && colDef.field === 'assetsubcategory') ||
        colDef.field === 'assetsupercategory'
      ) {
        const columnModifiers = new Map<string, ColumnModifier>();

        const columnModifier: ColumnModifier = {
          field: colDef.field,
          filter: true,
          pin: 'left',
          value,
        };

        columnModifiers.set(colDef.field, columnModifier);

        setModalVisibility(!isModalShown);
        dispatch(
          ReportActions.setExpandedReportType({
            expandedReportType:
              ReportViewSelectorValue.AmortizedCostProgression,
            columnModifiers,
          }),
        );
      }
    },
  };

  const colDefs: Array<ColDef | ColGroupDef> = [
    {
      field: 'bopamortizedcostbaseccy',
      headerName: tagMappings.get('bopamortizedcostbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'eopamortizedcostbaseccy',
      headerName: tagMappings.get('eopamortizedcostbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'earnedincomebaseccy',
      headerName: tagMappings.get('earnedincomebaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
    {
      field: 'capitalgainslossesbaseccy',
      headerName: tagMappings.get('tradegainslossesbaseccy'),
      valueFormatter: formatMonetaryNumberLocaleString,
      type: 'numericColumn',
    },
  ];

  if (selectedChartCategory !== AssetSuperCategory.ALL) {
    colDefs.unshift({
      field: 'assetsubcategory',
      headerName: tagMappings.get('assetsubcategory'),
      width: 300,
      cellStyle: { 'font-weight': 500 },
    });
  } else {
    colDefs.unshift({
      field: 'assetsupercategory',
      headerName: tagMappings.get('assetsupercategory'),
      width: 300,
      cellStyle: { 'font-weight': 500 },
    });
  }

  return (
    <StyledAgGridContainer className='ag-theme-views'>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={gridOptions.defaultColDef}
        gridOptions={gridOptions}
        rowData={data}
        statusBar={undefined}
      />
    </StyledAgGridContainer>
  );
};

export default CostProgressionSmallGridView;

const StyledAgGridContainer = styled.div`
  border-top: 2px solid
    ${(props): string => props.theme.colors.NTDarkGrey[700]}};
  height: calc(100% - 5.2rem);
  width: 100%;
`;
