import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import dayjs from 'dayjs';
import { usePeriodContext } from '../../../Context/PeriodSelector/PeriodProvider';
import { PeriodState } from '../../../Context/PeriodSelector/types';
import { PeriodSelectorActions } from '../../../Context/PeriodSelector/PeriodActions';

const DateSlider: FC = (): JSX.Element => {
  const [periodState, dispatch] = usePeriodContext();

  const {
    currentSelections: { selectedYear },
  }: PeriodState = periodState;

  const disableIncrementButton = dayjs(selectedYear)
    .add(1, 'year')
    .isAfter(dayjs());

  const disableDecrementButton = dayjs(selectedYear)
    .subtract(1, 'year')
    .isBefore(dayjs('2019-01-01T24:00:00.000Z'));

  return (
    <StyledSliderBar>
      <StyledToggle>
        <StyledChevronButton
          disabled={disableDecrementButton}
          onClick={(): void => {
            const decrementedYear = dayjs(selectedYear).subtract(1, 'year');
            dispatch(
              PeriodSelectorActions.onYearSliderToggled(decrementedYear),
            );
          }}
        >
          <i className='icon--chevron_left' />
        </StyledChevronButton>
        <Typography variant='h5'>{selectedYear.format('YYYY')}</Typography>
        <StyledChevronButton
          disabled={disableIncrementButton}
          onClick={(): void => {
            const incrementedYear = dayjs(selectedYear).add(1, 'year');
            dispatch(
              PeriodSelectorActions.onYearSliderToggled(incrementedYear),
            );
          }}
        >
          <i className='icon--chevron_right' />
        </StyledChevronButton>
      </StyledToggle>
    </StyledSliderBar>
  );
};

export default DateSlider;

const StyledToggle = styled.div`
  display: flex;
  padding-top: 1rem;
  width: 10rem;
  justify-content: space-between;
`;

const StyledChevronButton = styled(Button)`
  &.MuiButtonBase-root {
    padding: 0px;
    min-width: 3rem;
  }

  i {
    font-size: 2rem;
  }
`;

const StyledSliderBar = styled.div`
  display: flex;
  justify-content: center;
`;
