/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction } from '../CreateActionHelper';
import {
  JSONResponseCompanyData,
  SubsidiaryAccountCheckboxData,
} from './types';

import { ActionsUnion } from '../SharedContextTypes';

export enum CompanyActions {
  CANCEL_SELECTIONS = 'CANCEL_SELECTIONS',
  ON_SUBSIDIARY_TAG_DELETE = 'ON_SUBSIDIARY_TAG_DELETE',
  ON_SUBSIDIARY_ACCOUNT_TAG_DELETE = 'ON_SUBSIDIARY_ACCOUNT_TAG_DELETE',
  SAVE_SELECTIONS = 'SAVE_SELECTIONS',
  TOGGLE_SUBSIDIARY_SELECTED = 'TOGGLE_SUBSIDIARY_SELECTED',
  TOGGLE_SUBSIDIARY_ACCOUNT_SELECTED = 'TOGGLE_SUBSIDIARY_ACCOUNT_SELECTED',
  INITIALIZE_UPDATE = 'INITIALIZE_UPDATE',
  FINISH_UPDATE = 'FINISH_UPDATE',
  TOGGLE_SELECTED_ACCOUNTS_PANE = 'TOGGLE_SELECTED_ACCOUNTS_PANE',
  RESET_SELECTIONS = 'RESET_SELECTIONS',
}

export const CompanyDataActions = {
  cancelSelections: () => createAction(CompanyActions.CANCEL_SELECTIONS),
  initializeUpdate: () => createAction(CompanyActions.INITIALIZE_UPDATE),
  finishUpdate: (responseData: JSONResponseCompanyData) =>
    createAction(CompanyActions.FINISH_UPDATE, responseData),
  saveSelections: () => createAction(CompanyActions.SAVE_SELECTIONS),
  toggleSubsidiarySelected: (id: string) =>
    createAction(CompanyActions.TOGGLE_SUBSIDIARY_SELECTED, id),
  toggleSubsidiaryAccountSelected: (
    checkboxData: SubsidiaryAccountCheckboxData,
  ) =>
    createAction(
      CompanyActions.TOGGLE_SUBSIDIARY_ACCOUNT_SELECTED,
      checkboxData,
    ),
  onSubsidiaryTagDelete: (id: string) =>
    createAction(CompanyActions.ON_SUBSIDIARY_TAG_DELETE, id),
  onSubsidiaryAccountTagDelete: (checkboxData: SubsidiaryAccountCheckboxData) =>
    createAction(CompanyActions.ON_SUBSIDIARY_ACCOUNT_TAG_DELETE, checkboxData),
  toggleSelectedAccountsPane: () =>
    createAction(CompanyActions.TOGGLE_SELECTED_ACCOUNTS_PANE),
  resetSelections: () => createAction(CompanyActions.RESET_SELECTIONS),
};

export type CompanyDataActions = ActionsUnion<typeof CompanyDataActions>;
