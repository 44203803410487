/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-globals */
// @ts-nocheck
import React, { FC, useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import { ChangeAnalysisColors } from '../../Helpers/tag-color-helper';
import {
  PickerView,
  Month,
  NumericMonth,
  Period,
} from '../../Context/PeriodSelector/types';

interface StackedBarChartProps {
  data: any;
  period: Period;
  frequency: PickerView;
  height: number;
  width: number;
  onBarChartRectClicked?: (category: string) => void;
}

type MonthlyDomain = PickerView.Monthly | PickerView.YTD | PickerView.Annual;

const StackedBarChart: FC<StackedBarChartProps> = ({
  data,
  period,
  frequency,
  height,
  width,
  onBarChartRectClicked,
}): JSX.Element => {
  const ref = useRef<SVGGElement | null>(null);

  const margin = {
    top: 45,
    right: 30,
    bottom: 45,
    left: 100,
  };

  const getStackMin = useCallback((serie): number => {
    return d3.min(serie, d => {
      return d[0];
    });
  }, []);

  const getStackMax = useCallback((serie): number => {
    return d3.max(serie, d => {
      return d[1];
    });
  }, []);

  const formatMonetaryNumberLocaleString = useCallback(
    (val: string): string => {
      const value = Number(val);
      if (isNaN(value)) {
        return '0';
      }
      return value < 0
        ? `(${Math.abs(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })})`
        : `${value.toLocaleString()}`;
    },
    [],
  );

  const getMonetaryAbbreviation = useCallback(
    (value: string): string => {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return '0';
      }

      if (numberValue <= 10 ** 3) {
        return formatMonetaryNumberLocaleString(value);
      }

      let convertedVal = 0;
      let abbrv = '';

      // billions
      if (numberValue >= 10 ** 9) {
        convertedVal = numberValue / 10 ** 9;
        abbrv = 'B';
      }
      // millions
      if (numberValue < 10 ** 9 && numberValue >= 10 ** 6) {
        convertedVal = numberValue / 10 ** 6;
        abbrv = 'M';
      }
      //
      if (numberValue < 10 ** 6 && numberValue >= 10 ** 3) {
        convertedVal = numberValue / 10 ** 3;
        abbrv = 'K';
      }

      return `${Math.ceil(convertedVal)}${abbrv}`;
    },
    [formatMonetaryNumberLocaleString],
  );

  const getDataDomainValue = useCallback((domainData): string[] => {
    return domainData.map(d => {
      const month = d.timeunit.substring(
        d.timeunit.length - 2,
        d.timeunit.length,
      );
      return NumericMonth[Number(month) - 1];
    });
  }, []);

  const onChartClicked = useCallback(() => {
    if (onBarChartRectClicked) {
      onBarChartRectClicked('ALL');
    }
  }, [onBarChartRectClicked]);

  useEffect(() => {
    const svgGroup = d3.select(ref.current);

    const dataKeys = Object.keys(data.monthly[0]).filter(
      key => key !== 'timeunit',
    );

    const series = d3.stack().keys(dataKeys).offset(d3.stackOffsetDiverging)(
      data.monthly,
    );

    const defaultChartDomainValues = Object.values(Month);
    const dataDomainValues = getDataDomainValue(data.monthly);
    const remainingMonths = defaultChartDomainValues.filter(
      val => !dataDomainValues.includes(val),
    );

    const yMax = d3.max(series, getStackMax);
    const yMin = d3.min(series, getStackMin);

    const x = d3
      .scaleBand()
      .domain(defaultChartDomainValues)
      .range([margin.left, width - margin.right])
      .padding(0.5);

    const y = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .rangeRound([height - margin.bottom, margin.top]);

    const z = d3.scaleOrdinal().range(ChangeAnalysisColors);

    const nodes = svgGroup
      .append('g')
      .selectAll('g')
      .data(series)
      .enter()
      .append('g');

    nodes
      .attr('fill', d => {
        return z(d.key);
      })
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('rect')
      .on('click', () => {
        onChartClicked();
      })
      .attr('width', x.bandwidth)
      .attr('x', d => {
        const month = d.data.timeunit.substring(
          d.data.timeunit.length - 2,
          d.data.timeunit.length,
        );
        return x(NumericMonth[Number(month) - 1]);
      })
      .attr('y', d => {
        return y(d[1]);
      })
      .attr('height', d => {
        return y(d[0]) - y(d[1]);
      })
      .attr('class', 'change-analysis-rect');

    const monthlySums = data.monthly.map(month => month.total);

    let text = svgGroup.selectAll('.text').data(dataDomainValues);

    text.exit().remove();

    if (frequency !== PickerView.Quarterly) {
      text
        .enter()
        .append('text')
        .attr('class', 'text')
        .attr('text-anchor', 'middle')
        .attr('font-size', '1rem')
        .attr('x', d => x(d) + 12)
        .attr('y', 10)
        .text((d, i) => {
          return getMonetaryAbbreviation(monthlySums[i]);
        });
    }

    if (remainingMonths.length) {
      nodes
        .selectAll('rect.earned-income-future-month-bars')
        .data(remainingMonths)
        .enter()
        .append('rect')
        .on('click', () => {
          onChartClicked();
        })
        .attr('x', d => x(d))
        .attr('y', 45)
        .attr('width', x.bandwidth())
        .attr('height', height - margin.top - margin.bottom)
        .style('opacity', '0.1')
        .style('fill', 'lightblue')
        .attr('class', 'earned-income-future-month-bars');
    }

    if (frequency === PickerView.Monthly) {
      const monthAbbrv = NumericMonth[period];

      nodes
        .selectAll('rect.earned-income-background-bars')
        .data([monthAbbrv])
        .enter()
        .append('rect')
        .attr('x', d => x(d))
        .attr('y', 40)
        .attr('width', x.bandwidth() * 1.75)
        .attr('height', height - margin.top)
        .attr('transform', `translate(-10, -10)`)
        .style('fill', 'none')
        .style('stroke', 'lightblue')
        .attr('class', 'earned-income-background-bars');
    }

    if (frequency === PickerView.Quarterly) {
      const quarterDomainValues = new Map<Period, Month[]>([
        [Period.ONE, defaultChartDomainValues.slice(0, 3)],
        [Period.TWO, defaultChartDomainValues.slice(3, 6)],
        [Period.THREE, defaultChartDomainValues.slice(6, 9)],
        [Period.FOUR, defaultChartDomainValues.slice(9, 13)],
      ]);

      const quarterlySums = Object.values(data.quarterly);

      const selectedQtrDomainVals = quarterDomainValues.get(period);

      if (selectedQtrDomainVals) {
        nodes
          .selectAll('rect.earned-income-background-bars')
          .data(selectedQtrDomainVals)
          .enter()
          .append('rect')
          .attr('x', d => x(d))
          .attr('y', 40)
          .attr('width', x.bandwidth() * 1.8)
          .attr('height', height - margin.top)
          .attr('transform', `translate(-10, -10)`)
          .style('fill', 'none')
          .style('stroke', 'lightblue')
          .attr('class', 'earned-income-background-bars');

        const quartersToLabel: Array<Month> = [
          Month.FEB,
          Month.MAY,
          Month.AUG,
          Month.NOV,
        ];

        text = svgGroup.selectAll('.text').data(quartersToLabel);

        text.exit().remove();

        text
          .enter()
          .append('text')
          .attr('class', 'text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '1rem')
          .attr('x', d => x(d) + 15)
          .attr('y', 10)
          .text((d, i) => {
            return getMonetaryAbbreviation(quarterlySums[i]);
          });
      }
    }

    // // xAxis
    svgGroup
      .append('g')
      .attr('transform', `translate(0,${y(0)})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'translate(0, -25)')
      .style('text-anchor', 'middle')
      .style('font-size', 16)
      .style('fill', 'black');

    // yAxis
    svgGroup
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y));

    return (): void => {
      svgGroup.selectAll('g').remove();
    };
  }, [
    data,
    data.monthly,
    formatMonetaryNumberLocaleString,
    frequency,
    getDataDomainValue,
    getMonetaryAbbreviation,
    getStackMax,
    getStackMin,
    height,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
    onChartClicked,
    period,
    width,
  ]);
  return (
    <svg width={width} height={height}>
      <g ref={ref} transform='translate(0, 0)' />
    </svg>
  );
};

export default StackedBarChart;
