export interface ColumnModifier {
  field: string;
  sort?: boolean;
  filter?: boolean;
  pin?: 'left' | 'right';
  value: string;
}

export interface ReportData {
  expandGridOptions: {
    expandedReportType: ExpandedReportView;
    columnModifiers?: Map<string, ColumnModifier>;
  };
  reportType: ReportViewSelectorValue;
  accountingBasis: AccountingBasisValue;
  displayType: VisualizationMode;
}

export enum AccountingBasisOption {
  StatutoryReporting = 'Statutory Reporting',
  GaapReporting = 'Gaap Reporting',
  TaxReporting = 'Tax Reporting',
}

export enum AccountingBasisValue {
  Stat = 'Stat',
  Gaap = 'Gaap',
  Tax = 'Tax',
}

export enum ReportView {
  Holdings = 'Holdings',
  Transactions = 'Transactions',
  EarnedIncome = 'Earned Income',
  TrialBalance = 'Trial Balance',
  AmortizedCostProgression = 'Amortized Cost Progression',
  PerformanceAndBenchmarking = 'Performance & Benchmarking',
  CashflowProjection = 'Cashflow Projection',
}

export enum ReportViewSelectorValue {
  Holdings = 'Holdings',
  Transactions = 'Transactions',
  EarnedIncome = 'EarnedIncome',
  TrialBalance = 'TrialBalance',
  AmortizedCostProgression = 'AmortizedCostProgression',
  PerformanceAndBenchmarking = 'PerformanceAndBenchmarking',
  CashflowProjection = 'CashflowProjection',
}

export enum ReportURLSegment {
  Holdings = 'holdings',
  Transactions = 'transactions',
  EarnedIncome = 'earned-income',
  TrialBalance = 'trial-balance',
  AmortizedCostProgression = 'cost-progression',
  ChangeAnalysis = 'change-analysis',
}

export enum VisualizationMode {
  Chart = 'chart',
  Grid = 'grid',
}

export interface ExpandReportOptions {
  expandedReportType: ExpandedReportView;
  columnModifiers?: Map<string, ColumnModifier>;
}

// this prevents the type from widening to String type preventing you from passing any ol' string.
const ChangeAnalysis = 'Change Analysis' as const;

export type ChangeAnalysis = typeof ChangeAnalysis;

export type ExpandedReportView = ChangeAnalysis | ReportViewSelectorValue;
